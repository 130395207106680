import { useRef, useState, useEffect } from 'react';

import background from "../../assets/gif/excuses.gif";
import backgroundaudio from "../../assets/gif/excuses1.gif";
import background2 from "../../assets/gif/nomoreexcuses.png";

const PlayerApp = ({ id, url, content }) => {
  const [isplaying, setisplaying] = useState(false);
  const [duration, setDuration] = useState(0)
  const [durationv, setDurationv] = useState('')
  const [currentTime, setCurrentTime] = useState(0)
  const [progress, setProgress] = useState(0)
  const [currentTimev, setCurrentTimev] = useState(0)

  const audioElem = useRef();
  const clickRef = useRef();

  useEffect(() => {
    if (isplaying) {
      audioElem.current.play();
    }
    else {
      audioElem.current.pause();
    }
  }, [isplaying])


  const PlayPause = () => {
    setisplaying(!isplaying);

  }

  const onPlaying = () => {
    const tl = calculateTotalValue(audioElem.current.duration);
    const cl = calculateTotalValue(audioElem.current.currentTime);
    // console.log(audioElem.current.currentTime);
    // console.log(audioElem.current.duration);

    // console.log(Math.floor(audioElem.current.currentTime / audioElem.current.duration * 100));
    setProgress(Math.floor(audioElem.current.currentTime / audioElem.current.duration * 100));
    setDurationv(tl);
    setCurrentTimev(cl);
    setDuration(audioElem.current.duration);
    setCurrentTime(audioElem.current.currentTime);
    if (audioElem.current.currentTime === audioElem.current.duration) {
      setisplaying(false);
    }

  }
  const checkWidth = (e) => {
    let width = clickRef.current.clientWidth;
    const offset = e.nativeEvent.offsetX;

    const divprogress = offset / width * 100;
    audioElem.current.currentTime = divprogress / 100 * audioElem.current.duration;

  }
  const calculateTotalValue = (length) => {
    var minutes = Math.floor(length / 60),
      seconds_int = length - minutes * 60,
      seconds_str = seconds_int.toString(),
      seconds = seconds_str.substr(0, 2),
      time = minutes + ':' + seconds

    return time;
  }
  const bglink = isplaying ? {  backgroundImage: `url(${backgroundaudio})`, } : { backgroundImage: `url(${background2})`, };
  const pgress = { width: `${progress + "%"}` }

  return (
    <div className="w-full p-3 rounded-2xl flex flex-col justify-center items-center bg-black" >
      <audio src={url} ref={audioElem} id={id} onTimeUpdate={onPlaying} />
      <div className='w-full flex flex-col items-center '>
        <div className="h-28   flex mx-auto justify-center" onClick={PlayPause} >
          {
            isplaying ?
              (
                <div className='flex flex-col w-full justify-center items-center'>
                <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24" fill="none" stroke="#EF4444" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="10"></circle><line x1="10" y1="15" x2="10" y2="9"></line><line x1="14" y1="15" x2="14" y2="9"></line></svg>
                {/* <img
              className=" w-full h-24" 
              src={backgroundaudio}
              alt="audio"
            /> */}
                </div>
                )
                :
                (
               
               <>
                  <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 24 24" fill="none" stroke="#EF4444" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="10"></circle><polygon points="10 8 16 12 10 16 10 8"></polygon></svg>
               </>
              )
          }
        </div>


        <div className="h-36 w-full mb-1 flex mx-auto items-center justify-center">
          <div className=" h-full md:w-[60%] lg:w-full bg-no-repeat bg-center " style={bglink} ></div>
        </div>
      {
      isplaying ?
      (  <div className="w-full flex flex-nowrap justify-between items-start relative p-3">

          <div className="h-1 ml-6 md:ml-6 mr-2 flex-1 bg-white rounded-3xl cursor-pointer w-full" onClick={checkWidth} ref={clickRef}>
            <div className="bg-red-500 rounded-3xl h-full" style={pgress}></div>
          </div>
          <div className='text-white w-6 -mt-2'>{durationv}</div>
        </div>):
        (<div className="h-10"> &nbsp; </div>)
        }
        {/* <div className='text-green-600'>{currentTimev}</div> */}

        <div className=" -mt-3 flex items-center text-white text-lg " >
          <span>{content?.colabo}:</span>
          <span>&nbsp; &nbsp; &nbsp; </span>
          <span>{content?.caption}</span>

        </div>
      </div>

    </div>
  );
}


export default PlayerApp;
