import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import Header from "./Header";
import Image from "./Image";
function PostShare({ content }) {
if(content)
  return (

    <div className="container bg-white my-5 border rounded-md divide-y shadow-md">
      <Header userId={content?.userId} postID={content.id}  />
      <Image src={content.imageSrc} caption={content?.caption}
        fileType={content.fileType} fileKind={content.fileKind}
        content= {content}      />
   
      
    </div>
  );
}

export default PostShare;

PostShare.propTypes = {
  content: PropTypes.shape({
     caption: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    // userLikedPhoto: PropTypes.bool.isRequired,
   
  }),
};
