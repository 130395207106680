
import React, { useEffect, useRef, useState } from "react";
import Header from "./Header";
import Image from "./Image";
import Buttons from "./Buttons";
import Captions from "./Captions";
import Comments from "./Comments";
import { postModalState } from "../../atoms/modalAtom";
import { useRecoilState, useRecoilValue } from "recoil";
import { useSelector } from "react-redux";
import { currentuserId } from "../../store/authSlice";
import { selectUserById } from "../../store/usersSlice";

function Post({ content }) {

  // console.log(content);
  const currentUserId = useSelector((state) => currentuserId(state));
  const postUser = useSelector((state) => selectUserById(state, content.userId));
  const [showComment, setshowComment] = useState(false);

  const [commentLength, setCommentLength] = useState(0)
  const handleFocus = () => commentInput.current.focus();
  const commentInput = useRef(null);
  const [open, setOpen] = useRecoilState(postModalState);
  // setLiked(content.likes.some(a=> a===currentUserId));
  if (!content) {
    return
  }
  
  return (
    <div className="w-full   bg-white my-5 border rounded-lg divide-y shadow-md">
      <Header userId={content.userId} postID={content.id} />

      <Image src={content.imageSrc} caption={content?.caption}
        fileType={content?.fileType} fileKind={content?.fileKind}
        content={content}
      />
      <div>

        <Buttons
          id={content.id}
          totalLikes={content.likes?.length}
          totalShares={content.shares?.length ?? 0}
          totalComments={content.comments?.length}
          likedPhoto={content.likes?.some(a => a === currentUserId) }
          handleFocus={handleFocus}
          numberComment={commentLength}
          setshowComment={setshowComment}
          savedPhoto={content.saves?.some(a => a === currentUserId) }
          totalSaved={content.saves?.length}
          fileType={content?.fileType} fileKind={content?.fileKind}
        />
        <Captions caption={content?.caption} username={postUser?.username} />
      </div>
      <Comments
        id={content.id}
        postuserId={content.userId}
        postedAt={content.timestamp}
        commentInput={commentInput}
        setCommentsLen={setCommentLength}
        showComment={showComment}
      />

    </div>
  );
}

export default Post;

