import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LoginModal from './LoginModal';
import SignUpModal from './SignUpModal';
import { closeAuth } from '../../store/modelSlice';

const AuthModel = () => {
    const dispatch = useDispatch();
    const open = useSelector((state) => state.model.authModelStatus);


    const [showMode, setShowMode] = useState(true);
    return (
        <div className={`overflow-hidden w-full flex flex-col fixed right-0 top-0 h-full min-h-screen transition-all ease-in-out duration-300 bg-red-900/[.6] z-50 "  
    ${open ? 'max-w-7xl' : 'max-w-0'} 
    `}>
        <button onClick={() => dispatch(closeAuth())} className="p-2 text-red-500  rounded-md focus:outline-none focus:ring">
                        <svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="red" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                        </svg>
                    </button>
           {  showMode ? <LoginModal  dohide={setShowMode}/> :
            <SignUpModal dohide={setShowMode} />}
        </div>
    )
}

export default AuthModel