
import { useParams, } from "react-router-dom";
import UserProfile from "../components/UserProfile/UserProfile";
import Loader from "../components/UserProfile/Loader";
import { useSelector } from "react-redux";
import Post from "../components/Feed/Post";
import { selectPostById } from "../store/postSlice";


function PostPage() {
  // fetch username from url params
  const { id } = useParams();
  const post = useSelector((state) => selectPostById(state, id));

  return (
      <div className="mx-auto mt-16   overflow-x-hidden pb-4 sm:max-w-xl md:max-w-lg lg:max-w-xl xl:max-w-2xl">
        {post ? (<Post key={post?.id} content={post} />) : <Loader />}
      </div>
   
  );
}

export default PostPage;
