import { useSelector } from "react-redux";
import { selectPostById } from "../../store/postSlice";
import { Player } from "./audio8/Player";
import Audioplay4 from "./audioplay4";
import AudioPlay7 from "./audioplay7";
import Audioplayer3 from "./audioplayer3";
import AudioPlayer from "./audioview/AudioPlayer";
import AudioPlayer2 from "./audioview/audioPlayer2";
import PlayAudio5 from "./playAudio5";
import PlayerApp from "./playAudio5p";
import PlayAudio6 from "./playAudio6";
import PlayerAppCircle from "./playAudiocircle";
import PostShare from "./PostShare";


function Image({ src, caption, fileType, fileKind, content }) {
  const sharedPost = useSelector((state) => selectPostById(state, content?.sharedId));
  // console.log(content);
  if (content?.sharedId) {
    // console.log(content);
    return (<div><PostShare className='px-2' content={sharedPost} /> </div>);
  }
  let cnt = '';
  // <iframe width="560" height="315" src="https://www.youtube.com/embed/5Cp8ou3jIGA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
  if (fileKind && fileKind === 'video') {
    cnt = <video controls autoPlay loop muted className="w-full cursor-pointer object-contain" >
      <source src={src} type={fileType}></source>
    </video>
    // cnt =  <iframe width="560" height="315" src={src}  target="_parent"  title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
  } else if (fileKind && fileKind === 'audio') {
    // cnt = <audio controls className="w-full cursor-pointer object-contain" >
    //   <source src={src} type={fileType} />
    // </audio>;
    // cnt = <AudioPlayer  title={'Song Tile'} artist={'Artist name'}   audioSrc={src}/>
    // cnt = <Audioplayer3  url={src}/>
    // cnt = <Audioplay4 id={content?.id} url={src}/>

    
    // cnt = <PlayerAppCircle id={content?.id} url={src} content={content} />

    cnt = <PlayerApp id={content?.id} url={src} content={content} />



    // cnt = <PlayAudio6 id={content?.id} url={src}/>
    // cnt = <AudioPlay7 id={content?.id} url={src}/>
    // cnt = <Player id={content?.id} audioUrl={src}/>

    // cnt = <AudioPlayer2    Src={src}/>
    // cnt = <div className="bg-red-500 min-h-[480px] flex flex-col text-lg text-white w-full">
    //   <div className="flex flex-col justify-center mx-auto relative h-80 w-80">
    //   <canvas id='Player-canvas' key='Player-canvas'></canvas>
    // <div className="absolute z-20 top-8 w-full text-gray-200">
    //   <div className="flex "></div>
    // </div>
    //   </div>
    // </div>

  }
  else if (fileKind && fileKind === 'image') {
    cnt = <img id="" src={src} alt={caption} className="object-contain w-full h-full" />
  }

  else if (fileKind && fileKind === 'text') {
    cnt =  <p className="p-4 text-xl">{content?.text}</p>
  }
  
   
  else {
    cnt = ''
  }

  return (
    <div id={content?.id}>
      {cnt}
    </div>
  );
}

export default Image;


