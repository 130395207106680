import React, { Fragment, useEffect, useRef, useState } from "react";
import { useRecoilState } from "recoil";
import { postModalState } from "../../atoms/modalAtom";
import { Dialog, Transition } from "@headlessui/react";
import { CameraIcon, CloudUploadIcon, EmojiHappyIcon, XIcon } from "@heroicons/react/outline";
import { addPostsToFirestore2, addPostsToFirestore3 } from "../../context/PostFirbase";
import { useSelector } from "react-redux";
import { currentuserId } from "../../store/authSlice";

import Picker from 'emoji-picker-react';


function PostModal() {
  const userId = useSelector((state) => currentuserId(state));
  // const currentusername= useSelector((state) => currentusername);
  // const currentimage= useSelector((state) => currentimage);
  // const currentfollowers= useSelector((state) => currentfollowers);
  // const currentfullName= useSelector((state) => currentfullName);
  // const currentemailAddress= useSelector((state) => currentemailAddress);
  // const currentbio= useSelector((state) => currentbio);
  // const currentfollowing= useSelector((state) => currentfollowing);
  // const currentdateCreated= useSelector((state) => currentdateCreated);
  // const currentid= useSelector((state) => currentid);
  const [open, setOpen] = useRecoilState(postModalState);
  const filePickerRef = useRef(null);
  const captionRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [setPrice, setSetPrice] = useState(false);
  const [setAmount, setSetAmount] = useState(0);
  const [contentType, setContentType] = useState(null);
  const [contentTypeD, setContentTypeD] = useState(null);
  const [typeFile, setTypeFile] = useState('file');
  const [link, setLink] = useState('');

  const [inputStr, setInputStr] = useState('');
  const [showPicker, setShowPicker] = useState(false);

  const onEmojiClick = (event, emojiObject) => {
    setInputStr(prevInput => prevInput + emojiObject.emoji);
    setShowPicker(false);
  };


  const uploadPost = async () => {
    if (loading) return;
    setLoading(true);
    const caption = captionRef.current.value;
    // await addPostsToFirestore(userId, caption, selectedFile);
    if (selectedFile) {
      await addPostsToFirestore2(userId, caption, selectedFile, contentType, contentTypeD, setAmount);
    } else {
      await addPostsToFirestore3(userId, caption, 'text', 'text', setAmount);
    }
    console.log(userId, caption, selectedFile, contentType, contentTypeD, setAmount);

    setOpen(false);
    setLoading(false);
    setSelectedFile(null);
    document.location.reload();
  };
  // to display the image to be posted in the modal
  const addImageToPost = (event) => {
    const reader = new FileReader();
    if (event.target.files[0]) {
      reader.readAsDataURL(event.target.files[0]);
    }
    reader.onload = (readerEvent) => {
      setSelectedFile(readerEvent.target.result);
      setContentType(readerEvent.target.result.split(";")[0].split(":")[1]);
      setContentTypeD(readerEvent.target.result.split(";")[0].split(":")[1].split("/")[0]);
      // console.log(readerEvent.target.result);
      // console.log(readerEvent.target.result.split(";"));
      // console.log(readerEvent.target.result.split(";")[0].split(":")[1]);
      // console.log(selectedFile);
    };
    // typeFile === "file"


  };
  const selectContentType = () => {

  }
  // useEffect(() => {
  //   console.log(selectedFile);
  // }, [selectedFile])

  const handleSetFileType = (e) => {
    if (e.target.selectedIndex === 0) {

    } else {
      setContentTypeD(e.target.value);
    }
  }
  const handleSetPrice = (e) => {
    if (e.target.value === 'custom') {
      setSetPrice(true);
    } else {
      setSetAmount(e.target.value);
      setSetPrice(false);
    }
  }
  let cnt = '';

  // let cnttype1 = selectedFile.split(";")[0].split(":")[1];
  // let cnttype2 = cnttype1.split("/")[0];
  if (contentTypeD === 'image') {

    cnt = <img
      src={selectedFile}
      alt="Posting Image"
      className="w-full cursor-pointer object-contain max-h-36 max-w-xs"
      onClick={() => setSelectedFile(null)}

    />
  } else if (contentTypeD === 'video') {
    cnt = <video controls autoPlay loop muted onClick={() => setSelectedFile(null)} >
      <source src={selectedFile} type={contentType}></source>
    </video>
  } else if (contentTypeD === 'audio') {
    cnt = <audio controls className="w-full cursor-pointer object-contain" onClick={() => setSelectedFile(null)}>
      <source src={selectedFile} type={contentType} />
    </audio>;
  }
  const onChangeValue = (event) => {
    setTypeFile(event.target.value);
  }


  return (
    <div className="my-auto mx-auto overflow-y-auto"  >
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="absolute inset-0 z-[60] overflow-y-auto"
          onClose={setOpen}
        >
          <div className="m-2 flex min-h-screen items-center justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0 ">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-500"
              enterFrom="opactiy-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-500"
              enterFrom="opactiy-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block w-full transhtmlForm space-y-4 overflow-y-scroll rounded-lg bg-white p-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:max-w-sm sm:p-6 sm:align-middle">
                <div className="flex justify-between">

                  <div as="h3" className="flex items-center space-x-4  text-2xl ">
                    <div className=" flex h-8 w-8 items-center justify-center rounded-full bg-red-100">
                      <CloudUploadIcon className="mt-[2px] h-4 w-4 text-red-600" />
                    </div>
                    <span>Upload Post</span>
                  </div>
                  <button
                    className="float-right outline-none"
                    onClick={() => {
                      setOpen(false);
                      setLoading(false);
                      setSelectedFile(null);
                    }}
                  >
                    <XIcon className=" h-6  w-6 cursor-pointer text-gray-300" />
                  </button>
                </div>
                <hr />
              
                <div>
                  {typeFile === 'file' ?
                    (<div>
                      {selectedFile ?
                        cnt
                        : (
                          <div
                            onClick={() => filePickerRef.current.click()}
                            className="mx-auto flex h-12 w-12 cursor-pointer items-center justify-center rounded-full bg-red-100"
                          >
                            <CameraIcon
                              className="h-6 w-6 text-red-600"
                              aria-hidden="true"
                            />
                          </div>
                        )}

                      <div className="mt-3 text-center sm:mt-5">
                        <Dialog.Title
                          as="h3"
                          className="text-lg font-semibold leading-6 text-gray-500"
                        >
                          {selectedFile
                            ? "Note: Click on the Photo to Reselect."
                            : "Click on the Icon to Select an Image, an Audio or a video ."}
                        </Dialog.Title>
                        <div>
                          <input
                            type="file"
                            accept="*/*"
                            hidden
                            onChange={addImageToPost}
                            ref={filePickerRef}
                          />
                        </div>

                      </div>
                    </div>) : (
                      <div className="">
                        <div>

                          <label htmlFor="filekind" className=" mb-4 text-sm font-medium text-red-900 dark:text-red-400">Select</label>
                          <select id="filekind" onChange={handleSetFileType} className="selection:bg-red-500 accent-red-500 bg-red-50 border border-red-300 text-red-900 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2.5 dark:bg-gray-700 dark:border-red-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500">
                            <option value="none">Select File type</option>
                            <option value="image">Image</option>
                            <option value="video">video</option>
                            <option value="audio">audio</option>
                          </select>
                        </div>
                        <div>
                          <label htmlFor="link" className=" text-sm font-medium text-red-900 dark:text-red-300">Link</label>
                          <input type="text" onChange={e => setLink(e.target.value)} id="link" className=" p-2 w-full text-red-900 bg-red-50 rounded-lg border border-red-300 sm:text-xs focus:ring-blue-500 focus:border-blue-500 dark:bg-red-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
                        </div>
                      </div>
                    )
                  }
                  <div>
                    <div className="mt-2">
                      {/* <input
                          type="text"
                          placeholder="Please Enter a Caption"
                          ref={captionRef}
                          className=" w-full  text-center focus:outline-none"
                        /> */}

                      <label htmlFor="caption" className="block mb-2 text-sm font-medium text-red-900 dark:text-red-400">Caption</label>
                      <div className="flex ">
                        <textarea
                          id="caption"
                          rows="3"
                          value={inputStr}
                          className="flex-1 p-2.5 w-full text-sm text-red-900 bg-red-50 rounded-lg border border-red-300 focus:ring-red-500 focus:border-red-500 dark:bg-gray-700 dark:border-red-600 dark:placeholder-red-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500"
                          placeholder="Your message..." ref={captionRef} onChange={e => setInputStr(e.target.value)}></textarea>
                        <EmojiHappyIcon className="h-7 w-7 text-gray-700"
                          onClick={() => setShowPicker(val => !val)}
                        />
                        {showPicker && <Picker
                          pickerStyle={{ width: '100%' }}
                          onEmojiClick={onEmojiClick} />}
                      </div>
                    </div>
                    <div className="mt-2" >
                      <label htmlFor="price" className=" mb-2 text-sm font-medium text-red-900 dark:text-gray-400">Select Price</label>
                      <select id="price" onChange={handleSetPrice} className="bg-red-50 border border-red-300 text-red-900 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2 dark:bg-red-700 dark:border-red-600 dark:placeholder-red-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500">
                        <option >Free</option>
                        <option value="1000">#500</option>
                        <option value="1000">#1000</option>
                        <option value="2000">#2000</option>
                        <option value="3000">#3000</option>
                        <option value="5000">#5000</option>
                        <option value="custom">Set price</option>
                      </select>
                    </div>
                    <div className={` ${setPrice ? '' : 'hidden'} mt-2"`}>

                      <label htmlFor="caption" className="block mb-2 text-sm font-medium text-red-900 dark:text-red-400">Set Price</label>
                      <input
                        id="customprice"
                        type="number"
                        value={setAmount}
                        placeholder="Please Set your price"
                        className="block p-2.5 w-full text-sm text-red-900 bg-red-50 rounded-lg border border-red-300 focus:ring-red-500 focus:border-red-500 dark:bg-gray-700 dark:border-red-600 dark:placeholder-red-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500"
                        onChange={(e) => setSetAmount((e) => e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6">
                    <button
                      className="disables:bg-gray-400 inline-flex w-full justify-center rounded-md border border-transparent bg-red-400 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 disabled:cursor-not-allowed sm:text-sm "
                      onClick={uploadPost}
                      type="button"
                    // disabled={link.length < 4 }
                    >
                      {loading ? "Uploading..." : "Upload Post"}
                    </button>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog >
      </Transition.Root >
    </div>
  );
}

export default PostModal;
