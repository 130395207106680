import { Outlet } from 'react-router-dom';
const Layout = () => {
    return (
       
            <main className=" bg-[#f3f3f3]">
                <Outlet />
            </main>
    )
}

export default Layout