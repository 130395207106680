import { useGeolocated } from "react-geolocated";

const GetLocation = () => {
    const { coords, isGeolocationAvailable, isGeolocationEnabled } =
        useGeolocated({
            positionOptions: {
                enableHighAccuracy: false,
            },
            userDecisionTimeout: 5000,
        });
        console.log(coords)

    return !isGeolocationAvailable ?       console.log('Your browser does not support Geolocation')
    : !isGeolocationEnabled ? 
      console.log("Geolocation is not enabled")
     : coords ? coords : console.log("Getting the location data&hellip;");
};

export default GetLocation;