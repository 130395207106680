import React from "react";
import useUser from "../../hooks/use-user";
import MiniProfile from "./MiniProfile";
import Suggestions from "./Suggestions";
import Copyright from "./Copyright";
import { UserAuth } from "../../context/AuthContext";
import { currentfollowing, currentFullName, currentid, currentImage, currentuserId, currentusername } from "../../store/authSlice";
import { useSelector } from "react-redux";

function Sidebar() {

  const userId = useSelector((state) => currentuserId(state));
  const username = useSelector((state) => currentusername(state));
  const image = useSelector((state) => currentImage(state));
  const fullName = useSelector((state) => currentFullName(state));
  const following = useSelector((state) => currentfollowing(state));
  const id = useSelector((state) => currentid(state));



  return (
    <div className="md:flex flex-col mx-auto fixed right-14 h-full gap-2  ">

      <h1 className="font-bold mb-5">Trending Stars</h1>
      <div className=" flex justify-between items-center">
        <div className="overflow-hidden relative w-6 h-6 bg-gray-100 rounded-full dark:bg-gray-600 items-center justify-center">
          <svg className="absolute -left-1 w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clipRule="evenodd"></path></svg>
        </div>
        <h2 className="font-semibold">Blessing </h2>
        <span className="text-xl ml-3">+</span>
      </div>
      <div className="flex justify-between items-center ">
        <div className="overflow-hidden relative w-6 h-6 bg-gray-100 rounded-full dark:bg-gray-600 items-center justify-center">
          <svg className="absolute -left-1 w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clipRule="evenodd"></path></svg>
        </div>
        <h2 className="font-semibold">Vibesoflife </h2>
        <span className="text-xl ml-3">+</span>
      </div>
      <div className="flex justify-between items-center ">
        <div className="overflow-hidden relative w-6 h-6 bg-gray-100 rounded-full dark:bg-gray-600 items-center justify-center">
          <svg className="absolute -left-1 w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clipRule="evenodd"></path></svg>
        </div>
        <h2 className="font-semibold">Nicholas </h2>
        <span className="text-xl ml-3">+</span>
      </div>
      <div className="flex justify-between items-center ">
        <div className="overflow-hidden relative w-6 h-6 bg-gray-100 rounded-full dark:bg-gray-600 items-center justify-center">
          <svg className="absolute -left-1 w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clipRule="evenodd"></path></svg>
        </div>
        <h2 className="font-semibold">Blessing1</h2>
        <span className="text-xl ml-3">+</span>
      </div>
      <div className="flex justify-between items-center ">
        <div className="overflow-hidden relative w-6 h-6 bg-gray-100 rounded-full dark:bg-gray-600 items-center justify-center">
          <svg className="absolute -left-1 w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clipRule="evenodd"></path></svg>
        </div>
        <h2 className="font-semibold"> Nicholas </h2>
        <span className="text-xl ml-3">+</span>
      </div>


      {/* <MiniProfile username={username} fullName={fullName} image={image ? image : "/images/default.png"} />
      <Suggestions
        userId={userId}
        following={following}
        loggedInUserDocId={id}
      />
      <Copyright /> */}
    </div>
  );
}

export default Sidebar;
