
import Sidebar from "../components/Sidebar/Sidebar";
import Feed from "../components/Feed/Feed";
import SiderBarLeft from "../components/SiderbarLeft/SiderBarLeft";
import { useSelector } from "react-redux";
import { currentuserId } from "../store/authSlice";
import { statusFavorites } from "../store/favoriteSlice";
import FeedFavorite from "../components/Feed/FeedFavorite";




function Dashboard() {

   const userId = useSelector((state) => currentuserId(state));
   
  const sfavorite = useSelector(statusFavorites);
 const fd = sfavorite ? <FeedFavorite /> : <Feed />;
  return (
        
         <div className=" mx-auto flex justify-center min-h-screen mt-20 w-full bg-[#f3f3f3]">
            <section className="mt-4 relative hidden w-64  lg:block ">
              <SiderBarLeft />
            </section>
            <section className=" relative flex-1 z-10 pt-3 lg:ml-16 ">
              {fd }
            </section>
            <section className="mt-10 hidden w-64 md:block  relative">
              <Sidebar />
            </section>
          </div>
      
      //  : (
      //   <>
      //     <p className="m-10 mt-24 text-center text-5xl font-bold">
      //       Error 404!!!
      //     </p>
      //     <p className="m-8 text-center text-3xl font-bold">
      //       Sorry, this page isn't available.
      //     </p>
      //     <p className="mx-3 text-center text-lg font-semibold md:text-xl">
      //       It seems like you are trying to access the Dashboard. But
      //       unfortunately you don't seem to have logged in to an account.
      //     </p>
      //     <br />
      //     <p className="mx-3 text-center text-lg font-semibold md:text-xl">
      //       Please click the below button to head towards the Login page to
      //       access the Dashboard.
      //     </p>
      //     <Link to={ROUTES.SIGNIN} className="flex items-center justify-center">
      //       <button className="my-3 w-44 rounded-md border bg-sky-500 py-2 text-center text-lg font-semibold text-white">
      //         Go To Login
      //       </button>
      //     </Link>
      //   </>
      // )
  
   
  );
}

export default Dashboard;
