import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  messagebox: false,
  cuserId: '',
};

const messageSlice = createSlice({
  name: "message",
  initialState,
  reducers: {
 
    togglemessagebox: (state) => {
      state.messagebox =  !state.messagebox;
    },
    closemessagebox: (state) => {
      state.messagebox =  false;
    },
    openusermessagebox: (state, action) => {
     
      state.cuserId =  action.payload.userId;
      state.messagebox = true;
    },
  },
});

export const {
  togglemessagebox, closemessagebox, openusermessagebox,
} = messageSlice.actions;
export const messagebox = (state) => state.message.messagebox;
export const currentProfileId = (state) => state.message.cuserId;

export default messageSlice.reducer;
