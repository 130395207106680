import { useState } from 'react';
import PostVideo from './postVideo';
import PostArticule from './postArticule';
import PostMach from './postMach';
import { closePostModel, PostModelStateRe } from '../../../store/modelSlice';
import { useDispatch, useSelector } from 'react-redux';

const PostBox = () => {

  const open = useSelector((state) => PostModelStateRe(state));
  const dispatch = useDispatch();

  const [selectBox, setSelectBox] = useState(0);

  let content = '';
  const headerSub = (<div className='flex justify-between mb-3'>
    <div onClick={() => setSelectBox(0)} className='flex'>
      <span className='cursor-pointer'>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M19 12H6M12 5l-7 7 7 7" /></svg>
      </span>
      <span className='cursor-pointer'>Back</span>
    </div>
    <div ><span onClick={() => dispatch(closePostModel())} className='border-2 border-red-500 bg-white text-red-500 rounded-full px-1 '>x</span></div>
  </div>)
  if (selectBox === 1) {
    content = (
      <div className="border-0 rounded-lg  gap-2  flex flex-col mx-auto w-full m-2 p-4 bg-white outline-none focus:outline-none">
        {headerSub}
        <PostVideo />
      </div>
    )
  }
  else if (selectBox === 2) {
    content = (
      <div className="border-0 rounded-lg  gap-2  flex flex-col mx-auto w-full m-2 p-4 bg-white outline-none focus:outline-none">
        {headerSub}
        <PostVideo />
      </div>
    )
  }
  else if (selectBox === 3) {
    content = (
      <div className="border-0 rounded-lg  gap-2  flex flex-col mx-auto w-full m-2 p-4 bg-white outline-none focus:outline-none">
      {headerSub}
        <PostArticule />
      </div>
    )
  }
  else if (selectBox === 4) {
    content = (
      <div className="border-0 rounded-lg  gap-2  flex flex-col mx-auto w-full m-2 p-4 bg-white outline-none focus:outline-none">
      {headerSub}
        < PostMach />
      </div>
    )
  }
  else {
    content = (
      <div>
        <div className="border-0 rounded-lg shadow-lg  flex flex-col w-full bg-white outline-none focus:outline-none">
          <div className="flex items-start justify-between p-3 border-b border-solid border-gray-300 rounded-t ">
            <h3 className="text-xl font=semibold">Select Content Category</h3>
            {/* <button
          className="bg-transparent border-0 text-red-500 float-right"
          
        > */}
            <span onClick={() => dispatch(closePostModel())}
              className='border-2 border-red-500 bg-white text-red-500 rounded-full px-1 '>

              x
            </span>
            {/* </button> */}
          </div>
          <div className=" p-3 flex justify-center items-center mx-auto w-full">
            <div className='flex flex-col gap-2'>
              <div className='flex h-32 justify-center items-center m-2 '

              >
                <div className='h-full rounded-md w-32 p-2 bg-white  flex flex-col justify-center items-center  m-2 shadow-md hover:bg-red-500'
                  onClick={() => setSelectBox(1)}>

                  <span><svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M15.6 11.6L22 7v10l-6.4-4.5v-1zM4 5h9a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V7c0-1.1.9-2 2-2z" /></svg></span>
                  <span className='text-sm'>Video or Picture</span>


                </div>
                {/* <div className='h-full rounded-md hover:bg-red-500 w-32 bg-white flex flex-col  justify-center items-center m-2 shadow-md' */}
                <div className='h-full rounded-md w-32 p-2 bg-white  flex flex-col justify-center items-center  m-2 shadow-md hover:bg-red-500'

                  onClick={() => setSelectBox(2)}>
                  <span><svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><circle cx="5.5" cy="17.5" r="2.5" /><circle cx="17.5" cy="15.5" r="2.5" /><path d="M8 17V5l12-2v12" /></svg></span>
                  <span className='text-sm text-center'>Music, Podcast, gossip</span>
                </div>
              </div>
              <div className='flex h-32 justify-center items-center m-2'>
                <div className=' h-full rounded-md p-2 hover:bg-red-500 w-32 bg-white flex flex-col  justify-center items-center m-2 shadow-md'
                  onClick={() => setSelectBox(3)}
                >

                  <span><svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M14 2H6a2 2 0 0 0-2 2v16c0 1.1.9 2 2 2h12a2 2 0 0 0 2-2V8l-6-6z" /><path d="M14 3v5h5M16 13H8M16 17H8M10 9H8" /></svg></span>
                  <span className='text-sm'>Article</span>

                </div>
                <div className=' h-full rounded-md p-2 hover:bg-red-500 w-32 bg-white flex flex-col  justify-center items-center m-2 shadow-md'
                  onClick={() => setSelectBox(4)}>

                  <span><svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><circle cx="10" cy="20.5" r="1" /><circle cx="18" cy="20.5" r="1" /><path d="M2.5 2.5h3l2.7 12.4a2 2 0 0 0 2 1.6h7.7a2 2 0 0 0 2-1.6l1.6-8.4H7.1" /></svg></span>
                  <span className='text-sm'>merchandise</span>
                </div>


              </div>
            </div>

          </div>

        </div>
      </div>
    )
  }
  return (
    <>
      {open ? (
        <div className='block fixed z-40  left-0 top-0 w-full h-full bg-black bg-opacity-70 overflow-hidden'>
          <div className="flex justify-center items-center mx-auto overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none w-1/3 mx-auto min-w-96">
            <div className="w-full my-6 mx-auto  ml-6">
              {content}
            </div>
          </div>
        </div>

      ) : null}
    </>
  );
};

export default PostBox