import {collection,  getDocs } from "firebase/firestore";


import {  db } from '../firebase';

export async function getAllMechs() {
    const getResult = await getDocs(collection(db, "merchandise"));
    const userResult = getResult.docs
      .map((user) => ({
        ...user.data(),
        id: user.id,
      }));
    return userResult;
  }

