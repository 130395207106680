import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { UserAuth } from '../context/AuthContext';
import { DASHBOARD } from "../constants/routes";
import { useSelector } from 'react-redux';
import { currentuserId, loggedIn } from '../store/authSlice';
import { AiFillEyeInvisible, AiFillEye } from 'react-icons/ai'

import bglogin from "../assets/images/bg.png";
import bglogin3 from "../assets/images/layer.png";
import logo from "../assets/images/logos2.png";


const Signin = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { signIn, googleSignIn } = UserAuth();

  const [visblePwd, setVisblePwd] = useState(false)
  const [butDisable, setButDisable] = useState(false)

  // handle toggle 
  const toggle = () => {
    setVisblePwd(!visblePwd)
  }

  const userId = useSelector((state) => currentuserId(state));
  const isLogged = useSelector((state) => loggedIn(state));
  const handleSubmit = async (e) => {

    e.preventDefault();
    setButDisable(true);
    try {
      await signIn(email, password)
      // navigate(DASHBOARD)
    } catch (e) {
    setButDisable(false);

      if (e.code === 'auth/wrong-password') { setError('Invalid email or password') }
      else if (e.code === 'auth/too-many-requests') { setError('This account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.') }
      else {
        setError('Invalid ')
      }

    }
  };
  const handleGoogleSignIn = async () => {
    try {
      await googleSignIn();
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (isLogged) {
      navigate(DASHBOARD);
    }
  }, [userId]);

  return (
    <div className=' h-screen flex justify-center items-center bg-red-400' style={{ backgroundImage: `url(${bglogin})` }}>
      <div className='flex flex-row h-full xl:h-[85vh] my-auto py-14 px-16 w-full xl:max-w-5xl  2xl:max-w-[120rem] mx-auto  justify-center items-center rounded-l-xl'>
        <div className='h-full hidden md:block flex-1 '
          style={{ backgroundImage: `url(${bglogin3})` }}
        >
          {/* <img className='h-full w-full' src={bglogin3} alt="" /> */}
          <div className='flex justify-center items-center h-full w-full'>
            <div className='text-red-500 text-5xl 2xl:-mt-36  font-extrabold text-center'>
              Let's Create, <br />
              Let's Play
            </div>
          </div>
        </div>
        <div className='h-full flex-1 justify-between items-center  mx-auto bg-white rounded-r-xl p-2 px-6 ' style={{ boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px' }}>
          <div className='flex flex-col 2xl:mt-40'>
            <div className='h-24 w-24 flex mx-auto justify-center  items-center'>
              <img src={logo} alt="Logo" className=" object-contain h-full w-full" />
            </div>
            <div className='-mt-2'>

              <h1 className='text-center text-xl font-bold'>Sign in</h1>
            </div>

            <form onSubmit={handleSubmit}>

              <div id="toast-danger" className={` ${error ? '' : 'hidden'} flex items-center p-4 mb-2 w-full text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800`} role="alert">
                <div className="inline-flex flex-shrink-0 justify-center items-center w-8 h-8 text-red-500 bg-red-100 rounded-lg dark:bg-red-800 dark:text-red-200">
                  <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                  <span className="sr-only">Error icon</span>
                </div>
                <div className="ml-3 text-sm font-normal">{error}</div>
                <button type="button"
                  onClick={() => setError('')}
                  className="ml-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700" data-dismiss-target="#toast-danger" aria-label="Close">
                  <span className="sr-only">Close</span>
                  <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                </button>
              </div>


              <div className='flex flex-col py-1'>
                <label className='text-sm '>Email Address</label>
                <input onChange={(e) => { setEmail(e.target.value); setError(''); }} value={email} className='border p-1 rounded-md' type='email' />
              </div>
              <div className='flex flex-col py-1 mb-8'>
                <label className='text-sm '>Password</label>
                <div className='relative'>
                  <input
                    onChange={(e) => setPassword(e.target.value)} value={password}
                    className='absolute w-full border p-1 rounded-md'
                    type={(visblePwd === false) ? 'password' : 'text'}
                  />
                  <div className='text-2xl absolute top-2 right-5'>
                    {
                      (visblePwd === false) ? <AiFillEye onClick={toggle} /> :
                        <AiFillEyeInvisible onClick={toggle} />

                    }
                  </div>
                </div>
              </div>
              <Link to='/forgetpassword' className="font-medium  text-red-600 hover:underline">
                Forget Password.
              </Link>
             {butDisable ? 
              (<button disabled type="button" class="w-full py-2.5 px-5 text-sm font-medium text-white border-red-500 bg-red-600 hover:bg-red-800 rounded-md border  hover:text-red-700 focus:z-10 focus:ring-2 focus:ring-red-700 focus:text-red-700 flex mx-auto justify-center items-center">
                <svg aria-hidden="true" role="status" class="inline w-4 h-4 mr-2 text-gray-200 animate-spin dark:text-gray-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                  <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#DC2626" />
                </svg>
                Loading...
              </button>):
              (<button className='border border-red-500 bg-red-600 hover:bg-red-800 w-full p-1 mt-4 text-white rounded-md'>
              Sign In
            </button>)
              }
            </form>

            <div className="relative flex items-center justify-center w-full mt-6 border border-t">
              <div className="absolute px-5 text-sm bg-white">Or Continue with </div>
            </div>
            <div className="flex mt-4 gap-x-2">
              <button
                type="button"
                onClick={handleGoogleSignIn}
                className="flex items-center justify-center w-full p-2 border border-gray-600 rounded-md focus:ring-2 
          focus:ring-offset-1 focus:ring-violet-600 hover:bg-red-800"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="inline w-4 h-4 mr-3 text-gray-900 fill-current" viewBox="0 0 48 48" width="48px" height="48px">
                  <path fill="#fbc02d" d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12 s5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24s8.955,20,20,20 s20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"></path><path fill="#e53935" d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039 l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"></path><path fill="#4caf50" d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36 c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"></path><path fill="#1565c0" d="M43.611,20.083L43.595,20L42,20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571 c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z">
                  </path></svg>
                Google
              </button>

            </div>

            <p className="mt-2 text-xs font-light text-center text-gray-700">
              {" "}
              Don't have an account?{" "}

              <Link to='/signup' className="font-medium text-red-600 hover:underline">
                Sign up.
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>


  );
};

export default Signin;
