import React, { useContext, useState } from "react";
import {
  BookmarkIcon,
  ChatIcon,
  HeartIcon,
  PaperAirplaneIcon,

} from "@heroicons/react/outline";

import { useRecoilState } from "recoil";
import { likesModalState } from "../../atoms/modalAtom";
import { photoIdState } from "../../atoms/idAtom";
import { HeartIcon as HeartIconFilled } from "@heroicons/react/solid";
import PropTypes from "prop-types";
import { updateLikes, updateSaves } from "../../context/PostFirbase";
import { currentuserId, loggedIn } from "../../store/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { openAuth, openSharePostModel } from "../../store/modelSlice";


function Buttons({ id, totalLikes, totalShares, totalComments, likedPhoto, handleFocus,
   numberComment, setshowComment, showComment, savedPhoto, totalSaved,  fileType, fileKind }) {

  const userId = useSelector((state) => currentuserId(state));
  const isLogged = useSelector((state) => loggedIn(state));

  const dispatch = useDispatch()

  const [ishowComment, setishowComment] = useState(false); 

  const handleCommentShow = () =>{
    setshowComment(!ishowComment);
    setishowComment(!ishowComment);
  }
  const [open, setOpen] = useRecoilState(likesModalState);
  const [photoId, setPhotoId] = useRecoilState(photoIdState);
  const [toggledLiked, setToggledLiked] = useState(likedPhoto);
  const [likes, setLikes] = useState(totalLikes);

  const [toggledSaved, setToggledSaved] = useState(savedPhoto);
  const [saves, setSaves] = useState(totalSaved);
  // update the like count of the photo
  const handleToggleLiked = async () => {
    if (!isLogged) {
      dispatch(openAuth());
    } else {
      setToggledLiked((toggledLiked) => !toggledLiked);
      await updateLikes(id, userId, toggledLiked);
      setLikes((likes) => (toggledLiked ? likes - 1 : likes + 1));
    }
  };

  const handleToggleSaved = async () => {
    if (!isLogged) {
      dispatch(openAuth());
    } else {
      setToggledSaved((toggledSaved) => !toggledSaved);
      await updateSaves(id, userId, toggledSaved, fileType, fileKind);
      setSaves((saves) => (toggledSaved ? saves - 1 : saves + 1) );
    }
  };

  const handleToggleActive = async () => {
    setOpen(true);
    setPhotoId(id);
  };
  const handleShare = () => {
    if (!isLogged) {
      dispatch(openAuth());
    }
    
    else { dispatch(openSharePostModel({ postId: id })); }
  };

  return (
    <div>
      <div className="flex flex-col my-2 mx-6 ">
        <div className="flex justify-between">
          <div className="text-sm  flex justify-start items-center">
            <button
              onClick={handleToggleLiked}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  handleToggleLiked();
                }
              }}
            >
              {toggledLiked ? (
                <HeartIconFilled className="postButton h-8 w-8 text-red-500" />
              ) : (
                <HeartIcon className="postButton h-8 w-8" />
              )}

            </button>
            {likes > 1 ? `${likes} likes` : `${likes} like`}

          </div>
          <div className="flex justify-center items-center  text-xs cursor-pointer">
            <div onClick={()=>handleCommentShow()}>{numberComment > 1 ? `${numberComment} comments` : `${numberComment} comment`}</div>
          </div>
          <div className="flex justify-center gap-1 items-center  ml-3 cursor-pointer" onClick={handleShare} >
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><circle cx="18" cy="5" r="3"></circle><circle cx="6" cy="12" r="3"></circle><circle cx="18" cy="19" r="3"></circle><line x1="8.59" y1="13.51" x2="15.42" y2="17.49"></line><line x1="15.41" y1="6.51" x2="8.59" y2="10.49"></line></svg>
            </span>
            <span > {totalShares ?? 0} share </span>
          </div>
          {/* <div className="flex justify-center gap-1 items-center  ml-3 cursor-pointer"
          //  onClick={handleShare} 
          >
            <span>
              
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" width="18" height="18"  viewBox="0 0 24 24" strokeWidth="2" stroke="#000000" aria-hidden="true"><path strokeLinecap="round" strokeLinejoin="round" d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z"></path></svg>
            </span>
            
            <span > {totalShares ?? 0} save </span>
          </div> */}

          <div className="text-sm  flex justify-start items-center">
            <button onClick={handleToggleSaved} >
              {toggledSaved ? (
                <BookmarkIcon className="postButton h-4 w-4 text-red-500" />
              ) : (
                <BookmarkIcon className="postButton" />
              )}

            </button>
            { saves ? (saves > 1 ? `${saves} saves` : `${saves} save`) : ' 0 save'}

          </div>

        </div>
      </div>



      {/* <BookmarkIcon className="postButton" /> */}

    </div>
  );
}

export default Buttons;

Buttons.propTypes = {
  id: PropTypes.string.isRequired,
  totalLikes: PropTypes.number,
  likedPhoto: PropTypes.bool.isRequired,
  handleFocus: PropTypes.func.isRequired,
};
