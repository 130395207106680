import { useState } from 'react';
import './style.css';
import { WithContext as ReactTags } from 'react-tag-input';
import { selectAllUsers } from '../../store/usersSlice';
import { useSelector } from 'react-redux';


function Tag (setGetTag) {
  const [tags, setTags] = useState([]);
  const users = useSelector(selectAllUsers);
  // console.log(users);

  const suggestions = users.map((dt) => {
     return{id: dt.userId, text: dt.username};
  });

  const KeyCodes = {
    comma: 188,
    enter: 13,
  };
  
  const delimiters = [KeyCodes.comma, KeyCodes.enter];
  const handleDelete = (i) => {
    setTags(tags.filter((tag, index) => index !== i));
  };
  const handleAddition = (tag) => {
    setTags([...tags, tag]);
  };
  const handleDrag = (tag, currPos, newPos) => {
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    setTags(newTags);
    setGetTag(newTags);
  };

  const handleTagClick = (index) => {
    console.log('The tag at index ' + index + ' was clicked');
  };

  return (
       <div>
        <ReactTags
          tags={tags}
          suggestions={suggestions}
          delimiters={delimiters}
          handleDelete={handleDelete}
          handleAddition={handleAddition}
          handleDrag={handleDrag}
          handleTagClick={handleTagClick}
          inputFieldPosition="bottom"
          autocomplete
          editable
        />
      </div>
  );
};

export default Tag;