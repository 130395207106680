import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ReactTimeAgo from "react-time-ago";
import { selectUserById } from "../../../store/usersSlice";

const NotificationDiv = ({ notification }) => {
    const postUser = useSelector((state) => selectUserById(state, notification?.userId));
    // console.log(postUser);

    return (
        <Link to={notification?.link} className='flex items-center justify-between gap-2 flex-row flex-nowrap mb-2'>
            <span className="">
                <img
                    className="h-10 w-10 rounded-full"
                    src={postUser?.image ? postUser?.image : "/images/default.png"} alt="" /></span>
            <div className="flex-1 truncate pt-1 ml-1">
                <div className="font-bold text-gray-800 text-sm">{postUser?.username}</div>
                <div className="truncate text-xs"> {notification?.message}</div>
            </div>
            <div className="text-xs font-normal text-gray-400 dark:text-primary-light">
                <ReactTimeAgo
                    date={notification?.timestamp?.toDate()}
                    locale="en-US"
                    timeStyle="round"
                    className="mt-1 text-xs capitalize text-red-500"
                />
            </div>
        </Link>
    )
}

export default NotificationDiv