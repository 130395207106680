import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';

import { XIcon } from '@heroicons/react/outline'


import {
  SearchIcon,
  LoginIcon,
} from "@heroicons/react/outline";
import * as ROUTES from "../constants/routes";
import { Link } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { togglenotificationbox, togglePostModel } from "../store/modelSlice";
import { currentImage, currentuserId, currentusername, logOut } from "../store/authSlice";

import './Header.css';
import { displayNoty, resetNotification, } from "../context/profileFirebase";
import { useRecoilState } from "recoil";
import { postModalState } from "../atoms/modalAtom";
import { selectAllUnread } from "../store/chatSlice";

import logo from "../assets/images/logos.png";

function Header() {
  // const { user, userDt } = UserAuth();
  // const { username, image } = userDt;
  const [open, setOpen] = useRecoilState(postModalState);

  const userId = useSelector((state) => currentuserId(state));
  const username = useSelector((state) => currentusername(state));
  const image = useSelector((state) => currentImage(state));
  // const currentfollowers= useSelector((state) => currentfollowers);
  // const currentfullName= useSelector((state) => currentfullName);
  // const currentemailAddress= useSelector((state) => currentemailAddress);
  // const currentbio= useSelector((state) => currentbio);
  // const currentfollowing= useSelector((state) => currentfollowing);
  // const currentdateCreated= useSelector((state) => currentdateCreated);
  // const currentid= useSelector((state) => currentid);

  const [active, setActive] = useState(false);
  const [search, setSearch] = useState("");
  const [nCounter, setNCounter] = useState(0);

  const dispatch = useDispatch()
  const navigate = useNavigate();
  const handleLogOut = () => {
    dispatch(logOut());
    navigate('/signin');
  }
  const handleLogIn = () => {
    navigate('/signin');
  }
  const handleNotification = () => {
    dispatch(togglenotificationbox());
    if (nCounter > 0) { resetNotification(userId) };
  }
  const handleMessage = () => {

    navigate('/messages');
  }
  const mcounter = useSelector(selectAllUnread);
  // console.log('mcounter', mcounter);
  useEffect(() => {
    async function showNoti() {
      const vvount = await displayNoty(userId);
      setNCounter(vvount);
    }
    userId && showNoti();
  }, [userId]);


  return (
    // sticky top-0 z-30 h-[72px] bg-gray-900 bg-opacity-50 backdrop-blur backdrop-filter  firefox:bg-opacity-90
    // flex items-center justify-between border-b border-gray-800 px-4 py-5 sm:px-6 lg:px-8 xl:px-0
    // <div className=" border-b bg-white  shadow-sm w-full ">
    <div className="fixed top-0 left-0 w-screen border-b-2 bg-white z-30">
      <div className=" flex  items-center  justify-between bg-white xl:mx-auto pr-4 ">
        {/* Left Side */}
        <Link
          to={ROUTES.DASHBOARD}
          className="p-0 w-24 md:w-32 lg:ml-5   cursor-pointer sm:inline-grid"
        >
          <img src={logo} alt="Logo" className=" object-contain" />
        </Link>

        {/* Middle Part */}
        <div className={`-mr-1 ${active ? " w-[600px]" : "max-w-[140px] sm:max-w-xs"}`}>
          <div className="relative mt-1 mb-1 rounded-lg p-2">
            <div className="pointer-events-none absolute inset-y-0 flex items-center pl-3">
              <SearchIcon className="h-4 w-4 text-black" />
            </div>
            <input
              type="text"
              placeholder="Search"
              className="block text-black w-full rounded-lg border-[2.4px] border-gray-400 bg-gray-200 p-1 pl-10 hover:border-gray-500 focus:border-gray-500 focus:outline-none sm:text-base"
              onClick={() => setActive(true)}
              onKeyDown={() => setActive(true)}
              value={search}
              onChange={({ target }) => setSearch(target.value)}
            />
            <div
              className={`absolute top-[22px] right-5 flex cursor-pointer items-center justify-end outline-none ${!active && "hidden"
                }`}
              onClick={() => { setSearch(""); setActive(false) }}
            >
              <XIcon className="h-4 w-4 cursor-pointer text-gray-500" />
            </div>
          </div>
        </div>
        {/* Right Side */}

        <div className=" md:block">
          {username ?
            (<div className="flex items-center lg:mr-10">
              {/* <button onClick={() => dispatch(togglemessagesbox())} className="py-4 px-1 relative border-2 border-transparent text-gray-800 rounded-full hover:text-gray-400 focus:outline-none focus:text-gray-500 transition duration-150 ease-in-out" aria-label="Cart"> */}
              <button
                onClick={handleMessage}
                className="py-4 px-1 relative border-2 border-transparent text-gray-800 rounded-full hover:text-gray-400 focus:outline-none focus:text-gray-500 transition duration-150 ease-in-out" aria-label="Cart">

                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
                </svg>

                <span className="absolute inset-0 object-right-top -mr-6">
                  <div className="inline-flex items-center px-1.5 py-0.5 border-2 border-white rounded-full text-xs font-semibold leading-4 bg-red-500 text-white">
                    {mcounter}
                  </div>
                </span>
              </button>
              {/* <div class="dropdown"> */}
              {/* <button className="py-4 px-1 relative border-2 border-transparent text-gray-800 rounded-full hover:text-gray-400 focus:outline-none focus:text-gray-500 transition duration-150 ease-in-out" aria-label="Cart"> */}
              <button
                onClick={handleNotification} className="py-4 px-1 relative border-2 border-transparent text-gray-800 rounded-full hover:text-gray-400 focus:outline-none focus:text-gray-500 transition duration-150 ease-in-out" aria-label="Cart">

                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0" />
                </svg>
                {nCounter ? (<span className="absolute inset-0 object-right-top -mr-6">
                  <div className="inline-flex items-center px-1.5 py-0.5 border-2 border-white rounded-full text-xs font-semibold leading-4 bg-red-500 text-white">
                    {nCounter}
                  </div>
                </span>) : ''}
              </button>
              {/* <HeaderNotificationButton /> */}
              {/* <div class="dropdown-options">
                  <NotificationContainer />
                  </div> */}
              {/* </div> */}

              <button
                // onClick={() => setOpen(true)}
                onClick={() => dispatch(togglePostModel())}

                className="py-1 px-5  bg-red-500 text-white  rounded-lg hover:text-gray-400 focus:outline-none focus:text-gray-500 transition duration-150 ease-in-out" aria-label="Cart">
                <span className="text-xl mr-3">+</span>
                POST

              </button>

              {/* <Menu as="div" className="ml-3 relative">
                  <div>
                    <Menu.Button className="max-w-xs bg-gray-800 rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                      <span className="sr-only">Open user menu</span>
                      <img className="h-8 w-8 rounded-full" src={image} loading="lazy" alt="" />
                    </Menu.Button>
                  </div>
                  <Transition
                    //   as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                    className="absolute right-0 w-48 py-1 bg-white rounded-md shadow-lg top-12 ring-1 ring-black ring-opacity-5 dark:bg-dark focus:outline-none"
                  >
                    <Menu.Items
                    // className="origin-top-right absolute right-0 mt-2 w-28 rounded-md shadow-lg py-1 bg-blue ring-1 ring-black ring-opacity-5 focus:outline-none"
                    >

                      <Menu.Item key={1}   >

                        <Link to={`/profile/${username}`} className={`block px-2 py-1 text-sm text-gray-700 } `}
                        >
                          My Profile
                        </Link>
                      </Menu.Item>
                      <Menu.Item key={2}   >
                        <Link to="#" className={`block px-2 py-1 text-sm text-gray-700 } `}
                        >
                          Setting
                        </Link>
                      </Menu.Item>
                      <Menu.Item key={3}   >
                        <Link to={`#`} className={`block px-2 py-1 text-sm text-gray-700 } `}
                          onClick={() => { signOut(auth); handleLogOut(); }}
                        >

                          Sign Out
                        </Link>


                      </Menu.Item>

                    </Menu.Items>
                  </Transition>
                </Menu> */}
            </div>) :
            (
              <Link
                to={ROUTES.SIGNIN}
                className="mt-[6px] flex items-center space-x-2 rounded-md p-2 text-lg mr-14 font-semibold transition ease-in hover:bg-gray-300 hover:bg-opacity-50 sm:mt-[1px]"
                onClick={handleLogIn}
              >
                <LoginIcon className="navButton  " />
                <span>Log In</span>
              </Link>
            )}
        </div>

        {/* </div> */}
      </div>
    </div>
    // </div>

  );


}

export default Header;
