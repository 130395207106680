import { addDoc, arrayRemove, arrayUnion, collection, doc, getDocs, serverTimestamp, updateDoc } from "firebase/firestore";


import {  db } from '../firebase';

export async function getAllArticles() {
    const getResult = await getDocs(collection(db, "articles"));
    const userResult = getResult.docs
      .map((user) => ({
        ...user.data(),
        id: user.id,
      }))
      .sort((a, b) => b.timestamp - a.timestamp);
    return userResult;
  }

  export async function ShareArticlesToFirestore(
    userId,
    caption,
    sharedID,
    kind
  ) {
    const postRef = await addDoc(collection(db, "articles"), {
      userId: userId,
      caption: caption,
      fileType: 'share',
      fileKind: kind,
      sharedId: sharedID,
      comments: [],
      likes: [],
      saves: [],
      timestamp: serverTimestamp(),
    });
  
  }

  export async function updateArticleShares(id, userId) {
    const photosRef = doc(db, "articles", id);
    await updateDoc(photosRef, {
      shares: arrayUnion(userId),
    });
  }