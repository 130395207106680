import React, { Fragment, useEffect, useRef, useState } from "react";

import { Dialog, Transition } from "@headlessui/react";
import { CameraIcon, CloudUploadIcon, EmojiHappyIcon, XIcon } from "@heroicons/react/outline";
import { addPostsToFirestore2, addPostsToFirestore3, addPostsToFirestore4, updatePostShares } from "../../context/PostFirbase";
import { useDispatch, useSelector } from "react-redux";
import { currentuserId } from "../../store/authSlice";

import Picker from 'emoji-picker-react';
import { closeSharePostModel, PostId, sharePostModel } from "../../store/modelSlice";
import PostShare from "../Feed/PostShare";
import { selectPostById, selectRealPostById } from "../../store/postSlice";
import { addNotification } from "../../context/profileFirebase";


function SharePostModal() {
  const userId = useSelector((state) => currentuserId(state));

  
  const dispatch = useDispatch();
  const captionRef = useRef(null);
  const [loading, setLoading] = useState(false);

  const [inputStr, setInputStr] = useState('');
  const [showPicker, setShowPicker] = useState(false);

  const onEmojiClick = (event, emojiObject) => {
    setInputStr(prevInput => prevInput + emojiObject.emoji);
    setShowPicker(false);
  };


  const postid = useSelector((state) => PostId(state));
  const open = useSelector((state) => sharePostModel(state));

  const post = useSelector((state) => selectRealPostById(state, postid));

 

  const uploadPost = async () => {
    if (loading) return;
    setLoading(true);
    const caption = captionRef.current.value;
   await addPostsToFirestore4( userId,caption, post?.id, post?.fileKind ) ;
   await addNotification(post?.userId, userId, "share your post", "post/"+postid) 
   await updatePostShares(post?.id, userId);
    // setOpen(false);
    dispatch(closeSharePostModel());   
    document.location.reload();
  };
  // to display the image to be posted in the modal

  return (
    <div className="my-auto mx-auto overflow-y-auto"  >
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="absolute inset-0 z-[60] overflow-y-auto"
          onClose={() => dispatch(closeSharePostModel())}
        >
          <div className="m-2 flex min-h-screen items-center justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0 ">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-500"
              enterFrom="opactiy-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-500"
              enterFrom="opactiy-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block w-full transhtmlForm space-y-4 overflow-y-scroll rounded-lg bg-white p-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:max-w-sm sm:p-6 sm:align-middle">
                <div className="flex justify-between">

                  <div as="h3" className="flex items-center space-x-4  text-2xl ">
                    <div className=" flex h-8 w-8 items-center justify-center rounded-full bg-red-100">
                      <CloudUploadIcon className="mt-[2px] h-4 w-4 text-red-600" />
                    </div>
                    <span>Share Post </span>
                  </div>
                  <button
                    className="float-right outline-none"
                    onClick={() => {
                      // setOpen(false);
                      dispatch(closeSharePostModel());
                      setLoading(false);
                    }}
                  >
                    <XIcon className=" h-6  w-6 cursor-pointer text-gray-300" />
                  </button>
                </div>
                <hr />

                <div>

                  <div>
                    <div className="mt-2">
                     
                    {post ? (<PostShare key={post.id} content={post} />) : ''}
                  {(post?.fileKind && post?.fileKind === 'text')  ?
                   (<p className="p-4 text-xl">{post?.text}</p>):""
                    }
                    
                    
                      <label htmlFor="caption" className="block mb-2 text-sm font-medium text-red-900 dark:text-red-400">Caption</label>
                      <div className="flex ">
                        <textarea
                          id="caption"
                          rows="3"
                          value={inputStr}
                          className="flex-1 p-2.5 w-full text-sm text-red-900 bg-red-50 rounded-lg border border-red-300 focus:ring-red-500 focus:border-red-500 dark:bg-gray-700 dark:border-red-600 dark:placeholder-red-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500"
                          placeholder="Your message..." ref={captionRef} onChange={e => setInputStr(e.target.value)}></textarea>
                        <EmojiHappyIcon className="h-7 w-7 text-gray-700"
                          onClick={() => setShowPicker(val => !val)}
                        />
                        {showPicker && <Picker
                          pickerStyle={{ width: '100%' }}
                          onEmojiClick={onEmojiClick} />}
                      </div>
                    </div>
                   
                  
                  </div>
                  <div className="mt-5 sm:mt-6">
                    <button
                      className="disables:bg-gray-400 inline-flex w-full justify-center rounded-md border border-transparent bg-red-400 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 disabled:cursor-not-allowed sm:text-sm "
                      onClick={uploadPost}
                      type="button"
                    // disabled={link.length < 4 }
                    >
                      Share
                    </button>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog >
      </Transition.Root >
    </div>
  );
}

export default SharePostModal;
