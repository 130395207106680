
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Picker from 'emoji-picker-react';
import { EmojiHappyIcon } from '@heroicons/react/outline';
import { currentuserId } from '../../store/authSlice';
import { selectChatByCombinedID, setcurrentchat } from '../../store/chatSlice';
import { selectUserById, selectUserByUsername } from '../../store/usersSlice';
import { addMessageNotification, displayChat, sendChat, updateRead } from '../../context/chatsFirebase';
import MessageLine from './messageline';
import { useParams } from 'react-router-dom';
import { onSnapshot } from 'firebase/firestore';

import background from "../../assets/images/chatbg.png";

const Chatbox = () => {
    const { username } = useParams();
    const userByname = useSelector((state) => selectUserByUsername(state, username));
    const cuserId = userByname?.userId;
    const dispatch = useDispatch()

    // const cuserId = useSelector((state) => currentProfileId(state));
    const currentUserId = useSelector((state) => currentuserId(state));
    const combinedId = currentUserId > cuserId ? currentUserId + cuserId : cuserId + currentUserId;
    const user = useSelector((state) => selectUserById(state, cuserId));
    const [showPicker, setShowPicker] = useState(false);

    const chats = useSelector((state) => selectChatByCombinedID(state, combinedId));
    // console.log(combinedId);
    // console.log(chats);
    const onEmojiClick = (event, emojiObject) => {
        setText(prevInput => prevInput + emojiObject.emoji);
        setShowPicker(false);
    };


    const [messages, setMessages] = useState([]);


    useEffect(() => {
        if (cuserId) {
            dispatch(setcurrentchat({ userId: cuserId }))
        }

    }, [cuserId]);

    useEffect(() => {
        async function showChats() {
            onSnapshot(displayChat(combinedId), (snapshot) => {
                const dtm = snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })).sort((a, b) => a.date - b.date);
                setMessages(dtm);
                if (dtm.length > 0) { updateRead(dtm, currentUserId); }


            });

        }
        if (combinedId) { showChats(); }
    }, [combinedId]);
    const [text, setText] = useState("");
    const [img, setImg] = useState(null);

    const handleSend = async () => {
        await sendChat(combinedId, currentUserId, cuserId, text, img);
        await addMessageNotification(cuserId, currentUserId);
        setText("");
        setImg(null);
    };
    const handleKeypress = e => {
        console.log('enter');
        if (e.keyCode === 13 || e.key === "Enter") {
            handleSend();
        }
    };

    if (cuserId && currentUserId && username) {
        return (

            <div className="flex flex-col h-full m-3 relative " >
                <header className="rounded-lg flex justify-between item-center gap-2  bg-white w-full h-10 xl:h-24">
                    <img
                        src={user?.image ? user?.image : "/images/default.png"}
                        alt="" className="rounded-full h-10 xl:h-16" />
                    <div className='flex-1 justify-self-center '>
                        <div className='block'>  &nbsp;</div>
                        <h2 className='text-lg xl:text-xl'>{user?.username}</h2>
                    </div>
                </header>
                <div className="  flex-1 flex flex-col m-2 mb-20 xl:mb-32 overflow-y-auto  whitespace-nowrap scrollhide"
                    style={{ backgroundImage: `url(${background})` }}
                >

                    {messages?.length > 0 ? messages.map((m) => (
                        <MessageLine message={m} key={m.id} />

                    )) : (<div> Loading</div>)}
                </div>
                {showPicker && <Picker
                    pickerStyle={{ width: '100%' }}
                    onEmojiClick={onEmojiClick} />}
                <footer className="px-2 gap-1 flex justify-between items-center h-10 xl:h-20 w-full absolute bottom-5">
                    <div className='flex flex-nowrap justify-between items-center gap-2 w-full'>
                        <EmojiHappyIcon className="h-12 w-12 xl:h-12 xl:w-12 text-gray-400"
                            onClick={() => setShowPicker(val => !val)}
                        />
                        <input type="text" placeholder="message" 
                        className="flex-1 rounded-xl  xl:text-xl   h-12   bg-gray-200  p-1 border-gray-400 border-2 placeholder:text-black "
                            onChange={(e) => setText(e.target.value)}
                            onKeyPress={(e)=>handleKeypress(e)}
                            value={text}
                        />


                        <button className=' text-sm border-solid border-white xl:h-16 '
                            onClick={handleSend}
                    
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" fill={"#fff"} viewBox="0 0 12 12" strokeWidth={1.5} stroke={"#fff"} className="h-10 w-10 bg-red-500 p-1 rounded-full border-white">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5" />
                            </svg>

                        </button>
                    </div>
                </footer>

            </div>


        )
    }
    return (
        <div className='flex justify-center items-center mx-auto'>chat Box</div>
    )
}
export default Chatbox