import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { store } from './store/store'
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en.json";
import { fetchUsers } from './store/usersSlice';
import { fetchPosts } from './store/postSlice';
import { AuthContextProvider } from './context/AuthContext';
import { RecoilRoot } from 'recoil';
import { fetchArticles } from './store/articleSlice';
import { fetchMechs } from './store/mechsSlice';
TimeAgo.addDefaultLocale(en);

store.dispatch(fetchUsers());
store.dispatch(fetchPosts());
store.dispatch(fetchArticles());
store.dispatch(fetchMechs());

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
      <Provider store={store}>
  <AuthContextProvider>
    <RecoilRoot>
        <BrowserRouter>
          <Routes>
            <Route path="/*" element={<App />} />
          </Routes>
        </BrowserRouter>
    </RecoilRoot>
  </AuthContextProvider>
      </Provider>
);
