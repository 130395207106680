import { createContext, useContext, useEffect, useState } from 'react';
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
  signOut,
  onAuthStateChanged,
  updateProfile,
} from 'firebase/auth';
import { auth } from '../firebase';
import { useDispatch } from "react-redux";

import { checkEmailAddressExistData, createUserTable } from './PostFirbase';
import { setCredentials } from '../store/authSlice'


const UserContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState({});
  const [userDt, setUserDt] = useState({});
  
  const createUser = async (emailAddress, password, username) => {
    const createdUserResult = await createUserWithEmailAndPassword(
      auth,
      emailAddress,
      password
    );
    await updateProfile(createdUserResult.user, {
      displayName: username,
    });


    return createdUserResult;
  };

  const signIn = (email, password) => {
    const createdUserData = signInWithEmailAndPassword(auth, email, password);
    return createdUserData;
  }
  const googleSignIn = () => {
    const provider = new GoogleAuthProvider();
    const createdUserData = signInWithPopup(auth, provider);
    
    console.log(createdUserData);
  };

  const logout = () => {
    setUser({});
    return signOut(auth);

  }
  const dispatch = useDispatch();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      
      const checkData = async () => {
        const checkEmailExist = await checkEmailAddressExistData(currentUser.email);
        if (checkEmailExist.length < 1) {
          await createUserTable(currentUser.uid, currentUser.email, currentUser.username, currentUser.displayName, currentUser.photoURL)
          const userDtd = await checkEmailAddressExistData(currentUser.email);
          dispatch(setCredentials({...user, ...userDtd }))
          setUserDt(userDtd);     
        }else{
          setUserDt(checkEmailExist[0]);          
          const userData = checkEmailExist[0];
          dispatch(setCredentials({ ...userData }))

        }
      }
      if (currentUser?.email) {
         checkData();
      }
    });
    return () => {
      unsubscribe();
    };
  }, []);
  return (
    <UserContext.Provider value={{ createUser, user, userDt, logout, signIn, googleSignIn }}>
      {children}
    </UserContext.Provider>
  );
};

export const UserAuth = () => {
  return useContext(UserContext);
};
