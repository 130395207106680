import { async } from '@firebase/util';
import { nanoid } from '@reduxjs/toolkit';
import { addDoc, arrayUnion, collection, doc, getDoc, getDocs, query, setDoc, Timestamp, updateDoc, where } from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytesResumable, uploadString } from 'firebase/storage';
import { useSelector } from 'react-redux';
import { db, storage } from '../firebase';
import { currentuserId } from '../store/authSlice';


export function getMessages(combinedId) {
  return doc(db, "messages", combinedId);
}

export async function chatscheck(tonotifyId) {

  try {
    const docRef = doc(db, "chats", tonotifyId);
    const docSnap = await getDoc(docRef);

    if (!docSnap.exists()) {
      setDoc(docRef, { messages: [], notification: {} });
    }

  } catch (err) { }
}

// for(let i=0; i< chats.length; i++){
//   console.log(chats[i]);

//           let contact = currentUser.uid === chats[i].senderId ?  chats[i].receiverId : chats[i].senderId ;
//           if(!lContactUnread[contact]){
//             lContactUnread[contact]=0;
//           }
//           if(chats[i].read === 0){
//             lContactUnread[contact]=+1;
//             unreadm =+1
//           }

//         }
// export async function getChatContact(id) {

//   const equipment = collection(db, 'chats', id, 'messages');
//   const snapshot = await getDocs(equipment);
//   return snapshot.docs.map(doc => doc.data());

//   //    return doc(db, "/chats/", id, "/messages/");
//   // const chatContactsRef = collection(db, "chats", id, "messages");
//   // // return query(commentsRef, orderBy("timestamp", "desc"));
//   // return query(chatContactsRef);

//   // try {
//   //   const docRef = doc(db, "chats", tonotifyId, 'messages');
//   //   const docSnap = await getDoc(docRef);

//   //   if (docSnap.exists()) {
//   //     console.log("Document data:", docSnap.data());
//   //     const oldc = parseInt(docSnap.data().count) + 1
//   //     setDoc(docRef, { count: oldc })
//   //       .then(docRef => {
//   //         console.log("Entire Document has been updated successfully old");
//   //       })
//   //       .catch(error => {
//   //         console.log('error in set', error);
//   //       })
//   //   }
//   // //    else {
//   // //     console.log("no update counter");
//   // //     setDoc(docRef, { count: 1 })
//   // //       .then(docRef => {
//   // //         console.log("Entire Document has been updated successfully new");
//   // //       })
//   // //       .catch(error => {
//   // //         console.log('error in set', error);
//   // //       })
//   // //   }



//   // } catch (err) { }
// }
export async function getAllChat(id) {

  const q = query(collection(db, "/messages"), where("involves", "array-contains", id));

  const querySnapshot = await getDocs(q);

  return querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));

}


export async function getAllArticles() {
  const getResult = await getDocs(collection(db, "articles"));
  const userResult = getResult.docs
    .map((user) => ({
      ...user.data(),
      id: user.id,
    }));
  return userResult;
}

export async function sendChat(combinedId, currentUserId, receiverId, text, img) {
  try {
    if (img) {
      const imageRef = ref(storage, `/messages/${nanoid()}`);
      await uploadString(imageRef, img, "data_url").then(
        async (snapshot) => {
          const downloadUrl = await getDownloadURL(imageRef);
          console.log(downloadUrl);
          const postRef = await addDoc(collection(db, "messages"), {
            //  fileType: contentType,
            //   fileKind: contentKind,
            reaction: [],
            involves: [currentUserId, receiverId],
            imageSrc: downloadUrl,
            read: 0,
            text,
            senderId: currentUserId,
            receiverId,
            combinedId,
            date: Timestamp.now(),
          });
        }
      );
    } else {
      const postRef = await addDoc(collection(db, "messages"), {
        reaction: [],
        involves: [currentUserId, receiverId],
        read: 0,
        text,
        senderId: currentUserId,
        receiverId,
        combinedId,
        date: Timestamp.now(),
      });
    }
  } catch (err) { }
}

export function displayChat(id) {
  const commentsRef = collection(db, "messages");
  return query(commentsRef, where("combinedId", "==", id));
}
export async function updateRead(idarr, currentUserId) {
  // console.log(idarr);
  for (let i = 0; i < idarr.length; i++) {
    if (idarr[i].read === 0 && currentUserId === idarr[i].receiverId) {
      const refmessage = doc(db, "messages", idarr[i].id);
      await updateDoc(refmessage, {
        "read": 1
      });
    }
  }
}

export async function getAllFavorite(id) {

  const q = query(collection(db, "/favorite"), where("userId", "==", id));

  const querySnapshot = await getDocs(q);

  return querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));

}



export async function addMessageNotification(tonotifyId, currentUserId) {

  try {

    const docRef = doc(db, "messagenotificationcounter", tonotifyId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      // console.log("Document data:", docSnap.data());
      const oldc = parseInt(docSnap.data()[currentUserId]) + 1
      setDoc(docRef, { [currentUserId]: oldc })
        .then(docRef => {
          // console.log("Entire Document has been updated successfully old");
        })
        .catch(error => {
          // console.log('error in set', error);
        })
    } else {
      console.log("no update counter");
      setDoc(docRef, { [currentUserId]: 1 })
        .then(docRef => {
          // console.log("Entire Document has been updated successfully new");
        })
        .catch(error => {
          // console.log('error in set', error);
        })
    }



  } catch (err) { }
}