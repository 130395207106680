import { arrayRemove, arrayUnion, collection, doc, getDocs, updateDoc } from "firebase/firestore";


import {  db } from '../firebase';

export async function getAllUsersProfiles() {
    const getResult = await getDocs(collection(db, "users"));
    const userResult = getResult.docs
      .map((user) => ({
        ...user.data(),
        id: user.id,
      }));
    // .filter(
    //   (profile) =>
    //     profile.userId !== userId && !following?.includes(profile.userId)
    // );
    return userResult;
  }

  // update the active users following
export async function updateLoggedInUserFollowing(
  loggedInUserDocId,
  profileId,
  isFollowingProfile
) {
  const usersRef = doc(db, "users", loggedInUserDocId);
  await updateDoc(usersRef, {
    following: isFollowingProfile
      ? arrayRemove(profileId)
      : arrayUnion(profileId),
  });
}

// update the followers list of the user whom the active user follows
export async function updateFollowedUserFollowers(
  profileDocId,
  loggedInUserDocId,
  isFollowingProfile
) {
  const usersRef = doc(db, "users", profileDocId);
  await updateDoc(usersRef, {
    followers: isFollowingProfile
      ? arrayRemove(loggedInUserDocId)
      : arrayUnion(loggedInUserDocId),
  });
}


// handle the Follow unfollow button functionality
export async function toggleFollowFB(
  isFollowingProfile,
  activeUserDocId,
  profileDocId,
  profileUserUserId,
  activeUserUserId
) {
  await updateLoggedInUserFollowing(
    activeUserDocId,
    profileUserUserId,
    isFollowingProfile
  );
  await updateFollowedUserFollowers(
    profileDocId,
    activeUserUserId,
    isFollowingProfile
  );
  return ({
    isFollowingProfile,
    activeUserDocId,
    profileDocId,
    profileUserUserId,
    activeUserUserId}
  )
}