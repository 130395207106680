
import React, { lazy, Suspense } from "react";

import { Route, Routes } from 'react-router-dom';
import { AuthContextProvider } from './context/AuthContext';

import * as ROUTES from "./constants/routes";
import Signin from "./pages/Signin";
import Signup from "./pages/Signup";
import Dashboard from "./pages/dashboard";
import ForgetPassword from "./pages/forgetpassword";
import Layout from "./components/Layout";
import DashLayout from "./components/DashLayout";
import PostPage from "./pages/post";
import ChatPage from "./pages/chatpage";
import { auth } from "./firebase";
import { onAuthStateChanged } from "firebase/auth";
import { setCredentials } from "./store/authSlice";
import { useDispatch } from "react-redux";
import { checkEmailAddressExistData, createUserTable } from "./context/PostFirbase";
import { getAllChat, getAllFavorite } from "./context/chatsFirebase";
import { setChats, setListContactUnread, setListofUniqueContact, setUnread } from "./store/chatSlice";
import { setFavorites } from "./store/favoriteSlice";
import { setAllUSers } from "./store/allusersSlice";
import { getAllUsersProfiles } from "./store/userFirebase";
import GetLocation from "./location";

// import 'bootstrap/dist/css/bootstrap.min.css';


// const Signin = lazy(() => import('./pages/Signin'));
// const Signup = lazy(() => import('./pages/Signup'));
const NotFound = lazy(() => import("./pages/not-found"));
// const Dashboard = lazy(() => import("./pages/dashboard"));
// const Dashboard = lazy(() => import("./pages/home/index"));
const Profile = lazy(() => import("./pages/profile"));

function App() {

  const dispatch = useDispatch();
  GetLocation();
  onAuthStateChanged(auth, (currentUser) => {

    const checkData = async () => {
      const checkEmailExist = await checkEmailAddressExistData(currentUser.email);
      if (checkEmailExist.length < 1) {
        await createUserTable(currentUser.uid, currentUser.email, currentUser.username, currentUser.displayName, currentUser.photoURL)
        const userDtd = await checkEmailAddressExistData(currentUser.email);
        dispatch(setCredentials({ ...userDtd }))
        const chats = await getAllChat(currentUser.uid)
        dispatch(setChats({ ...chats }))
      } else {
        const userData = checkEmailExist[0];
        dispatch(setCredentials({ ...userData }))

        const chats = await getAllChat(currentUser.uid)
        const favorite = await getAllFavorite(currentUser.uid)
        const allusers = await getAllUsersProfiles();
        dispatch(setChats(chats))
        dispatch(setFavorites(favorite))
        dispatch(setAllUSers(allusers))


        let lContactUnread = {};
        let listallconve = []
        let unreadm = 0;
        for (let i = 0; i < chats.length; i++) {
          const contact = currentUser.uid === chats[i].senderId ? chats[i].receiverId : chats[i].senderId;

          listallconve.push(contact)
          if (!lContactUnread[contact]) {
            lContactUnread[contact] = 0;
          }
          if (chats[i].read === 0) {
            lContactUnread[contact] += 1;
            if (currentUser.uid === chats[i].receiverId)
              unreadm += 1;
          }

        }
        const set = new Set(listallconve);


        const newArr = [...set];
        dispatch(setListofUniqueContact(newArr))
        dispatch(setListContactUnread(lContactUnread))
        dispatch(setUnread(unreadm))

        // console.log(unreadm);




      }
    }
    if (currentUser?.email) {
      checkData();
    }

    else {
      console.log('user not found');
    }
  });

  return (
    <div className="w-full mx-auto bg-[#f3f3f3] relative  z-20">
      <Suspense
        fallback={
          <div className="flex justify-center items-center h-screen flex-col bg-[#f3f3f3]">
            <img
              className=" w-36 h-36"
              src={require('./assets/images/logos.png')}
              alt=""
            />
          </div>
        }
      >
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route element={<DashLayout />} >
              <Route index element={<Dashboard />} />
              <Route path={ROUTES.PROFILE} element={<Profile />} />
              <Route path={ROUTES.POST} element={<PostPage />} />
              <Route path={ROUTES.MESSAGE} element={<ChatPage />} />
              <Route path={ROUTES.MESSAGES} element={<ChatPage />} />

            </Route>
            <Route path="signin" element={<Signin />} />
            <Route path="forgetpassword" element={<ForgetPassword />} />
            <Route path={ROUTES.SIGNUP} element={<Signup />} />


            {/* <Route path="*" element={<NotFound />} /> */}
          </Route>
        </Routes>
      </Suspense>
    </div>
  );
}

export default App;
