// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import {  getStorage } from "firebase/storage";
import {  getFirestore } from "firebase/firestore";
import {  ref, serverTimestamp, set } from "firebase/database";


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// const firebaseConfig = {
//     apiKey: "AIzaSyBLcxC4c5pFvhDtSc-GsnW6zhH91TUq57E",
//     authDomain: "moonapp-f96b7.firebaseapp.com",
//     projectId: "moonapp-f96b7",
//     storageBucket: "moonapp-f96b7.appspot.com",
//     messagingSenderId: "962620806055",
//     appId: "1:962620806055:web:392537999ee5faf9589c82"
//   };
const firebaseConfig = {
  // apiKey: "AIzaSyDxYr7yzvv5ukyFkpaR_N4sCrKjzyy4-sk",

  // authDomain: "moonshot-79f80.firebaseapp.com",

  // projectId: "moonshot-79f80",

  // storageBucket: "moonshot-79f80.appspot.com",

  // messagingSenderId: "968753865992",

  // appId: "1:968753865992:web:59a113b3f3c0eafe21b8bd"

   apiKey: process.env.REACT_APP_APIKEY,

  authDomain: process.env.REACT_APP_AUTHDOMAIN,

  projectId: process.env.REACT_APP_PROJECTID,

  storageBucket: process.env.REACT_APP_STORAGEBUCKET,

  messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,

  appId: process.env.REACT_APP_APPID

};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const setupdate = async (currentUserId, cuserId,  text) => await set(ref(db, `userChats/${currentUserId}/${cuserId}`), {
  date: serverTimestamp(),
  text: text,
  profileId: cuserId
});
export default app



