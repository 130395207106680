import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cuserId: '',
  chats:[],
  listofUniqueContact:[],
  listContactUnread:{},
  unread:0,
};

const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    setcurrentchat: (state, action) => {
      state.cuserId = action.payload.userId;
    },
    setChats: (state, action) =>{ 
      const dt = action.payload;
      state.chats = dt
    },
    setListContactUnread: (state, action) =>{ 
      const dt = action.payload;
      state.listContactUnread =dt;
    },
    setListofUniqueContact: (state, action) =>{ 
      const dt = action.payload;
      state.listofUniqueContact = dt;
    },
    setUnread: (state, action) =>{ 
      const dt = action.payload;
      state.unread = dt;
    },
  },
});

export const selectAllChats = (state) => state.chat.chats;
export const selectAllContact = (state) => state.chat.listContactUnread;
export const selectAllUnread = (state) => state.chat.unread;
export const selectAllUnqiueContact = (state) => state.chat.listofUniqueContact;

export const { setcurrentchat, setChats, setListContactUnread, setListofUniqueContact, setUnread } = chatSlice.actions;
export const currentProfileId = (state) => state.chat.cuserId;
export const selectChatByCombinedID = (state, id) =>
    state.chat.chats.filter(chat => chat.combinedId === id);
export const selectContacts = (state) =>{
 
}
    // state.chat.chats.filter(chat => chat.combinedId === id);

export default chatSlice.reducer;
