import { Transition } from '@headlessui/react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closemessagebox, currentProfileId, messagebox } from '../../store/messageSlice';
import { currentuserId } from '../../store/authSlice';
import Message from './messages/Message';
import { nanoid } from '@reduxjs/toolkit';
import { arrayUnion, doc, getDoc, onSnapshot, serverTimestamp, setDoc, Timestamp, updateDoc } from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { db, storage, setupdate } from '../../firebase';
import { selectUserById } from '../../store/usersSlice';
import Picker from 'emoji-picker-react';
import { EmojiHappyIcon } from '@heroicons/react/outline';
import { updateUserChatsReceiver, updateUserChatsSender } from '../../context/profileFirebase';


const Messagebox = () => {
    const open = useSelector((state) => messagebox(state))
    const cuserId = useSelector((state) => currentProfileId(state));
    const currentUserId = useSelector((state) => currentuserId(state));
    const combinedId = currentUserId > cuserId ? currentUserId + cuserId : cuserId + currentUserId;
    const user = useSelector((state) => selectUserById(state, cuserId));
    const [showPicker, setShowPicker] = useState(false);

    const onEmojiClick = (event, emojiObject) => {
        setText(prevInput => prevInput + emojiObject.emoji);
        setShowPicker(false);
    };

    const [messages, setMessages] = useState([]);
    useEffect(() => {
        // const msgs = await getDoc(doc(db, "messages", combinedId));

        const unSub = async () => onSnapshot(doc(db, "messages", combinedId), (doc) => {
            doc.exists() && setMessages(doc.data().messages);

        });
        if (cuserId && currentUserId) {
            unSub();
        }

    }, [cuserId, currentUserId]);


    const [text, setText] = useState("");
    const [img, setImg] = useState(null);

    const handleSend = async () => {

        try {
            const res = await getDoc(doc(db, "messages", combinedId));

            if (!res.exists()) {
                //create a chat in messages collection
                await setDoc(doc(db, "messages", combinedId), { messages: [] });
            }
            //create user chats
            if (img) {
                const storageRef = ref(storage, nanoid());

                const uploadTask = uploadBytesResumable(storageRef, img);

                uploadTask.on(
                    (error) => {
                        //TODO:Handle Error
                    },
                    () => {
                        getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
                            await updateDoc(doc(db, "messages", combinedId), {
                                messages: arrayUnion({
                                    id: nanoid(),
                                    text,
                                    senderId: currentUserId,
                                    date: Timestamp.now(),
                                    img: downloadURL,
                                }),
                            });
                        });
                    }
                );
            } else {
                await updateDoc(doc(db, "messages", combinedId), {
                    messages: arrayUnion({
                        id: nanoid(),
                        text,
                        senderId: currentUserId,
                        date: Timestamp.now(),
                    }),
                });
            }
           
            // const docRef = doc(db, "userChats", cuserId);
            // const docSnap = await getDoc(docRef);
        
            // if (docSnap.exists()) {
            //   console.log("Document data:", docSnap.data());
            //   const oldc = parseInt(docSnap.data().currentUserId.readno) + 1
            //   setDoc(docRef,{[currentUserId]:{
            //     text ,
            //     readno: oldc,
            //     profileId: currentUserId,
            //     date: serverTimestamp(),
            //   }})
            //     .then(docRef => {
            //       console.log(" receiver successfully old");
            //     })
            //     .catch(error => {
            //       console.log('error in receiver', error);
            //     })
            // } else {
            //   console.log("no receiver yet ");
            //   setDoc(docRef, {[currentUserId]:{
            //     text ,
            //     readno: 1,
            //     profileId: currentUserId,
            //     date: serverTimestamp(),
            //   }})
            //     .then(docRef => {
            //       console.log("userChats receiver successfully new");
            //     })
            //     .catch(error => {
            //       console.log('error in receiver userChats', error);
            //     })
            // }
        
            // const docRef2 = doc(db, "userChats", currentUserId, cuserId);
            // console.log("not send yet ");
            // setDoc(docRef2, {[cuserId]:{
            //   text ,
            //   readno: 0,
            //   profileId: cuserId,
            //   date: serverTimestamp(),
            // }})
            //   .then(docRef2 => {
            //     console.log("userChats successfully new");
            //   })
            //   .catch(error => {
            //     console.log('error in sender userChats', error);
            //   })
            // await updateDoc(doc(db, "userChats",currentUserId), {
            //     [cuserId]: {
            //       text,
            //       date: serverTimestamp(),
            //     }
            //   });
   
            setText("");
            setImg(null);

        } catch (err) { }

    };

    const dispatch = useDispatch()


    return (
        <div>
            <Transition
                show={open}
                enter="transition-opacity duration-75"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity duration-150"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                className="fixed top-20 bottom-0 right-0 z-20 w-full max-w-xs bg-white dark:bg-darker dark:text-light sm:max-w-md focus:outline-none"

            >
                <div

                    className="absolute left-0 p-2 transform -translate-x-full ">
                    {/* <!-- Close button --> */}
                    <button onClick={() => dispatch(closemessagebox())} className="p-2 text-red-500  rounded-md focus:outline-none focus:ring">
                        <svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="red" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                        </svg>
                    </button>
                </div>
                <div className="flex flex-col h-screen m-3 " >
                    <header className="">
                        <div className='rounded-lg flex   mx-auto gap-3 '>
                            <img
                                src={user?.image ? user?.image : "/images/default.png"}
                                alt="" className="rounded-full h-12" />
                            <div className='flex-1  '>
                                <h2 className='text-lg p-4'>{user?.username}</h2>
                            </div>
                        </div>
                    </header>
                    <div className=" flex flex-col m-2 overflow-y-auto h-64">
                        
                            {messages?.length > 0 ? messages.map((m) => (
                                <Message message={m} key={m.id} />
                            )) : ''}
                    </div>
                    {showPicker && <Picker
                        pickerStyle={{ width: '100%' }}
                        onEmojiClick={onEmojiClick} />}
                    <footer className="px-2 mb-8 gap-1 flex justify-between items-center">
                        <textarea type="text" placeholder="message" className="flex-1 h-14 border-black placeholder:text-black "
                            onChange={(e) => setText(e.target.value)}
                            value={text}
                        >
                        </textarea>
                        <EmojiHappyIcon className="h-7 w-7 text-gray-700"
                            onClick={() => setShowPicker(val => !val)}
                        />
                        <button className='text-sm border-solid border-black'
                            onClick={handleSend}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5" />
                            </svg>

                        </button>
                    </footer>

                </div>
            </Transition>
        </div>
    )
}

export default Messagebox