import { createSlice, nanoid, createAsyncThunk } from "@reduxjs/toolkit";
import { getAllArticles } from "./articuleFirebase";
// status: 'idle', //'idle' | 'loading' | 'succeeded' | 'failed'

const initialState = {
    articles: [],
    status: 'idle',
    error: null
}

export const fetchArticles = createAsyncThunk('articles/fetchArticles', async () => {
    const response = await getAllArticles();
    return response;
})

const articlesSlice = createSlice({
    name: 'articles',
    initialState,
    reducers: {
    },
    extraReducers(builder) {
        builder
            .addCase(fetchArticles.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(fetchArticles.fulfilled, (state, action) => {
                state.status = 'succeeded'
                // Adding date and reactions
                // console.log(loadedArticles);
                const loadedArticles = action.payload.map(post => post);

                // Add any fetched articles to the array
                state.articles = state.articles.concat(loadedArticles)
                // console.log(state.articles )

            })
            .addCase(fetchArticles.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })

    }
})

export const selectAllArticles = (state) => state.articles.articles;
export const getArticlesStatus = (state) => state.articles.status;
export const getArticlesError = (state) => state.articles.error;

export const selectArticlesById = (state, postId) =>
    state.articles.articles.find(post => post.id === postId);

export const selectArticlesByIds = (state, data) => {
    var arrdt = []
    for (let i = 0; i < data.length; i++) {
        arrdt.push(state.articles.articles.find(post => post.id === data[i].fid))
    }
    return arrdt;
}
export const selectRealArticleById = (state, postId) => {
    const realpost = selectArticlesById(state, postId);
    return realpost?.sharedId ? selectArticlesById(state, realpost.sharedId) : realpost;
};



export const selectPostByUsername = (state, userId) =>
    state.articles.articles.filter(post => post.userId === userId);
export const selectArticlesUserID = (state, userId) =>
    state.articles.articles.filter(post => post.userId === userId);

export const { postAdded, reactionAdded } = articlesSlice.actions

export default articlesSlice.reducer