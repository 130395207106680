
import React, { useEffect, useRef, useState } from "react";
import Header from "./Header";
import Image from "./Image";
import Buttons from "./Buttons";
import Captions from "./Captions";
import Comments from "./Comments";
import { postModalState } from "../../atoms/modalAtom";
import { useRecoilState, useRecoilValue } from "recoil";
import { useDispatch, useSelector } from "react-redux";
import { currentuserId, loggedIn } from "../../store/authSlice";
import { selectUserById } from "../../store/usersSlice";
import { HeartIcon as HeartIconFilled } from "@heroicons/react/solid";
import { HeartIcon, BookmarkIcon } from '@heroicons/react/solid'

import { updateArticleLikes, updateArticleSaves } from "../../context/PostFirbase";
import { openAuth, openShareArticuleModel } from "../../store/modelSlice";
import { terminate } from "firebase/firestore";
import { selectArticlesById } from "../../store/articleSlice";

function Article({ content }) {
  // console.log(content);
  const userId = useSelector((state) => currentuserId(state));
  const currentUserId = useSelector((state) => currentuserId(state));
  // console.log(currentUserId);
  const postUser = useSelector((state) => selectUserById(state, content.userId));
  const [showComment, setshowComment] = useState(false);

  const isLogged = useSelector((state) => loggedIn(state));

  const [commentLength, setCommentLength] = useState(0)
  const handleFocus = () => commentInput.current.focus();
  const commentInput = useRef(null);
  const [open, setOpen] = useRecoilState(postModalState);
  const dispatch = useDispatch()


  const [toggledLiked, setToggledLiked] = useState(content.likes?.some(a => a === userId));

  const [likes, setLikes] = useState(content.likes?.length);

  const handleCommentShow = () => {
    setshowComment(!showComment);
  }

  const sharedPost = useSelector((state) => selectArticlesById(state, content?.sharedId));
 


  const [toggledSaved, setToggledSaved] = useState(content.saves?.some(a => a === userId));
  const [saves, setSaves] = useState(content.saves?.length);
  const [shares, setShares] = useState(content.shares?.length)

  // update the like count of the photo
  const handleToggleLiked = async () => {
    if (!isLogged) {
      dispatch(openAuth());
    } else {
      setToggledLiked((toggledLiked) => !toggledLiked);
      await updateArticleLikes(content?.id, userId, toggledLiked);
      setLikes((likes) => (toggledLiked ? likes - 1 : likes + 1));
    }
  };
  const handleShare = () => {
    if (!isLogged) {
      dispatch(openAuth());
    }
    else { dispatch(openShareArticuleModel({ postId: content?.id })); }
  };

  const handleToggleSaved = async () => {
    if (!isLogged) {
      dispatch(openAuth());
    } else {
      setToggledSaved((toggledSaved) => !toggledSaved);
      await updateArticleSaves(content?.id, userId, toggledSaved);
      setSaves((saves) => (toggledSaved ? (toggledSaved ? saves - 1 : saves + 1) : 1));
    }
  };


  return (
    <div className="w-full   bg-white my-5 border rounded-lg divide-y shadow-md">
      <Header userId={content.userId} postID={content.id} />

      
      {/* <Image src={content.imageSrc} caption={content?.caption}
        fileType={content?.fileType} fileKind={content?.fileKind}
        content={content}
      /> */}

  {content?.sharedId ? 
    //  (<div><PostShare className='px-2' content={sharedPost} /> </div>)
     (<div>
      <Header userId={sharedPost.userId} postID={sharedPost.id} />
     <p className="p-4 text-xl">{sharedPost.text}</p>
      
      </div>)
     :
     (<p className="p-4 text-xl">{content?.text}</p>)
  }
      <div>

        <div>
          <div className="flex flex-col my-2 mx-6 ">
            <div className="flex justify-between">
              <div className="text-sm  flex justify-start items-center">
                <button
                  onClick={handleToggleLiked}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      handleToggleLiked();
                    }
                  }}
                >
                  {toggledLiked ? (
                    <HeartIconFilled className="postButton h-8 w-8 text-red-500" />
                  ) : (
                    <HeartIcon className="postButton h-8 w-8" />
                  )}

                </button>
                {likes > 1 ? `${likes} likes` : `${likes} like`}

              </div>
              <div className="flex justify-center items-center  text-xs cursor-pointer">
                <div onClick={() => handleCommentShow()}>{commentLength > 1 ? `${commentLength} comments` : `${commentLength} comment`}</div>
              </div>
              <div className="flex justify-center gap-1 items-center  ml-3 cursor-pointer" onClick={handleShare} >
                <span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><circle cx="18" cy="5" r="3"></circle><circle cx="6" cy="12" r="3"></circle><circle cx="18" cy="19" r="3"></circle><line x1="8.59" y1="13.51" x2="15.42" y2="17.49"></line><line x1="15.41" y1="6.51" x2="8.59" y2="10.49"></line></svg>
                </span>
                <span > {shares} share </span>
              </div>


              <div className="text-sm  flex justify-start items-center">
                <button onClick={handleToggleSaved} >
                  {toggledSaved ? (
                    <BookmarkIcon className="postButton h-8 w-8 text-red-500" />
                  ) : (
                    <BookmarkIcon className="postButton" />
                  )}

                </button>
                {saves ? (saves > 1 ? `${saves} saves` : `${saves} save`) : ' 0 save'}

              </div>

            </div>
          </div>


        </div>

        <Captions caption={content?.caption} username={postUser?.username} />
      </div>
      <Comments
        id={content.id}
        postuserId={content.userId}
        postedAt={content.timestamp}
        commentInput={commentInput}
        setCommentsLen={setCommentLength}
        showComment={showComment}
      />

    </div>
  );
}

export default Article;

