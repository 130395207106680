import { createSlice, nanoid, createAsyncThunk } from "@reduxjs/toolkit";
import { getAllMechs } from "./mechFirebase";
// status: 'idle', //'idle' | 'loading' | 'succeeded' | 'failed'

const initialState = {
    mechs: [],
    status: 'idle',
    error: null
}

export const fetchMechs = createAsyncThunk('mechs/fetchMechs', async () => {
    const response = await getAllMechs();
    return response;
})

const mechsSlice = createSlice({
    name: 'mechs',
    initialState,
    reducers: {
    },
    extraReducers(builder) {
        builder
            .addCase(fetchMechs.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(fetchMechs.fulfilled, (state, action) => {
                state.status = 'succeeded'
                // Adding date and reactions
                // console.log(loadedMechs);
                const loadedMechs = action.payload.map(post => post);
                 
                // Add any fetched mechs to the array
                state.mechs = state.mechs.concat(loadedMechs)
                // console.log(state.mechs )

            })
            .addCase(fetchMechs.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
          
    }
})

export const selectAllMechs = (state) => state.mechs.mechs;
export const getMechsStatus = (state) => state.mechs.status;
export const getMechsError = (state) => state.mechs.error;


export const selectMechUserID = (state, userId) =>
state.mechs.mechs.filter(post => post.userId === userId);

export const selectMechsByIds = (state, data) => {
    var arrdt = []
    for (let i = 0; i < data.length; i++) {
        arrdt.push(state.mechs.mechs.find(post => post.id === data[i].fid))
    }
    return arrdt;
}

export const selectPostByUsername = (state, userId) =>
    state.mechs.mechs.filter(post => post.userId === userId);

export const { postAdded, reactionAdded } = mechsSlice.actions

export default mechsSlice.reducer