import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import ReactTimeAgo from "react-time-ago";
import { currentuserId } from "../../store/authSlice";


const MessageLine = ({ message }) => {
  // console.log(message);
  const ref = useRef();
  const currentUserId = useSelector((state) => currentuserId(state));

  useEffect(() => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  }, [message]);

  if (message?.senderId === currentUserId) {
    return (
      <div ref={ref} className={`flex flex-col justify-center items-end`} >
        <div
          className={`flex flex-col mr-4 w-[60%] p-2 bg-red-500 rounded-md rounded-br-none `}>
          {message?.img && <img src={message?.img} alt="" />}
          <p className={`px-4 py-1 text-white text-xs xl:text-xl`}>{message.text}</p>
        </div>
        <div className="messageInfo">
          <span className={` `}>

            <ReactTimeAgo
              date={message?.date.toDate()}
              locale="en-US"
              timeStyle="round"
              className=" text-[9px] xl:text-sm capitalize text-gray-800"
            />
          </span>
        </div>
      </div>
    );
  }
  return   (
    <div ref={ref} className={`flex flex-col justify-center items-start `} >
      <div
        className={`flex flex-col ml-4 bg-white  p-2 shadow-md rounded-md 2xl:p-5 opacity-100 `}>
        {message?.img && <img src={message?.img} alt="" />}
        <p className={`px-4 py-1 text-gray-800 text-xs  xl:text-xl`}>{message.text}</p>
      </div>
      <div className="messageInfo">
        <span className={` `}>

          <ReactTimeAgo
            date={message?.date.toDate()}
            locale="en-US"
            timeStyle="round"
            className=" text-[9px]  xl:text-sm capitalize text-gray-800 float-right"
          />
        </span>
      </div>
    </div>
  );
};

export default MessageLine;
