import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { addMerchandise } from '../../../context/PostFirbase';
import { currentuserId } from '../../../store/authSlice';
import { closePostModel } from '../../../store/modelSlice';

const PostMach = () => {
  const dispatch = useDispatch();

  const userId = useSelector((state) => currentuserId(state));
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const filePickerRef = useRef(null);
  const [caption, setCaption] = useState('')
  const [price, setPrice] = useState('')
  const [productName, setProductName] = useState('');
  const [contentType, setContentType] = useState(null);
  const [contentTypeD, setContentTypeD] = useState(null);
 

  const uploadMech = async () => {
    if (loading) return;
    setLoading(true);
    if (selectedFile) {
      await addMerchandise(userId, caption, price, productName, selectedFile);
    }
   
    dispatch(closePostModel())
    setLoading(false);
    setSelectedFile(null);
    document.location.reload();
  };
  const addImageToPost = (event) => {
    const reader = new FileReader();
    if (event.target.files[0]) {
      reader.readAsDataURL(event.target.files[0]);
    }
    reader.onload = (readerEvent) => {
      setSelectedFile(readerEvent.target.result);
      setContentType(readerEvent.target.result.split(";")[0].split(":")[1]);
      setContentTypeD(readerEvent.target.result.split(";")[0].split(":")[1].split("/")[0]);
      
    };
    
  };
  const [cnt, setCnt] = useState('')
  useEffect(() => {
    // let cnt = '';
  
     if (contentTypeD === 'image') {
  
      setCnt(<img
        src={selectedFile}
        alt="loading..."
        className="w-full cursor-pointer object-contain max-h-36 max-w-xs"
        onClick={() => setSelectedFile(null)}
      />)
    } else if (contentTypeD === 'video') {
      setCnt(<video controls autoPlay loop muted onClick={() => setSelectedFile(null)} >
        <source src={selectedFile} type={contentType}></source>
      </video>)
    } else if (contentTypeD === 'audio') {
      setCnt(<audio controls  loop muted onClick={() => setSelectedFile(null)} >
        <source src={selectedFile} type={contentType}></source>
      </audio>)
    }

    else {
      setSelectedFile(null)
      setCnt('');
    }
  
  }, [contentTypeD])
  return (
    <div className='flex flex-col gap-2 justify-between p-3 mb-6'>
    <div className='flex mx-auto justify-center items-center rounded-lg border-2 border-dashed  border-red-500 w-full'>
    <input
          type="file"
          accept="*/*"
          hidden
          onChange={(e)=> addImageToPost(e)}
          ref={filePickerRef}
        />
      <div className='w-full h-24 flex justify-center items-center'>
      {selectedFile ?
          cnt
          : (
        <span 
          onClick={() => filePickerRef.current.click()}
        className='bg-red-500 text-sm text-white rounded-lg w-24 h-10 text-center justify-center p-2'>Browse</span>
        )}

      </div>
    </div>
    <div className='flex gap-2 mt-4' >
      <div className='text-sm'>
        <label htmlFor="colabo">Product Name</label>
        <input className='w-full border-2 border-red-500 rounded-md py-1 px-2' type="text" name="colabo" id="colabo"
        onChange={(e) => setProductName(e.target.value)}
        maxLength={100}
        />
      </div>
      <div className='text-sm'>
        <label htmlFor="colabo">Price (N)</label>
        <input className='w-full border-2 border-red-500 rounded-md py-1 px-2' type="text" name="colabo" id="colabo"
        onChange={(e) => setPrice(e.target.value)}
        maxLength={20}

        />
      </div>
    </div>
    <div className='text-sm'>
      <label htmlFor="caption">Caption</label>
      <textarea
        className='w-full border-2 border-red-500 rounded-md py-1 px-2'
        onChange={(e) => setCaption(e.target.value)}
maxLength={100}
        name="caption" id="caption" cols="30" rows="2" placeholder='write your message'></textarea>
        
    </div>

    <div className='rounded-md h-12 text-sm w-full text-white bg-red-500 flex justify-center items-center'
    onClick={uploadMech}
    >
      Post
    </div>
  </div>
  )
}

export default PostMach