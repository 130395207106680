import { BookmarkIcon } from '@heroicons/react/outline'
import { HeartIcon } from '@heroicons/react/solid'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";

import { updateLikes, updateMechLikes, updateMechSaves, updateSaves } from '../../context/PostFirbase';
import { currentuserId, loggedIn } from '../../store/authSlice';
import { openAuth } from '../../store/modelSlice';
import { HeartIcon as HeartIconFilled } from "@heroicons/react/solid";
import { useNavigate } from 'react-router-dom';
import MechComments from './MechComments';
import { selectUserById } from '../../store/usersSlice';

const Mech = ({ content }) => {
    const userId = useSelector((state) => currentuserId(state));
    const isLogged = useSelector((state) => loggedIn(state));
    const dispatch = useDispatch();

    const [toggledLiked, setToggledLiked] = useState(content.likes?.some(a => a === userId));
    const [likes, setLikes] = useState(content.likes?.length);

    const [toggledSaved, setToggledSaved] = useState(content.saves?.some(a => a === userId));
    const [saves, setSaves] = useState(content.saves?.length);
    const [showComment, setshowComment] = useState(false);
    const [commentLength, setCommentLength] = useState(0)
    const commentInput = useRef(null);
    const userpost = useSelector((state) => selectUserById(state, content?.userId));
   

    const handleCommentShow = () => {
        setshowComment(!showComment);
    }
    // useEffect(() => {
    //     setLikes(content.shares?.length)
    //     setSaves(content.shares?.length)
    //     setToggledLiked(content?.likes.some(a => a === currentuserId))
    //     // setToggledSaved(content?.saves.some(a => a === currentuserId))

    // }, [content])
    const navigate = useNavigate();

    const handleToggleLiked = async () => {
        if (!isLogged) {
            dispatch(openAuth());
            // navigate(DASHBOARD);
        } else {
            setToggledLiked((toggledLiked) => !toggledLiked);
            await updateMechLikes(content?.id, userId, toggledLiked);
            setLikes((likes) => (toggledLiked ? likes - 1 : likes + 1));
        }
    };

    const handleToggleSaved = async () => {
        if (!isLogged) {
            dispatch(openAuth());
        } else {
            setToggledSaved((toggledSaved) => !toggledSaved);
            await updateMechSaves(content?.id, userId, toggledSaved);
            setSaves((saves) => (toggledSaved ? (toggledSaved ? saves - 1 : saves + 1) : 1));
        }
    };
    return (
        <div className='  flex rounded-lg w-full  lg:h-64 bg-white my-5 divide-y shadow-md '>
            <div className='w-48 h-48 p-2'>
                <img
                    className='rounded-xl'
                    src={content?.img} alt="img" loading='lazy' />
            </div>
            <div className='flex-1 flex flex-col p-2 items-stretch justify-between relative '>
                <div className='flex justify-between text-3xl'>
                    <div className='flex flex-col'>
                        <div className=''>{content?.productName} </div>

                        <Link to={`/profile/${userpost?.username ?? 'username'}`}
                            className="text-sm"      >
                            <p>{userpost?.username ?? 'username'}</p>
                        </Link>

                    </div>
                    <div className=''>{content?.price}</div>
                </div>
                <div className='text-xl w-96 overflow-hidden'>{content?.caption}</div>
                {/* <div className='absolute bottom-5'> */}
                <div className='flex flex-row justify-between '>
                    <div className='flex w-full gap-4'>
                        <div className="text-lg  flex justify-start items-center">
                            <button
                                onClick={handleToggleLiked}
                                onKeyDown={(event) => {
                                    if (event.key === "Enter") {
                                        handleToggleLiked();
                                    }
                                }}
                            >
                                {toggledLiked ? (
                                    <HeartIconFilled className="postButton h-8 w-8 text-red-500" />
                                ) : (
                                    <HeartIcon className="postButton h-8 w-8" />
                                )}

                            </button>
                            {likes > 1 ? `${likes} likes` : `${likes} like`}

                        </div>


                        <div className="text-sm  flex justify-start items-center">
                            <button onClick={handleToggleSaved} >
                                {toggledSaved ? (
                                    <BookmarkIcon className="postButton  text-red-500" />
                                ) : (
                                    <BookmarkIcon className="postButton h-8 w-8" />
                                )}

                            </button>
                            {saves ? (saves > 1 ? `${saves} saves` : `${saves} save`) : ' 0 save'}

                        </div>
                        <div className="flex justify-center items-center  text-sm cursor-pointer">
                            <div onClick={() => handleCommentShow()}>{commentLength > 1 ? `${commentLength} comments` : `${commentLength} comment`}</div>
                        </div>


                    </div>
                    <div className='text-red-500 text-sm text-right'>
                        details
                    </div>
                    {/* </div> */}

                </div>
                <MechComments
                    id={content.id}
                    postuserId={content.userId}
                    postedAt={content.timestamp}
                    commentInput={commentInput}
                    setCommentsLen={setCommentLength}
                    showComment={showComment}
                />

            </div>
        </div>
    )
}

export default Mech