import { createSlice } from "@reduxjs/toolkit"

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        "userId": "",
        "username": "",
        "image": "",
        "followers": [],
        "fullName": "",
        "emailAddress": "",
        "bio": "",
        "following": [],
        "dateCreated": "",
        "id": "",
        "loggedin": false,
    },
    reducers: {
        setCredentials: (state, action) => {
            const { userId, username, image, followers, fullName, emailAddress, bio, following, dateCreated, id } = action.payload;

            state.userId = userId;
            state.username = username;
            state.image = image;
            state.followers = followers;
            state.fullName = fullName;
            state.emailAddress = emailAddress;
            state.bio = bio;
            state.following = following;
            state.dateCreated = dateCreated;
            state.id = id;
            if (userId.length > 1) {
                state.loggedin = true;
            }

        },
        updateUsername: (state, action) => {
            const { username } = action.payload;

            state.username = username;
        },
        updateFollowing: (state, action) => {
            const id = action.payload.userId

            const isTrue = state.following?.some(d => d === id)
            if (isTrue) {
                state.following = state.following?.filter(a => a !== id)
            } else {
                if(state.following){
                    state.following.push(id)
                }else{
                    state.following = [id]
                }
            }


        },
        logOut: (state, action) => {
            state.loggedin = false;
            state.userId = null;
            state.username = null;
            state.image = null;
            state.followers = null;
            state.fullName = null;
            state.emailAddress = null;
            state.bio = null;
            state.following = null;
            state.dateCreated = null;
            state.id = null;
        }
    },
})

export const { setCredentials, updateUsername, logOut, updateFollowing } = authSlice.actions

export default authSlice.reducer

export const loggedIn = (state) => state.auth.loggedin;
export const currentuserId = (state) => state.auth.userId;
export const currentusername = (state) => state.auth.username;
export const currentImage = (state) => state.auth.image;
export const currentfollowers = (state) => state.auth.followers;
export const currentFullName = (state) => state.auth.fullName;
export const currentemailAddress = (state) => state.auth.emailAddress;
export const currentBio = (state) => state.auth.bio;
export const currentfollowing = (state) => state.auth.following;
export const currentdateCreated = (state) => state.auth.dateCreated;
export const currentid = (state) => state.auth.id;

export const isAFollowing = (state, currentUserId) => {
    return state.auth.following?.some(a => a === currentUserId);
}