import { collection, getDocs } from "firebase/firestore";
// import { UserAuth } from "../context/AuthContext";


import {  db } from '../firebase';

export async function getAllPost() {
    const getResult = await getDocs(collection(db, "photos"));
    const userResult = getResult.docs
      .map((post) => ({
        ...post.data(),
        id: post.id,
      }))
      .sort((a, b) => b.timestamp - a.timestamp);
    // .filter(
    //   (profile) =>
    //     profile.userId !== userId && !following?.includes(profile.userId)
    // );
    return userResult;
  }
// export async function getAllPhotos(userId) {
//   // const {  userDt } = UserAuth();
//   const getResultUsers = await getDocs(collection(db, "users"));
//   const usersde = getResultUsers.docs.map((users) => ({
//     ...users.data(),
//     id: users.id,
//   }));
//   const getResult = await getDocs(collection(db, "photos"));
//   const photoWithUserDetails = await Promise.all(
//     getResult.docs.map((photo) => {
//       if (photo != null) {
//         for (let i = 0; i < usersde.length; i++) {
//           if (usersde[i]?.userId === photo.data().userId) {
//             let userLikedPhoto = false;
//             photo.likes?.map((res) => {
//               if (res === userId) {
//                 userLikedPhoto = true;
//               }
//             });
//             return ({
//               ...photo.data(),
//               username: usersde[i].username,
//               image: usersde[i].image,
//               id: photo.id,
//               userLikedPhoto
//             });
//           }
//         }
//       }
//     }

//     ));
//   return photoWithUserDetails;
//   // }
//   // console.log('userFollowedPhotos', userFollowedPhotos);

//   // const photoWithUserDetails = await Promise.all(
//   //   userFollowedPhotos.map(async (photo) => {
//   //     // checks if the logged in user already likes that post or not
//   //     let userLikedPhoto = false;
//   //     photo.likes?.map((res) => {
//   //       if (res === userId) {
//   //         userLikedPhoto = true;
//   //       }
//   //     });
//   //     return { ...photo, userLikedPhoto };
//   //   })
//   // );
//   // return photoWithUserDetails;
// }