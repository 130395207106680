import { configureStore } from "@reduxjs/toolkit";

import usersReducer from "./usersSlice"
import postsReducer from "./postSlice"
import articlesReducer from "./articleSlice"
import mechsReducer from "./mechsSlice"
import modelReducer from "./modelSlice"
import messageReducer from "./messageSlice"
import authReducer from "./authSlice"
import chatReducer from "./chatSlice"
import favoriteReducer from "./favoriteSlice"
import allusersReducer from "./allusersSlice"


export const store = configureStore({
    reducer: {
        posts: postsReducer,
        articles: articlesReducer,
        mechs: mechsReducer,
        users: usersReducer,
        model: modelReducer,
        message: messageReducer,
        auth: authReducer,
        chat: chatReducer,
        favorite: favoriteReducer,
        allusers: allusersReducer,

    },

    middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
    //   serializableCheck: {
    //     // Ignore these action types
    //     ignoredActions: ['posts/fetchPosts/fulfilled'],
    //     // Ignore these field paths in all actions
    //     // ignoredActionPaths: ['meta.arg', 'payload.timestamp'],
    //     // // Ignore these paths in the state
    //     // ignoredPaths: ['post'],
    //   },
      serializableCheck: false
    }),
})