import { createSlice, nanoid, createAsyncThunk } from "@reduxjs/toolkit";
import { getAllPost } from "./postFirebase";
// status: 'idle', //'idle' | 'loading' | 'succeeded' | 'failed'

const initialState = {
    posts: [],
    status: 'idle',
    error: null
}

export const fetchPosts = createAsyncThunk('posts/fetchPosts', async () => {
    const response = await getAllPost();
    return response;
})

export const addNewPost = createAsyncThunk('posts/addNewPost', async (initialPost) => {
    // const response = await axios.post(POSTS_URL, initialPost)
    // return response.data
    return [];
})

export const updatePost = createAsyncThunk('posts/updatePost', async (initialPost) => {
    const { id } = initialPost;
    try {
        // const response = await axios.put(`${POSTS_URL}/${id}`, initialPost)
        // return response.data
        return [];
    } catch (err) {
        return err.message;
       
    }
})

export const deletePost = createAsyncThunk('posts/deletePost', async (initialPost) => {
    const { id } = initialPost;
    // try {
    //     const response = await axios.delete(`${POSTS_URL}/${id}`)
    //     if (response?.status === 200) return initialPost;
    //     return `${response?.status}: ${response?.statusText}`;
    // } catch (err) {
    //     return err.message;
    // }
})

const postsSlice = createSlice({
    name: 'posts',
    initialState,
    reducers: {
        postAdded: {
            reducer(state, action) {
                state.posts.push(action.payload)
            },
            prepare(title, content, userId) {
                return {
                    payload: {
                        id: nanoid(),
                        title,
                        content,
                        date: new Date().toISOString(),
                        userId,
                        reactions: {
                            thumbsUp: 0,
                            wow: 0,
                            heart: 0,
                            rocket: 0,
                            coffee: 0
                        }
                    }
                }
            }
        },
        reactionAdded(state, action) {
            const { postId, reaction } = action.payload
            const existingPost = state.posts.find(post => post.id === postId)
            if (existingPost) {
                existingPost.reactions[reaction]++
            }
        }
    },
    extraReducers(builder) {
        builder
            .addCase(fetchPosts.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(fetchPosts.fulfilled, (state, action) => {
                state.status = 'succeeded'
                // Adding date and reactions
                // console.log(loadedPosts);
                const loadedPosts = action.payload.map(post => post);
                 
                // Add any fetched posts to the array
                state.posts = state.posts.concat(loadedPosts)
                // console.log(state.posts )

            })
            .addCase(fetchPosts.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
            .addCase(addNewPost.fulfilled, (state, action) => {
                action.payload.id = state.posts[state.posts.length - 1].id + 1;
                action.payload.userId = Number(action.payload.userId)
                action.payload.date = new Date().toISOString();
                action.payload.reactions = {
                    thumbsUp: 0,
                    wow: 0,
                    heart: 0,
                    rocket: 0,
                    coffee: 0
                }
                console.log(action.payload)
                state.posts.push(action.payload)
            })
            .addCase(updatePost.fulfilled, (state, action) => {
                if (!action.payload?.id) {
                    console.log('Update could not complete')
                    console.log(action.payload)
                    return;
                }
                const { id } = action.payload;
                action.payload.date = new Date().toISOString();
                const posts = state.posts.filter(post => post.id !== id);
                state.posts = [...posts, action.payload];
            })
            .addCase(deletePost.fulfilled, (state, action) => {
                if (!action.payload?.id) {
                    console.log('Delete could not complete')
                    console.log(action.payload)
                    return;
                }
                const { id } = action.payload;
                const posts = state.posts.filter(post => post.id !== id);
                state.posts = posts;
            })
    }
})

export const selectAllPosts = (state) => state.posts.posts;
export const getPostsStatus = (state) => state.posts.status;
export const getPostsError = (state) => state.posts.error;

export const selectPostByIds = (state, data) =>{
var arrdt =[]
for (let i = 0; i < data.length; i++) {
    arrdt.push(state.posts.posts.find(post => post.id === data[i].fid ))
    
}
   return arrdt;
}
    export const selectPostById = (state, postId) =>
    state.posts.posts.find(post => post.id === postId);
export const selectPostByVideo = (state) =>
    state.posts.posts.filter(post => post.fileKind === 'video');
export const selectPostByVideoImage = (state) =>
    state.posts.posts.filter(post => post.fileKind === 'video' || post.fileKind === 'image');
export const selectPostByAudio = (state) =>
    state.posts.posts.filter(post => post.fileKind === 'audio');
export const selectPostByImage = (state) =>
    state.posts.posts.filter(post => post.fileKind === 'image');

export const selectRealPostById = (state, postId) =>{
    const realpost = selectPostById(state, postId);
   return realpost?.sharedId ?  selectPostById(state, realpost.sharedId) : realpost;
};

export const selectPostByUsername = (state, userId) =>
    state.posts.posts.filter(post => post.userId === userId);

    export const selectPostByVideoImageUserID = (state, userId) =>
    state.posts.posts.filter(post => (post.userId === userId) && (post.fileKind === 'video' || post.fileKind === 'image'));
    export const selectPostByAudioUserID = (state, userId) =>
    state.posts.posts.filter(post => post.userId === userId && post.fileKind === 'audio');

//     export const selectPostByVideoImageUserIDFavorite= (state, userId) =>
// {    for (let i = 0; i < chats.length; i++) {
//         const contact = currentUser.uid === chats[i].senderId ? chats[i].receiverId : chats[i].senderId;

//         listallconve.push(contact)
//         if (!lContactUnread[contact]) {
//           lContactUnread[contact] = 0;
//         }
//         if (chats[i].read === 0) {
//           lContactUnread[contact] += 1;
//           if (currentUser.uid === chats[i].receiverId)
//             unreadm += 1;
//         }

//       }}
 

export const { postAdded, reactionAdded } = postsSlice.actions

export default postsSlice.reducer