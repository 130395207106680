import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import Header from "./Header";
import Image from "./Image";
function ArticleShare({ content }) {
if(content)
  return (

    <div className="container bg-white my-5 border rounded-md divide-y shadow-md">
      <Header userId={content?.userId} postID={content.id}  />
      <p className="p-4 text-xl">{content?.text}</p>
 
      
    </div>
  );
}

export default ArticleShare;

ArticleShare.propTypes = {
  content: PropTypes.shape({
    id: PropTypes.string.isRequired,
    // userLikedPhoto: PropTypes.bool.isRequired,
   
  }),
};
