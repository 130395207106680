import { nanoid } from '@reduxjs/toolkit';
import { addDoc, collection, doc, getDoc, orderBy, query, serverTimestamp, setDoc, updateDoc } from 'firebase/firestore';
import { getDownloadURL, ref, uploadString } from 'firebase/storage';
import { useSelector } from 'react-redux';
import { db, storage } from '../firebase';
import { currentuserId } from '../store/authSlice';

// export async function updateAllProfiles(
//     id,
//     userId,
//     username,
//     fullName,
//     bio,
//     edituserName,
//     editfullName,
//     editBio,
//     selectedFile
//   ) {
//     const imageRef = ref(storage, `users/${userId}/image`);
//     const userRef = doc(db, "users", id);
//     selectedFile
//       ? await uploadString(imageRef, selectedFile, "data_url").then(
//         async (snapshot) => {
//           const downloadUrl = await getDownloadURL(imageRef);
//           await updateDoc(userRef, {
//             username: edituserName ? edituserName : username,
//             fullName: editfullName ? editfullName : fullName,
//             image: downloadUrl,
//             bio: editBio ? editBio : bio,
//           });
//         }
//       )
//       : await updateDoc(userRef, {
//         username: edituserName ? edituserName : username,
//         fullName: editfullName ? editfullName : fullName,
//       });
//   }
export async function updateBio(id, bio) {
  const userRef = doc(db, "users", id);
  await updateDoc(userRef, { bio: bio });
}
export async function updatePic(id, userId, selectedFile) {
  const imageRef = ref(storage, `users/${userId}/image`);
  const userRef = doc(db, "users", id);
  if (selectedFile) {
    await uploadString(imageRef, selectedFile, "data_url");

    const downloadUrl = await getDownloadURL(imageRef);
    console.log(downloadUrl);
    await updateDoc(userRef, {
      image: downloadUrl
    });


  }

}
export async function updateUsername(id, username) {
  const userRef = doc(db, "users", id);
  await updateDoc(userRef, { username: username });
}
export async function updateFullname(id, fullName) {
  const userRef = doc(db, "users", id);
  await updateDoc(userRef, { fullName: fullName });
}
export async function addNotification(tonotifyId, currentUserId, message, linked) {

  try {
    const NotificationRef = collection(db, "notifications", tonotifyId, "notifications");
    await addDoc(NotificationRef, {
      id: nanoid(),
      userId: currentUserId,
      message: message,
      read: false,
      link: linked,
      timestamp: serverTimestamp(),
    });
    const docRef = doc(db, "notificationcounter", tonotifyId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      console.log("Document data:", docSnap.data());
      const oldc = parseInt(docSnap.data().count) + 1
      setDoc(docRef, { count: oldc })
        .then(docRef => {
          console.log("Entire Document has been updated successfully old");
        })
        .catch(error => {
          console.log('error in set', error);
        })
    } else {
      console.log("no update counter");
      setDoc(docRef, { count: 1 })
        .then(docRef => {
          console.log("Entire Document has been updated successfully new");
        })
        .catch(error => {
          console.log('error in set', error);
        })
    }



  } catch (err) { }
}
export const updateUserChatsReceiver = async(sender, receiver, message) => {

  try {
    const docRef = doc(db, "userChats", receiver, );
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      console.log("Document data:", docSnap.data());
      const oldc = parseInt(docSnap.data().readno) + 1
      setDoc(docRef, {
        text: message,
        readno: oldc,
        profileId: sender,
        date: serverTimestamp(),
      })
        .then(docRef => {
          console.log(" receiver successfully old");
        })
        .catch(error => {
          console.log('error in receiver', error);
        })
    } else {
      console.log("no receiver yet ");
      setDoc(docRef, {
        text: message,
        readno: 1,
        profileId: sender,
        date: serverTimestamp(),
      })
        .then(docRef => {
          console.log("userChats receiver successfully new");
        })
        .catch(error => {
          console.log('error in receiver userChats', error);
        })
    }

    const docRef2 = doc(db, "userChats", sender, receiver);
    console.log("not send yet ");
    setDoc(docRef2, {
      text: message,
      readno: 0,
      profileId: receiver,
      date: serverTimestamp(),
    })
      .then(docRef2 => {
        console.log("userChats successfully new");
      })
      .catch(error => {
        console.log('error in sender userChats', error);
      })

  } catch (err) { }
}
export async function updateUserChatsSender(sender, receiver, message) {

  try {
    const docRef2 = doc(db, "userChats", sender, receiver);
    console.log("not send yet ");
    setDoc(docRef2, {
      text: message,
      readno: 0,
      profileId: receiver,
      date: serverTimestamp(),
    })
      .then(docRef2 => {
        console.log("userChats successfully new");
      })
      .catch(error => {
        console.log('error in sender userChats', error);
      })




  } catch (err) { }
}
export async function resetNotification(tonotifyId) {

  try {
    const docRef = doc(db, "notificationcounter", tonotifyId);
    console.log("reset notification ");
    setDoc(docRef, { count: 0 })
      .then(docRef => {
        console.log("reseted");
      })
      .catch(error => {
        console.log('error in reset', error);
      })

  } catch (err) { }
}

export function displayMessages(id) {
  const commentsRef = collection(db, "messages", id, "messages");
  return query(commentsRef, orderBy("date", "desc"));
}
export function getMessages(combinedId) {
  return doc(db, "messages", combinedId);
}
export const displayNoty = async (userId) => {
  const docRef = doc(db, "notificationcounter", userId);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    const re = docSnap.data().count
    return re;
  }
  return 0;

}