import { Tab, Transition } from '@headlessui/react';
import { useDispatch, useSelector } from 'react-redux';
import { closenotificationbox } from '../../store/modelSlice';
import NotificationContainer from './notificationpanel/Notificationcontainer';

const Notifiationbox = () => {
    const open = useSelector((state) => state.model.notificationbox);
    const dispatch = useDispatch();

    return (
        <div>
            <Transition
                show={open}
                enter="transition-opacity duration-75"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity duration-150"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                className="fixed top-16 right-0 z-40 md:w-80 overflow-y-auto  bg-white sm:max-w-md max-w-lg focus:outline-none"
            // className="fixed inset-y-0 z-20 w-full max-w-xs overflow-y-auto bg-red-500 dark:bg-darker dark:text-light sm:max-w-md focus:outline-none"
            >
                <div className="absolute left-0 p-2 transform translate-x-full">
                    {/* <!-- Close button --> */}
                    {/* <button onClick={() => dispatch(closenotificationbox())} className="p-2 text-red-500  rounded-md focus:outline-none focus:ring">
                        <svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="red" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                        </svg>
                    </button> */}
                </div>

                <NotificationContainer />


            </Transition>
        </div>
    )
}

export default Notifiationbox