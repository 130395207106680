import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cuserId: '',
  favorites:[],
  openfavorite: false
};

const favoriteSlice = createSlice({
  name: "favorite",
  initialState,
  reducers: {
    setcurrentfavorite: (state, action) => {
      state.cuserId = action.payload.userId;
    },
    setFavorites: (state, action) =>{ 
      const dt = action.payload;
      state.favorites= dt

    },
    toggleFavorite: (state) =>{      
      state.openfavorite = !state.openfavorite;
    },
    openFavorite: (state) =>{      
      state.openfavorite = true;
    },
    closeFavorite: (state) =>{      
      state.openfavorite = false;
    },
  },
});

export const selectAllFavorites = (state) => state.favorite.favorites;
export const statusFavorites = (state) => state.favorite.openfavorite;

export const { setcurrentfavorite, setFavorites, toggleFavorite, openFavorite, closeFavorite } = favoriteSlice.actions;



// export const selectFavoriteByVideoImage = (state) =>
// {
//   console.log(selectAllFavorites);
//   // console.log(state.favorite.favorites);
//     // state.favorite.favorite.filter(f => f.fileKind === 'video' || f.fileKind === 'image');
// }
// export const selectFavoriteByAudio = (state) =>
//     state.favorites.favorites.filter(favorite => favorite.fileKind === 'audio');
// export const selectFavoriteByArticles = (state) =>
//     state.favorites.favorites.filter(favorite => favorite.fileKind === 'articles');
// export const selectFavoriteByMerchandise = (state) =>
//     state.favorites.favorites.filter(favorite => favorite.fileKind === 'merchandise');


    // state.favorite.favorites.filter(favorite => favorite.combinedId === id);

export default favoriteSlice.reducer;
