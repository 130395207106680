import React, { useState } from "react";

import {
  PlusCircleIcon,
  HomeIcon,
  LoginIcon,
} from "@heroicons/react/outline";
import * as ROUTES from "../constants/routes";
import { Link, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { postModalState } from "../atoms/modalAtom";
import { currentusername } from "../store/authSlice";
import { useSelector } from "react-redux";

function Footer() {
  const [open, setOpen] = useRecoilState(postModalState);

  const [active, setActive] = useState(false);
  const username= useSelector((state) => currentusername(state));


  const navigate = useNavigate();
  const handleLogIn = () =>{
    
    navigate('/signin');
  }



  return (
    // <div className="fixed bottom-0 z-50 w-screen border-b bg-white shadow-sm">
    //   <div className="mx-3 flex max-w-7xl items-center justify-evenly lg:-mx-3 xl:mx-auto">
    <div className="">
      <div className=" fixed bottom-0 max-w-6xl w-full">
        <div className=" flex  items-center justify-between px-5   xl:mx-auto">
          {/* Left Side */}
          <Link to={ROUTES.DASHBOARD}>
            <HomeIcon className="navButton" />
          </Link>
          {/* Middle Part */}
          <div
            className={`-mr-1  max-w-[140px] sm:max-w-xs`}
          >
            <div className="relative mt-1 mb-1 rounded-md p-3">


              <PlusCircleIcon
                onClick={() => setOpen(true)}
                className="navButton"
              />
            </div>
          </div>
          {/* Right Side */}
          <div
            className={`flex items-center justify-end space-x-1 lg:space-x-3 ${active && "hidden"
              }`}
          >

            {username ? (
              <>



                <div className="items-center">
                  <Link to={`/profile/${username}`}>
                    <img
                      src="/images/default.png"
                      alt=""
                      className="h-10 w-10 rounded-full border-2 border-gray-200 object-cover p-[1px] mt-1"
                    />
                  </Link>
                </div>
              </>
            ) : (
              <Link
                to={ROUTES.SIGNIN}
                className="mt-[6px] cursor-pointer  flex items-center space-x-2 rounded-md p-2 text-lg font-semibold transition ease-in hover:bg-gray-300 hover:bg-opacity-50 sm:mt-[1px]"
                onClick={handleLogIn}
              >
                <LoginIcon className="navButton  " />
                <span>Log In</span>
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
