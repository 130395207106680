import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allusers:[]
};

const allusersSlice = createSlice({
  name: "allusers",
  initialState,
  reducers: {   
    setAllUSers: (state, action) =>{ 
      const dt = action.payload;
      state.allusers= dt
    },   
  },
});

export const selectAllUsers = (state) => state.allUsers.allUsers;
export const allUsers = (state) => state.allUsers.allUsers.map(dt => ({id: dt.userId, text: dt.username}));


export const { setAllUSers, } = allusersSlice.actions;



export default allusersSlice.reducer;
