import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Picker from 'emoji-picker-react';

import { addPostVideo } from "../../../context/PostFirbase";
import { currentuserId } from "../../../store/authSlice";
import { closePostModel } from "../../../store/modelSlice";
import { EmojiHappyIcon } from "@heroicons/react/outline";
import Tag from "../../tag/tag";
const PostVideo = () => {
  const userId = useSelector((state) => currentuserId(state));
  const [loading, setLoading] = useState(false);
  const filePickerRef = useRef(null);
  const captionRef = useRef(null);
  const [contentType, setContentType] = useState(null);
  const [contentTypeD, setContentTypeD] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [setAmount, setSetAmount] = useState(0);
  const [colabo, setColabo] = useState('');
  const [tag, setTag] = useState('');
  const [location, setLocation] = useState('');
  const dispatch = useDispatch();
  const [inputStr, setInputStr] = useState('');
  const [showPicker, setShowPicker] = useState(false);
  const [getTag, setGetTag] = useState([false]);



  const onEmojiClick = (event, emojiObject) => {
    setInputStr(prevInput => prevInput + emojiObject.emoji);
    setShowPicker(false);
  };

  const uploadPost = async () => {
    if (loading) return;
    setLoading(true);
    const caption = captionRef.current.value;
    if (selectedFile) {
      await addPostVideo(userId, caption, selectedFile, contentType, contentTypeD, setAmount, colabo, getTag, location);
    }

    dispatch(closePostModel())
    setLoading(false);
    setSelectedFile(null);
    document.location.reload();
  };
  const addImageToPost = (event) => {
    const reader = new FileReader();
    if (event.target.files[0]) {
      reader.readAsDataURL(event.target.files[0]);
    }
    reader.onload = (readerEvent) => {
      setSelectedFile(readerEvent.target.result);
      setContentType(readerEvent.target.result.split(";")[0].split(":")[1]);
      setContentTypeD(readerEvent.target.result.split(";")[0].split(":")[1].split("/")[0]);
    };

  };
  // console.log(selectedFile);
  // console.log(contentTypeD);

  const [cnt, setCnt] = useState('')
  useEffect(() => {
    // let cnt = '';

    if (contentTypeD === 'image') {

      setCnt(<img
        src={selectedFile}
        alt="loading..."
        className="w-full cursor-pointer object-contain max-h-36 max-w-xs"
        onClick={() => setSelectedFile(null)}
      />)
    } else if (contentTypeD === 'video') {
      setCnt(<video controls autoPlay loop muted onClick={() => setSelectedFile(null)} >
        <source src={selectedFile} type={contentType}></source>
      </video>)
    } else if (contentTypeD === 'audio') {
      setCnt(<audio controls loop muted onClick={() => setSelectedFile(null)} >
        <source src={selectedFile} type={contentType}></source>
      </audio>)
    }

    else {
      setSelectedFile(null)
      setCnt('');
    }

  }, [contentTypeD])

  return (
    <div className='flex gap-2 justify-between mb-6'>
      <div>
        <input
          type="file"
          accept="*/*"
          hidden
          onChange={(e) => addImageToPost(e)}
          ref={filePickerRef}
        />
      </div>
      <div className='flex mx-auto justify-center items-center rounded-lg border-2 border-dashed border-red-500 w-1/2'>
        {selectedFile ?
          cnt
          : (
            <div
              onClick={() => filePickerRef.current.click()}
              className='bg-red-500 cursor-pointer text-white rounded-lg w-24 h-10 text-center justify-center p-2'>
              Browse
            </div>
          )}

      </div>
      <div className='flex flex-col w-1/2 gap-2'>
        <div className='text-xs'>
          <div className="flex justify-between">

            <label htmlFor="caption">Title</label>
            <EmojiHappyIcon className="h-7 w-7 text-gray-700"
              onClick={() => setShowPicker(val => !val)}
            />

          </div>
          <textarea
            className='w-full border-2 border-red-500 rounded-md py-1 px-2'
            name="caption" value={inputStr}
            id="caption"
            cols="30" rows="2"
            placeholder='write your message'
            maxLength={100}
            ref={captionRef}
            onChange={e => setInputStr(e.target.value)}
          ></textarea>
          {showPicker && <Picker
            pickerStyle={{ width: '100%' }}
            onEmojiClick={onEmojiClick} />}
        </div>
        <div className='text-xs'>
          <label htmlFor="colabo">Add Colaborator</label>
          <input
            className='w-full border-2 border-red-500 rounded-md py-1 px-2' type="text" name="colabo" id="colabo"
            onChange={(e) => setColabo(e.target.value)}
            value={colabo}
            maxLength={100}
          />
        </div>
        <div className='text-xs'>
          <label htmlFor="tag">Tag People</label>
          {/* <input 
          onChange={(e) => setTag( e.target.value)}
          value={tag}
          className='w-full border-2 border-red-500 rounded-md py-1 px-2' type="text" name="tag" id="tag" /> */}
          <Tag setGetTag={setGetTag} />
        </div>
        <div className='text-xs'>
          <label htmlFor="location">Location </label>
          <input
            onChange={(e) => setLocation(e.target.value)}
            value={location}
            className='w-full border-2 border-red-500 rounded-md py-1 px-2' type="text" name="location" id="location" />
        </div>
        <div className={`rounded-md w-full cursor-pointer text-white bg-red-500 flex justify-center items-center `}
          disabled={!selectedFile}
          onClick={uploadPost}
        >
          Post
        </div>
      </div>
    </div>
  )
}

export default PostVideo