import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { EmojiHappyIcon } from "@heroicons/react/outline";
import { addComment, displayComment, getAllComments, useGetAllComments } from "../../context/PostFirbase";
import { onSnapshot } from "firebase/firestore";
import { db } from "../../firebase";
import ReactTimeAgo from "react-time-ago";
import Comment from "./Comment";
import { UserAuth } from "../../context/AuthContext";
import Picker from 'emoji-picker-react';
import { currentuserId, loggedIn } from "../../store/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { addNotification } from "../../context/profileFirebase";
import { openAuth } from "../../store/modelSlice";


function Comments({ id, postedAt, commentInput, postuserId, setCommentsLen, showComment }) {
    
  const userId = useSelector((state) => currentuserId(state));
  const isLogged = useSelector((state) => loggedIn(state));
  const dispatch = useDispatch();


  const [comments, setComments] = useState([]);
  const [comment, setComment] = useState("");
  
  // const [inputStr, setInputStr] = useState('');
  const [showPicker, setShowPicker] = useState(false);

  const onEmojiClick = (event, emojiObject) => {
    setComment(prevInput => prevInput + emojiObject.emoji);
    setShowPicker(false);
  };


  // realtime update the comments section
  useEffect(() => {
    async function showComments() {
      onSnapshot(displayComment(id), (snapshot) => {
        setComments(snapshot.docs);
        setCommentsLen(snapshot.docs.length);
      });

    }
    showComments();
    // console.log(comments);
  }, [db, id]);
  // push the comment into firestore
  const sendComment = async (event) => {

    event.preventDefault();
    if(!isLogged){
      dispatch(openAuth());
    }
    else
  {  const commentToSend = comment;
    setComment("");
    await addComment(id, commentToSend, userId);
    await addNotification(postuserId, userId, "commented on your post", "post/"+id) }
  };
  return showComment && ( 
    <div>
      {/* Display Comments */}
      {comments.length > 0 && (
        <div className=" max-h-[108px] overflow-y-scroll border-b scrollbar-thin scrollbar-thumb-gray-500">
          {comments.map((comment) => (
            <div className="" key={comment.id}>
              <Comment
                photoId={id}
                commentId={comment.id}
                userId={comment.data().userId}
                comment={comment.data().comment}
                postedAt={comment.data().timestamp}
                totalLikes={comment.data().likes?.length}
              />
            </div>
          ))}
        </div>
      )}
      {/* Comment Input */}
      <div>
        <form className="flex items-center border-b border-gray-200 px-4">
          <EmojiHappyIcon className="h-7 w-7 text-gray-700" onClick={() => setShowPicker(val => !val)}
          />
          {showPicker && <Picker
            pickerStyle={{ width: '100%' }}
            onEmojiClick={onEmojiClick} />}
          <input
            type="text"
            placeholder="Add a comment..."
            className="mx-4 flex-1 border-none p-4 outline-none focus:ring-0"
            value={comment}
            aria-label="Add a comment"
            autoComplete="off"
            onChange={({ target }) => setComment(target.value)}
            // onChange={e => setInputStr(e.target.value)}
            ref={commentInput}
          />
          <button
            className="font-semibold text-red-400"
            type="submit"
            disabled={!comment.trim()}
            onClick={sendComment}
          >
            Post
          </button>
        </form>
      </div>
      <div className="p-4">
        <ReactTimeAgo
          date={postedAt.toDate()}
          locale="en-US"
          timeStyle="round"
          className="mt-2 text-xs capitalize text-gray-400"
        />
      </div>
    </div>
  );
}

export default Comments;

Comments.propTypes = {
  id: PropTypes.string.isRequired,
  postedAt: PropTypes.object.isRequired,
  commentInput: PropTypes.object,
};
