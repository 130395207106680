
import { useSelector } from "react-redux";
import { currentuserId } from "../../store/authSlice";
import Contact from "./contact";
import { AiOutlineSetting } from "react-icons/ai";
import { selectAllContact, selectAllUnqiueContact } from "../../store/chatSlice";


const ChatContact = () => {

    const listContact = useSelector(selectAllContact);
    const listofUniqueContacta = useSelector(selectAllUnqiueContact);
    
  

    return (
        <div className="bg-white overflow-hidden w-64 flex flex-col gap-4 lg:min-w-[10rem] xl:min-w-[20rem] ">
            <header className=" text-xl px-2 py-3 flex justify-between items-center border-b-2  border-gray-200">
                {/* <input type="text" placeholder="search" className="w-full h-8 px-5 rounded-lg flex-1   p-1 border-red-400 border-2 placeholder:text-red-500 " /> */}
                <h1 className="">Messages</h1>
                <AiOutlineSetting />
            </header>
            <ul className="flex-1 ml-1 mb-2 ">
                {listofUniqueContacta ? listofUniqueContacta.map((key) => (
                    <Contact key={key} act read={key} />
                )) : ''}
            </ul>
           
        </div>
    )
}

export default ChatContact