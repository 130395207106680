
import { createSlice, nanoid, createAsyncThunk } from "@reduxjs/toolkit";
import { getAllUsersProfiles, toggleFollowFB } from "./userFirebase";

const initialState = {
    users: [],
    status: 'idle', //'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
    currentId: ''
}

export const fetchUsers = createAsyncThunk('users/fetchUsers', async () => {
    const response = await getAllUsersProfiles();
    return response;
})

export const toggleFollow = createAsyncThunk('users/toggleFollow', async (initialPost) => {
    const { isFollowingProfile, activeUserDocId, profileDocId, profileUserUserId, activeUserUserId } = initialPost;
    const response = await toggleFollowFB(isFollowingProfile,
        activeUserDocId,
        profileDocId,
        profileUserUserId,
        activeUserUserId);
    // fetchUsers();
    console.log(response);
    return response;
})



const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        setcurrent: (state, action) => {
            // const {currentId} = action.payload
            state.currentId = action.payload
        },

    },
    extraReducers(builder) {
        builder
            .addCase(fetchUsers.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(fetchUsers.fulfilled, (state, action) => {
                state.status = 'succeeded'
                // Adding date and reactions

                const loadedusers = action.payload.map(user => user);

                // Add any fetched users to the array
                state.users = state.users.concat(loadedusers)
            })
            .addCase(fetchUsers.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
            .addCase(toggleFollow.fulfilled, (state, action) => {
                const {
                    isFollowingProfile,
                    profileUserUserId,
                    activeUserUserId }
                    = action.payload;
                const profileUserIndex = state.users.findIndex(user => user.userId === action.payload.profileUserUserId);
                const currentUserIndex = state.users.findIndex(user => user.userId === action.payload.activeUserUserId);
                //     const profileUser = state.users.find(user => user.userId === action.payload.profileUserUserId);
                // const currentUser = state.users.find(user => user.userId === action.payload.activeUserUserId);
                // console.log("before", state.users[profileUserIndex]);
                if (isFollowingProfile) {
                    state.users[profileUserIndex].followers.filter(a => a !== activeUserUserId);
                    state.users[currentUserIndex].following.filter(b => b !== profileUserUserId);
                } else {
                    if(state.users[profileUserIndex].followers){
                        state.users[profileUserIndex].followers.push(activeUserUserId);
                    }else{
                        state.users[profileUserIndex].followers = [activeUserUserId];

                    }
                    if(state.users[currentUserIndex].following){
                        state.users[currentUserIndex].following.push(profileUserUserId);
                    }else{
                        state.users[currentUserIndex].following = [profileUserUserId]
                    }
                }
                console.log("after", state.users[profileUserIndex]);
            })


    }
})

export const selectAllUsers = (state) => state.users.users;
export const getUsersStatus = (state) => state.users.status;
export const getUsersError = (state) => state.users.error;
export const currentId = (state) => state.users.currentId;

// export const tagUsers = (state) => state.users.users.map((user => {id: user.userId, text : user.}))
// console.log(selectAllUsers);
export const currentUser = (state) =>
    state.users.users.find(user => user.userId === state.users.currentId);
export const selectUserById = (state, userId) =>
    state.users.users.find(user => user.userId === userId);
export const selectUserByUsername = (state, username) =>
    state.users.users.find(user => user.username === username);

export const isUserFollowing = (state, profileuserId, currentUserId) => {
    const profile = state.users.users.find(user => user.userId === profileuserId)
    return profile.followers.some(a => a === currentUserId);
}

export const getSuggestion = (state, userId, following) => state.users.users.filter((profile) =>profile.userId !== userId && !following?.includes(profile.userId));


export const { setcurrent } = usersSlice.actions

export default usersSlice.reducer