import React from 'react'
import { useEffect, useState } from 'react';
// import { Editor } from "react-draft-wysiwyg";
// import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
// import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { addPostArticle } from '../../../context/PostFirbase';
import { useDispatch, useSelector } from 'react-redux';
import { currentuserId } from '../../../store/authSlice';
import { closePostModel } from '../../../store/modelSlice';

const PostArticule = () => {
  const dispatch = useDispatch();

  const userId = useSelector((state) => currentuserId(state));

  // const [editorState, setEditorState] = useState(() => EditorState.createEmpty())
  // useEffect(() => {
  //   const data = convertToRaw(editorState.getCurrentContent());
  //   console.log(data);
  // }, [editorState]);
  // const updateTextDescription = async (state) => {

  //   setEditorState(state);

  //   const data = convertToRaw(editorState.getCurrentContent());

  // };
  const [article, setArticle] = useState('')
  const uploadArticule = async () => {

    if (article.length > 10) {
      await addPostArticle(userId, article, 'text', 'text',);
    }
    console.log(userId, article);

    dispatch(closePostModel())
    document.location.reload();
  };
  return (
    <div className='flex flex-col gap-2 justify-between mb-6'>
      <div className=''>
        <p>write your thoughts</p>
      </div>
      <div className='flex mx-auto'>
        {/* <Editor
        editorState={editorState}
        onEditorStateChange={setEditorState}
      /> */}
        {/* <Editor

          editorState={editorState}

          toolbarClassName="toolbarClassName"

          wrapperClassName="wrapperClassName"

          editorClassName="editorClassName"

          onEditorStateChange={updateTextDescription}

        /> */}
      <textarea value={article} onChange={e=>setArticle(e.target.value)} maxLength={1000} minLength={10} name="article" id="" cols="30" rows="10"></textarea>
      </div>
      <div className='rounded-md w-full cursor-pointer text-white bg-red-500 flex justify-center items-center'
        disabled={article.length < 10}
        onClick={uploadArticule}
      >
        Post
      </div>
    </div>
  )
}

export default PostArticule