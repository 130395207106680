import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { DASHBOARD } from "../../constants/routes";
import { UserAuth } from "../../context/AuthContext";
import { currentuserId, loggedIn } from "../../store/authSlice";
import { closeAuth } from '../../store/modelSlice';
import { AiFillEyeInvisible, AiFillEye } from 'react-icons/ai'


const LoginModal = ({ dohide }) => {
  const dispatch = useDispatch();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { signIn, googleSignIn } = UserAuth();
  const [visblePwd, setVisblePwd] = useState(false)

  // handle toggle 
  const toggle = () => {
    setVisblePwd(!visblePwd)
  }

  const userId = useSelector((state) => currentuserId(state));
  const isLogged = useSelector((state) => loggedIn(state));
  const handleSubmit = async (e) => {

    e.preventDefault();
    try {
      await signIn(email, password)
      // navigate(DASHBOARD)
    } catch (e) {
      if (e.code === 'auth/wrong-password') { setError('Invalid email or password') }
      else if (e.code === 'auth/too-many-requests') { setError('This account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.') }
      else {
        setError('Invalid ')
      }
      // console.log(e.message)
      // console.log(e.code)
      // console.log(e.name)
    }
  };
  const handleGoogleSignIn = async () => {
    try {
      await googleSignIn();
      // navigate(DASHBOARD);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (isLogged) {
      dispatch(closeAuth())
    }
  }, [isLogged]);
  return (


    <div className='max-w-[700px] w-full mx-auto rounded-md p-4 mt-20 bg-slate-100'>
      <div>
        <h1 className='text-center text-2xl font-bold py-2'>Sign in</h1>
      </div>
      <form onSubmit={handleSubmit}>

        <div id="toast-danger" className={` ${error ? '' : 'hidden'} flex items-center p-4 mb-4 w-full text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800`} role="alert">
          <div className="inline-flex flex-shrink-0 justify-center items-center w-8 h-8 text-red-500 bg-red-100 rounded-lg dark:bg-red-800 dark:text-red-200">
            <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
            <span className="sr-only">Error icon</span>
          </div>
          <div className="ml-3 text-sm font-normal">{error}</div>
          <button type="button"
            onClick={() => setError('')}
            className="ml-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700" data-dismiss-target="#toast-danger" aria-label="Close">
            <span className="sr-only">Close</span>
            <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
          </button>
        </div>


        <div className='flex flex-col py-2'>
          <label className='text-sm '>Email Address</label>
          <input onChange={(e) => {setEmail(e.target.value); setError('');}} value={email} className='border p-1 rounded-md' type='email' />
        
        </div>
        <div className='flex flex-col py-2'>
          <label className='text-sm '>Password</label>
          {/* <input onChange={(e) => setPassword(e.target.value)} value={password} className='border p-1 rounded-md' type='password' /> */}
          <div className='relative'>
            <input
              onChange={(e) => setPassword(e.target.value)} value={password}
              className='absolute w-full border p-1 rounded-md'
              type={(visblePwd === false) ? 'password' : 'text'}
            />
            <div className='text-2xl absolute top-2 right-5'>
              {
                (visblePwd === false) ? <AiFillEye onClick={toggle} /> :
                  <AiFillEyeInvisible onClick={toggle} />

              }
            </div>
          </div>
          <Link to='/forgetpassword' className="font-medium float-right text-red-600 hover:underline">
            Forget Password.
          </Link>
        </div>
        <button className='border 
        border-red-500 bg-red-600 hover:bg-red-800 w-full p-1 my-2 text-white rounded-md'>
          Sign In
        </button>
      </form>
      <div className="relative flex items-center justify-center w-full mt-6 border border-t">
        <div className="absolute px-5 text-sm bg-white">Or Continue with </div>
      </div>
      <div className="flex mt-8 gap-x-2">
        <button
          type="button"
          onClick={handleGoogleSignIn}
          className="
          flex items-center justify-center w-full p-2 border border-gray-600 rounded-md focus:ring-2 
          focus:ring-offset-1 focus:ring-violet-600
          hover:bg-red-800"
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="inline w-4 h-4 mr-3 text-gray-900 fill-current" viewBox="0 0 48 48" width="48px" height="48px">
            <path fill="#fbc02d" d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12 s5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24s8.955,20,20,20 s20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"></path><path fill="#e53935" d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039 l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"></path><path fill="#4caf50" d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36 c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"></path><path fill="#1565c0" d="M43.611,20.083L43.595,20L42,20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571 c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z">
            </path></svg>

          Google
        </button>

      </div>

      <p className="flex mt-4 justify-center text-xs font-light text-center text-gray-700">
        {" "}
        Don't have an account?{" "}

        <span
          onClick={() => dohide(false)}
          className="font-medium cursor-pointer text-red-600 hover:underline">
          Sign up.
        </span>
      </p>

    </div>

  )
}

export default LoginModal