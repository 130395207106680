import { createSlice } from "@reduxjs/toolkit";

// const initialState = {
//   notificationbox: false,
//   messagesbox: false,
//   SharePostModel: false,
//   postModelStateRe: true,
//   authModelStatus: false,
//   postID: ''
// };

const modelSlice = createSlice({
  name: "model",
  initialState: {
    notificationbox: false,
    messagesbox: false,
    SharePostModel: false,
    ShareArticuleModel: false,
    postModelStateRe: false,
    profileModelStateRe: false,

    authModelStatus: false,
    postID: ''
  },
  reducers: {
    togglenotificationbox: (state) => {
      state.notificationbox =  !state.notificationbox;
    },
    closenotificationbox: (state) => {
      state.notificationbox =  false;
    },
    opennotificationbox: (state) => {
      state.notificationbox =  true;
    },
    togglemessagesbox: (state) => {
      state.messagesbox =  !state.messagesbox;
    },
    closemessagesbox: (state) => {
      state.messagesbox =  false;
    },
    openmessagesbox: (state) => {
      state.messagesbox =  true;
    },
    toggleSharePostModel: (state) => {
      state.SharePostModel =  !state.SharePostModel;
    },
    closeSharePostModel: (state) => {
      state.SharePostModel =  false;
    },
    openSharePostModel: (state, action) => {
      state.postID =  action.payload.postId;      
      state.SharePostModel =  true;
    },
    toggleShareArticuleModel: (state) => {
      state.ShareArticuleModel =  !state.ShareArticuleModel;
    },
    closeShareArticuleModel: (state) => {
      state.ShareArticuleModel =  false;
    },
    openShareArticuleModel: (state, action) => {
      state.postID =  action.payload.postId;      
      state.ShareArticuleModel =  true;
    },
    togglePostModel: (state) => {
      state.postModelStateRe =  !state.postModelStateRe;
    },
    closePostModel: (state) => {
      state.postModelStateRe =  false;
    },
    openPostModel: (state) => {      
      state.postModelStateRe =  true;
    },
    toggleProfileModel: (state) => {
      state.profileModelStateRe =  !state.profileModelStateRe;
    },
    closeProfileModel: (state) => {
      state.profileModelStateRe =  false;
    },
    openProfileModel: (state) => {      
      state.profileModelStateRe =  true;
      console.log("dipacth");
    },
    toggleAuth: (state) => {
      state.authModelStatus =  !state.authModelStatus;
    },
    closeAuth: (state) => {
      state.authModelStatus =  false;
    },
    openAuth: (state) => {   
      state.authModelStatus =  true;
    },
  },
});

export const { togglenotificationbox, closenotificationbox, opennotificationbox,
  togglemessagesbox, closemessagesbox, openmessagesbox,
  toggleSharePostModel, closeSharePostModel, openSharePostModel,
  toggleShareArticuleModel, closeShareArticuleModel, openShareArticuleModel,
  togglePostModel, closePostModel, openPostModel,
  toggleProfileModel, closeProfileModel, openProfileModel,
  toggleAuth, closeAuth, openAuth,
} = modelSlice.actions;
export const notificationbox = (state) => state.model.notificationbox;
export const messagesbox = (state) => state.model.messagesbox;
export const sharePostModel = (state) => state.model.SharePostModel;
export const shareArticuleModel = (state) => state.model.ShareArticuleModel;
export const PostModelStateRe = (state) => state.model.postModelStateRe;
export const ProfileModelStateRe = (state) => state.model.profileModelStateRe;

export const PostId = (state) => state.model.postID;
export const AuthModal = (state) => state.model.authModelStatus;

export default modelSlice.reducer;
