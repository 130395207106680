import { onSnapshot } from "firebase/firestore";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import  { chatscheck } from "../context/chatsFirebase";
import { db } from "../firebase";
import { currentuserId } from "../store/authSlice";
import { currentProfileId } from "../store/chatSlice";
import { selectUserByUsername } from "../store/usersSlice";
import Chatbox from "./chats/chat";
import ChatContact from "./chats/ChatContact";



function ChatPage() {

  const currentUserId = useSelector((state) => currentuserId(state));
  // const { username } = useParams();
  // const userMessgae = useSelector((state) => selectUserByUsername(state, username));
  const { username } = useParams();
  const userByname = useSelector((state) => selectUserByUsername(state, username));
  
  // const cuserId = useSelector((state) => currentProfileId(state));
  const cuserId = userByname?.userId;

 

  return (
      <div className="mx-auto mt-24 flex flex-nowrap h-[85vh]  max-h-screen rounded-lg border-red-400 border-2 overflow-x-hidden pb-4 s">
       
         <ChatContact />
        <div className="bg-white flex-1 border-l-2 border-gray-200">
        { cuserId ? (<Chatbox  />): (<div>Select your contact</div>)}

        </div>
      </div>
   
  );
}

export default ChatPage;
