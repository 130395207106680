import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { UserAuth } from '../context/AuthContext';
import { DASHBOARD, SIGNIN } from "../constants/routes";

import { createUserTable, doesUsernameExist } from "../context/PostFirbase";
import { AiFillEyeInvisible, AiFillEye } from 'react-icons/ai'
import { currentuserId, loggedIn } from '../store/authSlice';
import { useSelector } from 'react-redux';

import logo from "../assets/images/logos2.png";

import bglogin from "../assets/images/bg.png";
import bglogin3 from "../assets/images/layer.png";

const Signup = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('')
  const { createUser, googleSignIn, user } = UserAuth();
  const navigate = useNavigate();

  const userId = useSelector((state) => currentuserId(state));
  const isLogged = useSelector((state) => loggedIn(state));


  const [visblePwd, setVisblePwd] = useState(false)

  const [showModal, setShowModal] = useState(false);
 
 

  // handle toggle 
  const toggle = () => {
    setVisblePwd(!visblePwd)
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    try {
      const result = await createUser(email, password, username)
        .then(async (e) => await createUserTable(e.user.uid, email, username, name, ''));
        setShowModal(true);
    } catch (e) {
      setError(e.message);
      console.log(e.message);
    }
  };
  const handleonBlurUsername = async (e) => {
    e.preventDefault();
    setError('');
    try {
      const usernameExists = await doesUsernameExist(username);

      if (usernameExists.length) {
        setError('Username exist');
        // e.current.focus();

      }

    } catch (e) {
      setError(e.message);
      console.log(e.message);
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      await googleSignIn();
      //
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (isLogged) {
      navigate(DASHBOARD);
    }
  }, [userId]);

  return (
    // <div className='max-w-[550px] mx-auto  p-4'>
    <>
    <div className=' h-screen flex justify-center items-center  bg-red-400' style={{ backgroundImage: `url(${bglogin})` }}>
      <div className='flex flex-row h-full xl:h-full my-auto py-4 px-36 w-full xl:max-w-7xl  2xl:max-w-[120rem] mx-auto  justify-center items-center rounded-lg'>
        <div className='h-full flex-1 xl:mt-5 hidden md:block' style={{ backgroundImage: `url(${bglogin3})` }}
        >
          <div className='flex flex-col justify-center items-center h-full w-full'>

            <div className='text-red-500 text-xl  md:text-3xl xl:text-5xl  2xl:-mt-48   font-extrabold text-center'>
              Let's Create, <br />
              Let's Play
            </div>
          </div>
        </div>
        <div className='h-full flex-1 justify-center items-center mx-auto bg-white rounded-r-xl p-2 px-6 ' style={{ boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px' }}>
          <div className='flex flex-col 2xl:mt-52'>
            <div className='h-12 w-12 xl:h-24 xl:w-24 flex mx-auto justify-center  items-center'>
              <img src={logo} alt="Logo" className=" object-contain h-full w-full" />
            </div>
            <div>

              <h1 className='text-center text-lg 2xl:text-4xl font-bold py-1'>Create your account</h1>
              <p className='text-center text-sm 2xl:text-xl py-1'>
                Get started with your account to connect with your favourite creators
              </p>
            </div>
            {error && <p className="error-text">{error}</p>}
            <form onSubmit={handleSubmit} >
              <div className='flex flex-col py-2'>
                <label className=' text-xs 2xl:text-xl'>Full name</label>
                <input
                  onChange={(e) => setName(e.target.value)}
                  className='border py-0 lg:p-1 rounded-md'
                  type='text'
                />
              </div>
              <div className='flex flex-col py-1'>
                <label className=' text-xs 2xl:text-xl'>Email Address</label>
                <input
                  onChange={(e) => setEmail(e.target.value)}
                  className='border py-0 lg:p-1 rounded-md'
                  type='email'
                />
              </div>
              <div className='flex flex-col py-1'>
                <label className=' text-xs 2xl:text-xl'>Username</label>
                <input
                  onChange={(e) => setUsername(e.target.value)}
                  onBlur={handleonBlurUsername}
                  className='border py-0 lg:p-1  rounded-md'
                  type='text'
                />
              </div>
              <div className='flex flex-col py-1'>
                <label className=' text-xs 2xl:text-xl'>Password</label>
                {/* <input
            onChange={(e) => setPassword(e.target.value)}
            className='border p-1 rounded-md'
            type='password'
          /> */}
                <div className='relative mb-4'>
                  <input
                    onChange={(e) => setPassword(e.target.value)} value={password}
                    className='absolute w-full border py-0 lg:p-1  rounded-md'
                    type={(visblePwd === false) ? 'password' : 'text'}
                  />
                  <div className='text-xl xl:text-2xl absolute top-2 right-5'>
                    {
                      (visblePwd === false) ? <AiFillEye onClick={toggle} /> :
                        <AiFillEyeInvisible onClick={toggle} />
                    }
                  </div>
                </div>
              </div>
              <button className='mt-5 border 2xl:text-xl border-red-500 bg-red-600 hover:bg-red-500 w-full p-1 my-2 text-white rounded-md'>
                Sign Up
              </button>
              <div>
                <div className="relative flex items-center justify-center w-full mt-6 border border-t">
                  <div className="absolute px-5 bg-white">Or Continue with </div>
                </div>
                <div className="flex mt-4 gap-x-2">
                  <button
                    type="button"
                    onClick={handleGoogleSignIn}
                    className="flex items-center justify-center w-full p-2 border border-gray-600 rounded-md focus:ring-2 focus:ring-offset-1 focus:ring-violet-600"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" className="inline w-4 h-4 mr-3 text-gray-900 fill-current" viewBox="0 0 48 48" width="48px" height="48px">
                      <path fill="#fbc02d" d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12 s5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24s8.955,20,20,20 s20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"></path><path fill="#e53935" d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039 l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"></path><path fill="#4caf50" d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36 c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"></path><path fill="#1565c0" d="M43.611,20.083L43.595,20L42,20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571 c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z">
                      </path></svg>

                    Google
                  </button>

                </div>

                <p className="mt-4 text-xs font-light text-center text-gray-700">
                  {" "}
                  Already have an account yet?{" "}

                  <Link to='/signin' className="font-medium text-red-600 hover:underline">
                    Sign in.
                  </Link>
                </p>
                {/* <p className='py-1 text-xs'>
          Already have an account yet?{' '}
          <Link to='/' className='underline'>
            Sign in.
          </Link>
        </p> */}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

 
   
      {showModal ? (
        <>
          <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div className="relative w-auto my-6 mx-auto max-w-sm">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-3xl font-semibold">
                    Suceessful
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  <p className="my-4 text-slate-500 text-lg leading-relaxed">
                    Your registeration is successful. You ll redirected to Sign In Page
                  </p>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                 
                  <button
                    className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => {setShowModal(false); navigate('/signin')}}
                  >
                    okay
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
   

    </>
  );
};

export default Signup;
