import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { DASHBOARD } from "../../constants/routes";
import { UserAuth } from "../../context/AuthContext";
import { createUserTable, doesUsernameExist } from "../../context/PostFirbase";
import { loggedIn } from "../../store/authSlice";
import { closeAuth } from "../../store/modelSlice";
import { AiFillEyeInvisible, AiFillEye } from 'react-icons/ai'

const SignUpModal = ({dohide}) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('')
  const { createUser, googleSignIn, user } = UserAuth();
  const navigate = useNavigate()
  const isLogged = useSelector((state) => loggedIn(state));
  const dispatch = useDispatch();
  const [visblePwd, setVisblePwd] = useState(false)

  // handle toggle 
  const toggle = () => {
    setVisblePwd(!visblePwd)
  }


  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    try {
      const result  =  await createUser(email, password, username)
      .then(async (e) => await createUserTable(e.user.uid,email, username, name, '') );
      // if(result)
      // console.log('createuser', user.uid, email, password, username, name)
      // await createUserTable(user.uid,email, username, name) 
      navigate(DASHBOARD)
    } catch (e) {
      setError(e.message);
      console.log(e.message);
    }
  };
  const handleonBlurUsername = async (e) => {
    e.preventDefault();
    setError('');
    try {
    const usernameExists = await doesUsernameExist(username);

    if (usernameExists.length) {
    setError('Username exist');
    // e.current.focus();

    }

    } catch (e) {
      setError(e.message);
      console.log(e.message);
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      await googleSignIn();
      navigate(DASHBOARD)
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (user != null) {
      // navigate(DASHBOARD);
    }
  }, [user]);


useEffect(() => {
  if (isLogged) {
    dispatch(closeAuth())
  }
}, [isLogged]);
return (
  <div className='max-w-[550px] w-full mx-auto rounded-md bg-slate-100 my-auto p-4'>
    <div>
      <h1 className='text-center text-2xl font-bold py-2'>Create your account</h1>
      <p className='text-center  py-1'>
        Get started with your account to connect with your favourite creators
      </p>
    </div>
    {error && <p className="error-text">{error}</p>}
    <form onSubmit={handleSubmit}>
      <div className='flex flex-col py-2'>
        <label className=' text-sm'>Full name</label>
        <input
          onChange={(e) => setName(e.target.value)}
          className='border p-1 rounded-md'
          type='text'
        />
      </div>
      <div className='flex flex-col py-2'>
        <label className=' text-sm'>Email Address</label>
        <input
          onChange={(e) => setEmail(e.target.value)}
          className='border p-1 rounded-md'
          type='email'
        />
      </div>
      <div className='flex flex-col py-2 '>
        <label className=' text-sm'>Username</label>
        <input
          onChange={(e) => setUsername(e.target.value)}
          onBlur={handleonBlurUsername}
          className='border p-1 rounded-md'
          type='text'
        />
      </div>
      <div className='flex flex-col py-2'>
        <label className=' text-sm'>Password</label>
        {/* <input
          onChange={(e) => setPassword(e.target.value)}
          className='border p-1 rounded-md'
          type='password'
        /> */}
          <div className='relative mb-4'>
            <input
              onChange={(e) => setPassword(e.target.value)} value={password}
              className='absolute w-full border p-1 rounded-md'
              type={(visblePwd === false) ? 'password' : 'text'}
            />
            <div className='text-2xl absolute top-2 right-5'>
              {
                (visblePwd === false) ? <AiFillEye onClick={toggle} /> :
                  <AiFillEyeInvisible onClick={toggle} />
              }
            </div>
          </div>
      </div>
      <button className='border mt-5 border-red-500 bg-red-600 hover:bg-red-500 w-full p-1 my-2 text-white rounded-md'>
        Sign Up
      </button>
      <div>
      <div className="relative flex items-center justify-center w-full mt-6 border border-t">
      <div className="absolute px-5 bg-white">Or Continue with </div>
    </div>
    <div className="flex mt-8 gap-x-2">
      <button
        type="button"
        onClick={handleGoogleSignIn}
        className="flex items-center justify-center w-full p-2 border border-gray-600 rounded-md focus:ring-2 focus:ring-offset-1 focus:ring-violet-600"
      >
        <svg xmlns="http://www.w3.org/2000/svg" className="inline w-4 h-4 mr-3 text-gray-900 fill-current" viewBox="0 0 48 48" width="48px" height="48px">
          <path fill="#fbc02d" d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12 s5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24s8.955,20,20,20 s20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"></path><path fill="#e53935" d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039 l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"></path><path fill="#4caf50" d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36 c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"></path><path fill="#1565c0" d="M43.611,20.083L43.595,20L42,20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571 c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z">
            </path></svg>
       
        Google
      </button>
      
    </div>

    {/* <p className="mt-4 text-xs font-light text-center text-gray-700"> */}
    <p className="flex mt-4 justify-center text-xs font-light text-center text-gray-700">
      {" "}
      Already have an account yet?{" "}
      
      <span
      onClick={()=>dohide(true)}
      className="font-medium cursor-pointer text-red-600 hover:underline">
          Sign in.
        </span>
    </p>
      {/* <p className='py-1 text-xs'>
        Already have an account yet?{' '}
        <Link to='/' className='underline'>
          Sign in.
        </Link>
      </p> */}
    </div>
    </form>
  </div>
);
}

export default SignUpModal