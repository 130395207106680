import {  onSnapshot } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { displayNotifications } from "../../../context/PostFirbase";
import { currentuserId } from "../../../store/authSlice";
import NotificationDiv from "./NotificationDiv";

const NotificationContainer = () => {

    const currentUserId = useSelector((state) => currentuserId(state));

    const [notifications, setNotifications] = useState([]);


    useEffect(() => {
        async function showNotifications() {
          onSnapshot(displayNotifications(currentUserId), (snapshot) => {
            // console.log(snapshot.docs);
            setNotifications(snapshot.docs);
            // setNotificationsLen(snapshot.docs.length);
          });
    
        }
        showNotifications();
        // console.log(comments);
      }, [ currentUserId]);

// console.log(notifications);
    return (
           <ul className="border-2 border-gray-300 border-solid py-3 rounded-lg">
                {notifications ? notifications?.map((notification) => (
                    // <li className="flex justify-start gap-1 my-2 bg-white rounded-sm p-2"
                    <li className="block gap-2 odd:bg-gray-200 even:bg-white  p-1"
                     key={notification.data().id} 
                     >
                        <NotificationDiv notification={notification.data()} />
                    </li>
                )) : ''}
            </ul>
    )
}

export default NotificationContainer