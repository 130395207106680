import { Outlet } from 'react-router-dom';
import Header from './Header';
import Footer from './footer';
import PostModal from './Modals/PostModal';
import LikesModal from './Modals/LikesModal';
import CommentLikesModal from './Modals/CommentLikesModal';
import TopScroll from './TopScroll';
import Notifiationbox from './slider/notifiationbox';
import Messagebox from './slider/messagebox';
import Messagesbox from './slider/messagesbox';
import SharePostModal from './Modals/SharePostModal';
import AuthModel from './Modals/authModel';
import PostBox from './Modals/post/PostBox';
import ShareArticuleModal from './Modals/ShareArticuleModal';
const DashLayout = () => {
    return (
        <div className="overflow-hidden">
            <AuthModel />
        <PostModal />
        <LikesModal />
        <CommentLikesModal />
        <TopScroll />
        <Header />
        {/* <Footer /> */}
        <Notifiationbox />
        <Messagesbox />
        <Messagebox />
        <SharePostModal />
        <ShareArticuleModal />
        <PostBox />
                <Outlet />
                
          </div>
    )
}

export default DashLayout