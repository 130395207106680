import { useDispatch, useSelector } from 'react-redux';
import { currentProfileId, setcurrentchat } from '../../store/chatSlice';
import { selectUserById } from '../../store/usersSlice';
import { useNavigate } from 'react-router-dom';

const Contact = ({ read }) => {
    const personid = read
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const user = useSelector((state) => selectUserById(state, personid));
    const currentChat = useSelector((state) => currentProfileId(state));
    const tt = (personid === currentChat);

    const handleMessage = () => {
        dispatch(setcurrentchat({ userId: personid }));
        navigate('/messages/' + user?.username);;
    }
    const kstyle = tt ? 'bg-gray-100 border-l-4 border-red-600' : '';
    return (
        <li className={`flex gap-4 justify-between items-center  py-2  mb-2 cursor-pointer ${kstyle}`}
            onClick={handleMessage}
        >
            <div>

                <img src={user?.image ? user.image : "/images/default.png"} alt="" 
                className="rounded-full h-8 ml-1 xl:h-12" />
            </div>
            <div className='flex-1 flex-col'>
                <h2 className='text-base xl:text-2xl'>{user?.username}</h2>
                <h3 className='text-xs xl:text-xl'>
                    {/* {contact?.text} */}
                </h3>
            </div>
        </li>
    )
}

export default Contact