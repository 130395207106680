

const SidebarMenuItem = ({ text, Icon, active }) => {
    return (
        <div  className="cursor-pointer hover:bg-[#f3f3f3] rounded-full w-[52px] w-auto h-[52px] h-auto p-3 transition duration-500 ease-out flex items-start text-gray-700 justify-start justify-start text-lg space-x-3">
          <Icon className="h-7" />
          <span className={`inline`}>{text}</span>
        </div>
      );
}

export default SidebarMenuItem