import { deleteObject, uploadBytesResumable } from "firebase/storage";
import {
  collection,
  query,
  where,
  getDocs,
  limit,
  doc,
  updateDoc,
  arrayRemove,
  arrayUnion,
  addDoc,
  serverTimestamp,
  orderBy,
  getDoc,
  deleteDoc,
  onSnapshot,
  setDoc,
} from "firebase/firestore";
import { ref, getDownloadURL, uploadString } from "firebase/storage";
import { updateProfile } from "firebase/auth";
import { v4 } from "uuid";


import { auth, db, storage } from '../firebase';
import { nanoid } from "@reduxjs/toolkit";
import { async } from "@firebase/util";


export async function createUserTable(uid, email, username, name, img = "/images/default.png") {
  const usernameSet = username ? username.toLowerCase() : email.split('@')[0];

  await setDoc(doc(db, "users", uid), {
    userId: uid,
    username: usernameSet,
    fullName: name?.toLowerCase(),
    emailAddress: email.toLowerCase(),
    dateCreated: Date.now(),
    image: img,
  });

}

export async function doesUsernameExist(username) {
  const usersRef = collection(db, "users");
  const result = query(usersRef, where("username", "==", username));
  const getResult = await getDocs(result);
  const returnResult = getResult.docs.map((user) => user.data().length > 0);
  return returnResult;
}

export async function checkEmailAddressExist(emailAddress) {
  const usersRef = collection(db, "users");
  const result = query(usersRef, where("emailAddress", "==", emailAddress));
  const getResult = await getDocs(result);
  const returnResult = getResult.docs.map((user) => user.data().length > 0);
  return returnResult;
}
export async function checkEmailAddressExistData(emailAddress) {
  const usersRef = collection(db, "users");
  const result = query(usersRef, where("emailAddress", "==", emailAddress));
  const getResult = await getDocs(result);
  const returnResult = getResult.docs
    .map((user) => ({
      ...user.data(),
      id: user.id,
    }));
  return returnResult;
}

// get user from the firestore with the username
export async function getUserByUsername(username) {
  const usersRef = collection(db, "users");
  const result = query(usersRef, where("username", "==", username));
  const getResult = await getDocs(result);
  const returnResult = getResult.docs.map((user) => ({
    ...user.data(),
    id: user.id,
  }));
  return returnResult;
}


// get user from the firestore with the doc id
export async function getUserByUserDocId(userId) {
  const usersRef = doc(db, "users", userId);
  console.log("userId", userId);
  const getResult = await getDoc(usersRef)
    .then((doc) => { return { ...doc.data(), id: doc.id } });

  return getResult;
}

// get user from the firestore with the userId
export async function getUserByUserId(userId) {
  const usersRef = collection(db, "users");
  const result = query(usersRef, where("userId", "==", userId));
  const getResult = await getDocs(result);
  const userResult = getResult.docs.map((user) => ({
    ...user.data(),
    id: user.id,
  }));
  return userResult;
}


// fetch the suggested profiles for the active user.
export async function getAllUsersProfiles() {
  const getResult = await getDocs(collection(db, "photos"));
  const userResult = getResult.docs
    .map((user) => ({
      ...user.data(),
      id: user.id,
    }));
  // .filter(
  //   (profile) =>
  //     profile.userId !== userId && !following?.includes(profile.userId)
  // );
  console.log("userResult", userResult);
  return userResult;
}
// fetch the suggested profiles for the active user.
export async function getSuggestedProfiles(userId, following) {
  // const flwg = await getUserByUserId(userId);
  const usersRef = collection(db, "users");
  const result = query(usersRef, limit(10));
  const getResult = await getDocs(result);
  const userResult = getResult.docs
    .map((user) => ({
      ...user.data(),
      id: user.id,
    }))
    .filter(
      (profile) =>
        profile.userId !== userId && !following?.includes(profile.userId)
    );
  return userResult;
}

// update the active users following
export async function updateLoggedInUserFollowing(
  loggedInUserDocId,
  profileId,
  isFollowingProfile
) {
  const usersRef = doc(db, "users", loggedInUserDocId);
  await updateDoc(usersRef, {
    following: isFollowingProfile
      ? arrayRemove(profileId)
      : arrayUnion(profileId),
  });
}

// update the followers list of the user whom the active user follows
export async function updateFollowedUserFollowers(
  profileDocId,
  loggedInUserDocId,
  isFollowingProfile
) {
  const usersRef = doc(db, "users", profileDocId);
  await updateDoc(usersRef, {
    followers: isFollowingProfile
      ? arrayRemove(loggedInUserDocId)
      : arrayUnion(loggedInUserDocId),
  });
}

// fetch the posts of the users whom the active user is following
export async function getAllComments(userId, id) {

  const getResultUsers = await getDocs(collection(db, "users"));
  const usersde = getResultUsers.docs.map((users) => ({
    ...users.data(),
    id: users.id,
  }));
  const commentsRef = collection(db, "photos", id, "comments");
  const getResult = query(commentsRef, orderBy("timestamp", "desc"));
  // await Promise.all(
  let fetched = false;
  return new Promise((resolve, reject) => {
    onSnapshot(getResult, (querySnapshot) => {
      const urls = querySnapshot.docs.map((comment) => {
        if (comment != null) {
          for (let i = 0; i < usersde.length; i++) {
            if (usersde[i].userId === comment.data().userId) {
              let userLikedcomment = false;
              comment.likes?.map((res) => {
                if (res === userId) {
                  userLikedcomment = true;
                }
              });
              return ({
                ...comment.data(),
                username: usersde[i].username,
                image: usersde[i].image.length > 0 ? usersde[i].image : "/images/default.png",
                id: comment.id,
                userLikedcomment
              });
            }
          }
        }


      })

      if (!fetched) {
        // URLs fetched for first time, return value
        fetched = true;
        resolve(urls);
      } else {
        // URLs fetched already, an update received.
        // TODO: Update in state directly
      }
    })
  })

  //   let document = []
  //   const cmtd = await Promise.all
  //   ( onSnapshot(getResult, (snapshot) => 
  //  { snapshot.docs.map((comment) => {
  //       if(comment != null){
  //         for (let i = 0; i < usersde.length; i++) {
  //         if (usersde[i].userId == comment.data().userId) {
  //           let userLikedcomment = false;
  //           comment.likes?.map((res) => {
  //             if (res === userId) {
  //               userLikedcomment = true;
  //             }
  //           });
  //          return document=[...document, {
  //             ...comment.data(),
  //             username: usersde[i].username,
  //             image: usersde[i].image,
  //             id: comment.id,
  //             userLikedcomment
  //           }];
  //         }
  //       }}


  //     })
  //   }
  // ))
  // return cmtd;


}
export async function getAllPhotos(userId) {
  const getResultUsers = await getDocs(collection(db, "users"));
  const usersde = getResultUsers.docs.map((users) => ({
    ...users.data(),
    id: users.id,
  }));
  const getResult = await getDocs(collection(db, "photos"));
  const photoWithUserDetails = await Promise.all(
    getResult.docs.map((photo) => {
      if (photo != null) {
        for (let i = 0; i < usersde.length; i++) {
          if (usersde[i]?.userId === photo.data().userId) {
            let userLikedPhoto = false;
            photo.likes?.map((res) => {
              if (res === userId) {
                userLikedPhoto = true;
              }
            });
            return ({
              ...photo.data(),
              username: usersde[i].username,
              image: usersde[i].image,
              id: photo.id,
              userLikedPhoto
            });
          }
        }
      }
    }

    ));
  return photoWithUserDetails;
  // }
  // console.log('userFollowedPhotos', userFollowedPhotos);

  // const photoWithUserDetails = await Promise.all(
  //   userFollowedPhotos.map(async (photo) => {
  //     // checks if the logged in user already likes that post or not
  //     let userLikedPhoto = false;
  //     photo.likes?.map((res) => {
  //       if (res === userId) {
  //         userLikedPhoto = true;
  //       }
  //     });
  //     return { ...photo, userLikedPhoto };
  //   })
  // );
  // return photoWithUserDetails;
}
export async function getPhotos(userId, following) {
  const photosRef = collection(db, "photos");
  const result = query(photosRef, where("userId", "in", following));
  const getResult = await getDocs(result);
  const userFollowedPhotos = getResult.docs.map((photo) => ({
    ...photo.data(),
    id: photo.id,
  }));
  const photoWithUserDetails = await Promise.all(
    userFollowedPhotos.map(async (photo) => {
      // checks if the logged in user already likes that post or not
      let userLikedPhoto = false;
      photo.likes?.map((res) => {
        if (res === userId) {
          userLikedPhoto = true;
        }
      });
      return { ...photo, userLikedPhoto };
    })
  );
  return photoWithUserDetails;
}

// update the like count of the post
export async function updateLikes(id, userId, toggledLiked) {
  console.log(id, userId, toggledLiked);
  const photosRef = doc(db, "photos", id);
  await updateDoc(photosRef, {
    likes: toggledLiked ? arrayRemove(userId) : arrayUnion(userId),
  });
}

// update the like count of the post
export async function updateArticleLikes(id, userId, toggledLiked) {
  // console.log(id, userId, toggledLiked);
  const photosRef = doc(db, "articles", id);
  await updateDoc(photosRef, {
    likes: toggledLiked ? arrayRemove(userId) : arrayUnion(userId),
  });
}

// update the like count of the post merchandise
export async function updateMechLikes(id, userId, toggledLiked) {
  const photosRef = doc(db, "merchandise", id);
  await updateDoc(photosRef, {
    likes: toggledLiked ? arrayRemove(userId) : arrayUnion(userId),
  });
}

// update the save count of the post
export async function updateMechSaves(id, userId, toggledsaved) {
  
  const photosRef = doc(db, "merchandise", id);
  await updateDoc(photosRef, {
    saves: toggledsaved ? arrayRemove(userId) : arrayUnion(userId),
  });

 await  addfavorite(userId,id,"merchandise", "merchandise")

  // const usersRef = doc(db, "users", userId);
  // await updateDoc(usersRef, {
  //   msaved: toggledsaved
  //     ? arrayRemove(id)
  //     : arrayUnion(id),
  // });
}



// update the save count of the post
export async function updateSaves(id, userId, toggledsaved, ct, ck) {
  console.log(id, userId, toggledsaved) ;
  const photosRef = doc(db, "photos", id);
  await updateDoc(photosRef, {
    saves: toggledsaved ? arrayRemove(userId) : arrayUnion(userId),
  });
  // const usersRef = doc(db, "users", userId);
  // await updateDoc(usersRef, {
  //   saved: toggledsaved
  //     ? arrayRemove(id)
  //     : arrayUnion(id),

  // });
 await  addfavorite(userId,id,ct, ck)

}

export async function updateArticleSaves(id, userId, toggledsaved) {
  // console.log(id, userId, toggledsaved) ;
  const photosRef = doc(db, "articles", id);
  await updateDoc(photosRef, {
    saves: toggledsaved ? arrayRemove(userId) : arrayUnion(userId),
  });
  // const usersRef = doc(db, "users", userId);
  // await updateDoc(usersRef, {
  //   asaved: toggledsaved
  //     ? arrayRemove(id)
  //     : arrayUnion(id)
  // });
 await  addfavorite(userId,id,"articles", "articles")
}



export async function addfavorite(userId,fid,contentType, contentKind){
  await setDoc(doc(db, "favorite", userId+fid), {
   
    userId: userId,
    fid: fid,
    fileType: contentType,
    fileKind: contentKind,
    timestamp: serverTimestamp(),
  });
}

// export async function toggleFollow(
//   isFollowingProfile,
//   activeUserDocId,
//   profileDocId,
//   profileUserUserId,
//   activeUserUserId
// ) {
//   await updateLoggedInUserFollowing(
//     activeUserDocId,
//     profileUserUserId,
//     isFollowingProfile
//   );
//   await updateFollowedUserFollowers(
//     profileDocId,
//     activeUserUserId, d
//     isFollowingProfile
//   );
// }





// update the shares count of the post
export async function updatePostShares(id, userId) {
  const photosRef = doc(db, "photos", id);
  await updateDoc(photosRef, {
    shares: arrayUnion(userId),
  });
}

// add the photo to firebase storage, fetch its download url and add to photos in firestore
export async function addPostsToFirestore(
  userId,
  caption,
  selectedFile
) {
  console.log("selectedFile", selectedFile);
  const imageRef = ref(storage, `/posts/${v4()}`);
  await uploadString(imageRef, selectedFile, "data_url").then(
    async (snapshot) => {
      const downloadUrl = await getDownloadURL(imageRef);
      console.log(downloadUrl);
      const postRef = await addDoc(collection(db, "photos"), {
        userId: userId,
        caption: caption,
        comments: [],
        likes: [],
        saves: [],
        imageSrc: downloadUrl,
        timestamp: serverTimestamp(),
      });
      // const photoRef2 = doc(db, "photos", postRef.id);
      // await updateDoc(photoRef2, {
      // });
    }
  );
}
export async function addPostsToFirestore2(
  userId,
  caption,
  selectedFile,
  contentType,
  contentKind,
  amount
) {
  console.log("selectedFile", selectedFile);
  const imageRef = ref(storage, `/posts/${nanoid()}`);

  await uploadString(imageRef, selectedFile, "data_url").then(
    async (snapshot) => {
      const downloadUrl = await getDownloadURL(imageRef);
      console.log(downloadUrl);
      const postRef = await addDoc(collection(db, "photos"), {
        userId: userId,
        caption: caption,
        fileType: contentType,
        fileKind: contentKind,
        comments: [],
        shares: [],
        likes: [],
        saves: [],
        imageSrc: downloadUrl,
        amount: amount,
        timestamp: serverTimestamp(),
      });
      // const photoRef2 = doc(db, "photos", postRef.id);
      // await updateDoc(photoRef2, {
      // });
    }
  );
}



export async function addPostsToFirestore4(
  userId,
  caption,
  sharedID,
  kind
) {
  const postRef = await addDoc(collection(db, "photos"), {
    userId: userId,
    caption: caption,
    fileType: 'share',
    fileKind: kind,
    sharedId: sharedID,
    comments: [],
    likes: [],
    saves: [],
    timestamp: serverTimestamp(),
  });

}
export async function addPostsToFirestore3(
  userId,
  caption,
  contentType,
  contentKind,
  amount
) {
  await addDoc(collection(db, "photos"), {
    userId: userId,
    caption: caption,
    fileType: contentType,
    fileKind: contentKind,
    comments: [],
    likes: [],
    saves: [],
    amount: amount,
    timestamp: serverTimestamp(),
  });


}
export async function addPostVideo(
  userId,
  caption,
  selectedFile,
  contentType,
  contentKind,
  amount,
  colabo,
  tag,
  location
) {
  console.log("selectedFile", selectedFile);
  const imageRef = ref(storage, `/posts/${nanoid()}`);

  await uploadString(imageRef, selectedFile, "data_url").then(
    async (snapshot) => {
      const downloadUrl = await getDownloadURL(imageRef);
      console.log(downloadUrl);
      const postRef = await addDoc(collection(db, "photos"), {
        userId: userId,
        caption: caption,
        fileType: contentType,
        fileKind: contentKind,
        comments: [],
        shares: [],
        saves: [],
        likes: [],
        imageSrc: downloadUrl,
        amount: amount,
        colabo,
        tag,
        location,
        timestamp: serverTimestamp(),
      });
      // const photoRef2 = doc(db, "photos", postRef.id);
      // await updateDoc(photoRef2, {
      // });
    }
  );
}
export async function addPostAudio(
  userId,
  caption,
  selectedFile,
  contentType,
  contentKind,
  amount,
  colabo,
  tag,
  location
) {
  console.log("selectedFile", selectedFile);
  const imageRef = ref(storage, `/audios/${nanoid()}`);

  await uploadString(imageRef, selectedFile, "data_url").then(
    async (snapshot) => {
      const downloadUrl = await getDownloadURL(imageRef);
      console.log(downloadUrl);
      const postRef = await addDoc(collection(db, "audios"), {
        userId: userId,
        caption: caption,
        fileType: contentType,
        fileKind: contentKind,
        comments: [],
        shares: [],
        saves: [],
        likes: [],
        src: downloadUrl,
        amount: amount,
        colabo,
        tag,
        location,
        timestamp: serverTimestamp(),
      });
      // const photoRef2 = doc(db, "photos", postRef.id);
      // await updateDoc(photoRef2, {
      // });
    }
  );
}
export async function addPostArticle(
  userId,
  text,
  contentType,
  contentKind,
) {

  const postRef = await addDoc(collection(db, "articles"), {
    userId: userId,
    text,
    fileType: contentType,
    fileKind: contentKind,
    comments: [],
    shares: [],
    likes: [],
    saves: [],
    timestamp: serverTimestamp(),
  });

}

export async function addMerchandise(
  userId,
  caption,
  price,
  productName,
  img
) {
  if (img) {
    const storageRef = ref(storage, `/merchandise/${nanoid()}`);



    await uploadString(storageRef, img, "data_url").then(
      async (snapshot) => {
        const downloadUrl = await getDownloadURL(storageRef);
        console.log(downloadUrl);
        const postRef = await addDoc(collection(db, "merchandise"), {
          userId: userId,
          caption,
          price,
          productName,
          comments: [],
          shares: [],
          likes: [],
          saves: [],
          img: downloadUrl,
          timestamp: serverTimestamp(),
        });
      }
    );
  }
  else {
    const postRef = await addDoc(collection(db, "merchandise"), {
      userId: userId,
      caption,
      price,
      productName,
      comments: [],
      shares: [],
      saves: [],
      likes: [],
      timestamp: serverTimestamp(),
    });
  }

}
// add the comments to the particular post
export async function addComment(id, commentToSend, userId) {
  const commentsRef = collection(db, "photos", id, "comments");
  await addDoc(commentsRef, {
    comment: commentToSend,
    userId: userId,
    likes: [],
    timestamp: serverTimestamp(),
  });
}
export async function addMechComment(id, commentToSend, userId) {
  const commentsRef = collection(db, "merchandise", id, "comments");
  await addDoc(commentsRef, {
    comment: commentToSend,
    userId: userId,
    likes: [],
    timestamp: serverTimestamp(),
  });
}
// export async function addMsgUpdate(currentUserId, profileId, text) {
//   const commentsRef = collection(db, "userChats", currentUserId);
//   // await addDoc(commentsRef, {
//   //   text,
//   //   profileId,
//   //   timestamp: serverTimestamp(),
//   // });

//   const sendMessage = async () => {
//     try {
//       if (user && recieverData) {
//         await addDoc(
//           collection(
//             db,
//             "users",
//             user.uid,
//             "chatUsers",
//             recieverData.userId,
//             "messages"
//           ),
//           {
//             username: user.displayName,
//             messageUserId: user.uid,
//             message: chatMessage,
//             timestamp: new Date(),
//           }
//         );

//         await addDoc(
//           collection(
//             db,
//             "users",
//             recieverData.userId,
//             "chatUsers",
//             user.uid,
//             "messages"
//           ),
//           {
//             username: user.displayName,
//             messageUserId: user.uid,
//             message: chatMessage,
//             timestamp: new Date(),
//           }
//         );
//       }
//     } catch (error) {
//       console.log(error);
//     }
// }
// }
// fetch the comments of the particular post
export function displayComment(id) {
  const commentsRef = collection(db, "photos", id, "comments");
  return query(commentsRef, orderBy("timestamp", "desc"));
}
export function displayMechComment(id) {
  const commentsRef = collection(db, "merchandise", id, "comments");
  return query(commentsRef, orderBy("timestamp", "desc"));
}
export function displayNotificationCounter(id) {
  const commentsRef = collection(db, "notificationcounter", id);
  return query(commentsRef);
}
export function displayNotifications(id) {
  const commentsRef = collection(db, "notifications", id, "notifications");
  return query(commentsRef, orderBy("timestamp", "desc"));
}

// fetch users who liked the particular post
export async function getLikedUsers(id) {
  const photosRef = doc(db, "photos", id);
  const getResult = await getDoc(photosRef);
  const likesUserIds = getResult.data().likes;
  // returns a data as promise
  const data = likesUserIds.map(async (userId) => {
    const user = await getUserByUserId(userId);
    return user;
  });
  // extracts the data from the promise
  const result = await Promise.all(data);
  return result;
}

// get photos of the visited user from the firestore with the username
export async function getPhotosById(id) {
  const photosRef = collection(db, "photos");
  // fetch photos only of the visited user
  const result = query(
    photosRef,
    where("userId", "==", id),
    orderBy("timestamp", "desc")
  );

  const getResult = await getDocs(result);
  const photos = getResult.docs.map((photo) => ({
    ...photo.data(),
    id: photo.id,
  }));
  return photos;
}
export async function getPhotosByUsername(username) {
  const [user] = await getUserByUsername(username);
  const photosRef = collection(db, "photos");
  // fetch photos only of the visited user
  const result = query(
    photosRef,
    where("userId", "==", user.userId),
    orderBy("timestamp", "desc")
  );
  const getResult = await getDocs(result);
  const photos = getResult.docs.map((photo) => ({
    ...photo.data(),
    id: photo.id,
  }));
  return photos;
}

// checks if the logged in user is following the profile user or not
export async function isUserFollowingProfile(
  loggedInUserUsername,
  profileUserUserId
) {
  const userRef = collection(db, "users");
  const result = query(
    userRef,
    where("username", "==", loggedInUserUsername),
    where("following", "array-contains", profileUserUserId)
  );
  const getResult = await getDocs(result);
  const [response = {}] = getResult.docs.map((res) => ({
    ...res.data(),
    id: res.id,
  }));
  return response.userId;
}

// handle the Follow unfollow button functionality
export async function toggleFollow(
  isFollowingProfile,
  activeUserDocId,
  profileDocId,
  profileUserUserId,
  activeUserUserId
) {
  await updateLoggedInUserFollowing(
    activeUserDocId,
    profileUserUserId,
    isFollowingProfile
  );
  await updateFollowedUserFollowers(
    profileDocId,
    activeUserUserId,
    isFollowingProfile
  );
}

// edit the logged in User details as per his request
export async function updateUserDetails(
  id,
  userId,
  username,
  fullName,
  bio,
  edituserName,
  editfullName,
  editBio,
  selectedFile
) {
  const imageRef = ref(storage, `users/${userId}/image`);
  const userRef = doc(db, "users", id);
  selectedFile
    ? await uploadString(imageRef, selectedFile, "data_url").then(
      async (snapshot) => {
        const downloadUrl = await getDownloadURL(imageRef);
        await updateDoc(userRef, {
          username: edituserName ? edituserName : username,
          fullName: editfullName ? editfullName : fullName,
          image: downloadUrl,
          bio: editBio ? editBio : bio,
        });
      }
    )
    : await updateDoc(userRef, {
      username: edituserName ? edituserName : username,
      fullName: editfullName ? editfullName : fullName,
    });
}

// update the logged in User's username if an edit was requested
export async function updateUserAuthDetails(username, edituserName) {
  updateProfile(auth.currentUser, {
    displayName: edituserName ? edituserName : username,
  }).then(() => {
    console.log(auth.currentUser);
  });
}

// fetch the followers of the profile user
export async function getFollowers(id) {
  const usersRef = doc(db, "users", id);
  const getResult = await getDoc(usersRef);
  const followersId = getResult.data().followers;
  // returns a data as promise
  const data = followersId.map(async (userId) => {
    const user = await getUserByUserId(userId);
    return user;
  });
  // extracts the data from the promise
  const result = await Promise.all(data);
  return result;
}

// fetch the following of the profile user
export async function getFollowing(id) {
  const usersRef = doc(db, "users", id);
  const getResult = await getDoc(usersRef);
  const followingUsersId = getResult.data().following;
  // returns a data as promise
  const data = followingUsersId.map(async (userId) => {
    const user = await getUserByUserId(userId);
    return user;
  });
  // extracts the data from the promise
  const result = await Promise.all(data);
  return result;
}

//get the length of the comments in the photo with that id
export async function getCommentsLength(id) {
  const commentRef = collection(db, "photos", id, "comments");
  const result = query(commentRef, where("username", "!=", ""));
  const getResult = await getDocs(result);
  // returns length of comments in that photo
  return getResult.size;
}

// deletes the photo posted both from storage as well as firestore (including its other docs)
export async function deletePost(photoId) {
  await deleteDoc(doc(db, "photos", photoId));
  // const imageRef = ref(storage, `posts/${photoId}`);
  // await deleteObject(imageRef).then(() => {
  //   console.log("Deleted");
  // });
}

// fetch the active Users Latest Post to show it in the active users's feed alse
export async function activeUserLatestPost(userId, activeUserId) {
  const photoRef = collection(db, "photos");
  const result = query(
    photoRef,
    where("userId", "==", activeUserId),
    orderBy("timestamp", "desc")
  );
  // getting all posts which the active user has posted and arranging them in descending order so that the latest post is at the post
  const getResult = await getDocs(result);
  const activeUserPhotos = getResult.docs.map((photo) => ({
    ...photo.data(),
    id: photo.id,
  }));
  // extract the latest post which is at index = 0
  const latestPhoto = activeUserPhotos[0];
  // checks if the logged in user already likes that post or not
  let userLikedPhoto = false;
  latestPhoto.likes?.map((res) => {
    if (res === userId) {
      userLikedPhoto = true;
    }
  });
  return { ...latestPhoto, userLikedPhoto };
}

// update the like count of the comment
export async function likeComment(photoId, commentId, userId, toggledLiked) {
  const commentRef = doc(db, "photos", photoId, "comments", commentId);
  await updateDoc(commentRef, {
    likes: toggledLiked ? arrayRemove(userId) : arrayUnion(userId),
  });
}

// checks if the logged in user already likes that comment or not
export async function isCommentLiked(photoId, commentId, userId) {
  const commentRef = doc(db, "photos", photoId, "comments", commentId);
  const getResult = await getDoc(commentRef);
  const comment = getResult.data().likes;
  if (comment.includes(userId)) return true;
  return false;
}

export async function getCommentLikedUsers(photoId, commentId) {
  const commentRef = doc(db, "photos", photoId, "comments", commentId);
  const getResult = await getDoc(commentRef);
  const likesUserIds = getResult.data().likes;
  // returns a data as promise
  const data = likesUserIds.map(async (userId) => {
    const user = await getUserByUserId(userId);
    return user;
  });
  // extracts the data from the promise
  const result = await Promise.all(data);
  return result;
}

