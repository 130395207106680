import { doc, onSnapshot } from "firebase/firestore";
import React, {  useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { db } from "../../../firebase";
import { currentuserId } from "../../../store/authSlice";
import ContactBox from "./contactbox";



const MessageContactBox = () => {

    const [chats, setChats] = useState([]);

    const currentUserId = useSelector((state) => currentuserId(state));

    useEffect(() => {
        const getChats = () => {
            const unsub = onSnapshot(doc(db, "userChats", currentUserId), (doc) => {
                console.log(doc.data());
                setChats(doc.data());
            });

            return () => {
                unsub();
            };
        };

        currentUserId && getChats();
    }, [currentUserId]);
    return (
        <aside className="flex flex-col mx-2" >

            <header className="px-2 py-3">
                <input type="text" placeholder="search" className="w-full h-8 px-5 placeholder:text-red-500 " />
            </header>
            <ul className="">
                {/* { chats?.profileId ? chats.map((contact) => (
                    <ContactBox key={contact?.profileId}   contact={contact} />
                   
                )): ''} */}
            </ul>
        </aside>
    )
}

export default MessageContactBox