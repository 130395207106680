import React, { Fragment, useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { likesModalState } from "../../atoms/modalAtom";
import { photoIdState } from "../../atoms/idAtom";
import { Dialog, Transition } from "@headlessui/react";
import { HeartIcon, XIcon } from "@heroicons/react/outline";
import { getLikedUsers } from "../../context/PostFirbase";
import { Link } from "react-router-dom";

function LikesModal() {
  const [open, setOpen] = useRecoilState(likesModalState);
  const [users, setUsers] = useState([]);
  const [photoId, setPhotoId] = useRecoilState(photoIdState);
  useEffect(() => {
    if (open) {
      //to fetch the users who have liked that post
      const getUsers = async () => {
        const result = await getLikedUsers(photoId);
        result.map((res) => {
          setUsers((users) => [...users, res[0]]);
        });
      };
      // to show the list of users who have liked that post if its photoId is present
      if (photoId !== "") {
        getUsers();
      }
    } else {
      setPhotoId(""); setUsers([]);
    }
  }, [photoId, open]);
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 overflow-y-auto z-[60]"
        onClose={setOpen}
      >
        <div className="flex items-center justify-center min-h-screen  pt-4 px-4 pb-20 text-center sm:block m-2 sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-500"
            enterFrom="opactiy-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-500"
            enterFrom="opactiy-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg p-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm w-full sm:p-6 space-y-4">
            <button className="outline-none float-right" onClick={() => setOpen(false)}>
                <XIcon className=" h-6  w-6 cursor-pointer text-gray-300" />
              </button>
              <div as="h3" className="flex items-center space-x-4  text-2xl ">
                <div className=" flex items-center justify-center h-14 w-14 rounded-full bg-red-100">
                  <HeartIcon className="h-8 w-8 text-red-600 mt-[2px]" />
                </div>
                <span>Likes</span>
              </div>
              <hr />
              <div className="overflow-y-scroll scrollbar-hide space-y-3 max-h-96">
                {users?.map((user) => (
                  <div className="flex items-center" key={user.id}>
                    <img
                      src={user.image}
                      alt={`${user?.username}`}
                      className="rounded-full w-14 h-14 object-cover border-2 border-gray-300 p-[2px] text-center mr-3"
                    />
                    <Dialog.Title as="h3" className="font-bold text-gray-800">
                      <Link
                        to={`/profile/${user.username}`}
                        onClick={() => setOpen(false)}
                      >
                        {user.username}
                      </Link>
                      <p className="text-gray-600 -mt-[2px] font-medium ">
                        {user.fullName}
                      </p>
                    </Dialog.Title>
                  </div>
                ))}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default LikesModal;
