import  {  useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import ReactTimeAgo from "react-time-ago";
import { currentuserId } from "../../../store/authSlice";


const Message = ({ message }) => {
  const ref = useRef();
  const currentUserId = useSelector((state) => currentuserId(state));

  useEffect(() => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  }, [message]);

  return (
    <div
      ref={ref}
      className={`flex flex-col gap-2 mb-5 `}
    >

      <div 
      className={`flex flex-col text-red-500 ${message?.senderId === currentUserId ?  "text-white" : 'text-black'} `}>
        {message?.img && <img src={message?.img} alt="" />}
        <p className={`px-4 py-1 text-xs ${message?.senderId === currentUserId ?  "ml-16" : ''} `}>{message.text}</p>
      </div>
      <div className="messageInfo">
        <span className={` ${message?.senderId === currentUserId ? '': "float-right" }`}>
          
        <ReactTimeAgo
          date={message?.date.toDate()}
          locale="en-US"
          timeStyle="round"
          className=" text-[9px] capitalize text-gray-800"
        />
        </span>
      </div>
    </div>
  );
};

export default Message;
